import React from 'react';
import styles from './OrderTypeText.module.scss';
import { commonConstants } from '../../constants';

const OrderTypeText = ({ orderType, orderStatus, deliverOn, deliverBy, pickedUpOn, pickupBy, serviceOn, serviceBy }) => {
  const renderDateText = (label, date) => (
    <>
      <span className={styles.customLabelText}>{label}</span>
      <span className={styles.customContentText}>{date}</span>
    </>
  );

  const isNotDefaultDate = (date) => date && date !== commonConstants.defaultDateTime;

  const renderDeliveryText = () => {
    if ((orderStatus === "Delivered" || orderStatus === "Complete") && isNotDefaultDate(deliverOn)) {
      return renderDateText("Delivered on:", deliverOn);
    } else if (isNotDefaultDate(deliverBy)) {
      return renderDateText("Deliver by:", deliverBy);
    }
    return null;
  };

  const renderPickupText = () => {
    if ((orderStatus === "Picked Up" || orderStatus === "Complete") && isNotDefaultDate(pickedUpOn)) {
      return renderDateText("Picked up on:", pickedUpOn);
    } else if (isNotDefaultDate(pickupBy)) {
      return renderDateText("Pickup by:", pickupBy);
    }
    return null;
  };

  const renderServiceRequestText = () => {
    if (orderStatus === "Received" && isNotDefaultDate(serviceOn)) {
      return renderDateText("Serviced on:", serviceOn);
    } else if (isNotDefaultDate(serviceBy)) {
      return renderDateText("Service by:", serviceBy);
    }
    return null;
  };

  const renderServiceText = () => {
    if (["Serviced", "Complete", "Delivered"].includes(orderStatus) && isNotDefaultDate(serviceOn)) {
      return renderDateText("Serviced on:", serviceOn);
    } else if (isNotDefaultDate(serviceBy)) {
      return renderDateText("Service by:", serviceBy);
    }
    return null;
  };

  if (orderStatus === "Canceled") return null;

  switch (orderType) {
    case "Delivery":
    case "Supplies":
    case "Supply":
      return renderDeliveryText();
    case "Pick Up":
    case "Pickup":
      return renderPickupText();
    case "Pick up all":
    case "Pickup all":
      return isNotDefaultDate(pickupBy) ? renderDateText("Pickup by:", pickupBy) : null;
    case "Service Request":
      return renderServiceRequestText();
    case "Service":
      return renderServiceText();
    default:
      return null;
  }
};

export default OrderTypeText;
