import { Card, Dialog, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from './LocationPopup.module.scss';
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ETA from "../../../components/ETA/ETA";
import { useTranslation } from "react-i18next";

function locationPopupLoadScript(src, position, id, initMap) {
    if (!position) {
        return;
    }
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.defer = true;

    script.src = src;
    position.appendChild(script);
    script.addEventListener('load', initMap)
    window.initMap = initMap;
}

export function initMap(location, addressDetails) {
    const myLatLng = location;
    const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 10,
        center: myLatLng,
    });
    const marker = new google.maps.Marker({
        position: myLatLng,
        map,
    });

   
    const infoWindowContent = ` 
    <div class=${styles.infoWindowContainer}>
       <p> ${addressDetails?.addressLine1},</p>
       ${addressDetails?.addressLine2 && `<p>${addressDetails?.addressLine2},</p>`} 
        <p>${addressDetails?.city}, ${addressDetails?.state}, ${addressDetails?.zipcode}, ${addressDetails?.country}</p>
    </div>`;

    const infoWindow = new google.maps.InfoWindow({
        content: infoWindowContent
    });
    marker.addListener('click', () => {
        infoWindow.open(map, marker);
    });
    infoWindow.open(map, marker);
}

const LocationPopup = ({ orderId, location, eta, orderStatus, openLocationPopup, setOpenLocationPopup, addressDetails, arrivalTime, formattedArrivalTime, isDelayed, etaWindow}) => {
    const { t } = useTranslation();
    const loaded = useRef(false);
    const handleClosePopup = () => {
        setOpenLocationPopup(!openLocationPopup);
    }

    const googleMapApiKey = useSelector((state) => state.authState.userData?.data?.application_settings?.google_maps_api_key_front_end)

    useEffect(() => {
        if (typeof window !== 'undefined' && !loaded.current) {
            if (!document.querySelector('#google-maps')) {
                locationPopupLoadScript(
                    `https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}&callback=Function.prototype&v=weekly`,
                    document.querySelector('head'),
                    'google-maps',
                    () => initMap(location, addressDetails)
                );
            }
            loaded.current = true;
        }
        return () => {
            const mapScript = document.querySelector('#google-maps');
            if (mapScript && mapScript.parentNode) {
                mapScript.parentNode.removeChild(mapScript);
            }
        }
    }, [location, googleMapApiKey])

    return <div className={styles.locationPopup}>
        <Dialog open={openLocationPopup}
            className={styles.locationDialog}
            onClose={handleClosePopup}
            data-testid="dialogWindow"
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "800px",
                        height: '100%',
                        maxHeight: '480px'
                    },
                },
            }}
        >
            <DialogTitle className={styles.locationDialogTitle}>
                <span className={styles.locationTitle}>{t("mapLocationPopup.title")}: {orderId}
                    <IconButton
                        className={styles.closePopup}
                        onClick={handleClosePopup}
                        data-testid="closePopup"
                    >
                        <CloseIcon />
                    </IconButton>
                </span>
            </DialogTitle>
            <Card>
                <div id="map" style={{ height: '385px' }}></div>
                <div className={styles.etaStatus}>
                    <ETA manualEta={eta} orderStatus={orderStatus} arrivalTime={arrivalTime} formattedArrivalTime={formattedArrivalTime} isDelayed={isDelayed} etaWindow={etaWindow}/>
                </div>
            </Card>
        </Dialog>
    </div >
}

export default LocationPopup;