import React from 'react';
import { Chip, Typography } from '@mui/material';
import style from './GenericEquipmentTags.module.scss';
import { LineOfBusinessEnum } from '../../../constants/BusinessConstants';

const GenericEquipmentTags = ({ item, settingForBranch }) => {
  const isMember = LineOfBusinessEnum.patientToMember.toString().toLowerCase() === 'member';

  const chips = [
    { condition: item?.kit || item?.kit_item, label: 'Kit', customStyle: 'bgDarkGrey' },
    { condition: item?.approval, label: 'Approval', customStyle: 'bgRed' },
    { condition: settingForBranch?.pre_authorization_enabled && (item?.pre_auth || item?.need_pre_authorization), label: 'Pre-Auth', customStyle: 'bgOrange' },
    { condition: item?.is_rx, label: 'Rx', customStyle: 'bgOrange' },
    { condition: (isMember && (!item?.cap && item?.oof)) || (isMember &&  (!item?.cap && item?.oof == undefined)) , label: 'OOF', customStyle: 'bgRed' },
    { condition: (isMember && item?.cap) || item?.formulary, label: 'Formulary', customStyle: 'bgBaseColor' },
    { condition: (!isMember && !item?.cap) || item?.non_cap, label: 'Non-CAP', customStyle: 'bgRed' },
    { condition: (!isMember && item?.cap), label: 'Cap', customStyle: 'bgBaseColor' },
    { condition: item?.hidden_equipment || item?.hidden, label: 'Hidden', customStyle: 'bgDarkGrey' },
  ];

  return (
    <div className={style.chips}>
      {chips.map(
        (chip) =>
          Boolean(chip.condition) && (
            <Typography key={chip.label}>
              <Chip
                label={chip.label}
                size="small"
                className={`${style.myCustomChip} displayFlex ${chip.customStyle}`}
              />
            </Typography>
          )
      )}
    </div>
  );
};

export default GenericEquipmentTags;
