import React, { useEffect, useState } from "react";
import styles from "./supply-details.module.scss";
import {
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import FollowButton from "../../../components/followButton/followButton";
import style from "../OrdersDetails/OrdersDetails.module.scss";
import InventoryIcon from "@mui/icons-material/Inventory";
import { EMRTooltip } from "../../../components/MuiCustomComponent/MuiCustomComponent";
import {
  DischargeReason,
  EntityTypeForFollow,
  FacilityTypeEnum,
  GenderEnum,
  LiterFlowTypeEnum,
  OrderDetailsPatientEnum,
  PhoneEnum,
  RelationShipEnum,
  SupplierSpecial,
  SuppliesDeliveryMethod,
} from "../../../common/enums";
import { useTranslation } from "react-i18next";
import {
  HTTPMethod,
  callApi as supplyDetail,
} from "../../../services/HttpService/HttpService";
import { OrdersComponentApi } from "../../../constants/ApiConstants";
import { Link, useNavigate } from "react-router-dom";
import { LineOfBusinessEnum } from "../../../constants/BusinessConstants";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NoData from "../../../components/noData/noData";
import loader from "../../../../src/assets/loader.gif";
import PhoneIcon from "@mui/icons-material/Phone";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import withTitle from "../../../components/withTitle/withTitle";

const supplyOrdersStatusColors = ["#619633", "#FAC300", "#B6B6B6", "#777777"];
export function supplyStatusColorForChip(orderStatus) {
  switch (orderStatus) {
    case "Shipping":
      return supplyOrdersStatusColors[0];
    case "InProgress":
      return supplyOrdersStatusColors[1];
    case "Queued":
      return supplyOrdersStatusColors[3];
    case "Submitted":
      return supplyOrdersStatusColors[3];
    default:
      return supplyOrdersStatusColors[3];
  }
}
const supplyStatusColors = [
  "#619633",
  "#777777",
  "#D2322D",
];
export function getStatusChipSupplyDetails(patientStatus) {
  switch (patientStatus) {
    case "Active":
      return supplyStatusColors[0];
    case "InActive":
      return supplyStatusColors[1];
    default:
      return supplyStatusColors[2];
  }
}
export const getSuppliesDeliveryMethod = (deliveryMethod) => {
  switch(deliveryMethod){
    case 0: return SuppliesDeliveryMethod[deliveryMethod];
    case 1: return `${LineOfBusinessEnum.patientToMember}`; 
    case 2: return `${LineOfBusinessEnum.patientToMember} Care Of Branch`;  
    case 3: return SuppliesDeliveryMethod[deliveryMethod];
    default: break; 
  }
}

const SupplyDetails = ({ setOrder }) => {
  const { t } = useTranslation();
  const [isFollowButton, setFollowButton] = useState(false);
  const [isFollowFollowing, setSelectedFollowFollowing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [supplyOrdersDetailsData, setSupplyOrdersDetailsData] = useState();
  const [proofOfDelivery, setProofOfDelivery] = useState();
  const navigate = useNavigate();

  const handleSupplyOrdersDetails = async () => {
    setIsLoading(true);
    const supplyOrderId = location.pathname.split("/").pop();
    const apiEndpoint = OrdersComponentApi.supplyDetails.replace(
      "{purchase_order_id}",
      supplyOrderId
    );
    const response = await supplyDetail(HTTPMethod.Get, apiEndpoint);
    if (response && response.status_code == 200 && response.data) {
      setSupplyOrdersDetailsData(response.data.supply_details);
      setOrder(response.data?.supply_details?.orders?.purchase_order_id);
      setSelectedFollowFollowing(response?.data?.supply_details?.orders?.follow);
      setIsLoading(false);
      if (response.data?.supply_details?.orders?.order_status !== "Shipping") {
        setFollowButton(true);
      }
      sessionStorage.setItem('supplyDetailsData', JSON.stringify(response.data) )
    }
  };

  const handleViewOtherOrderPage = (order_id) => {
    localStorage.setItem("drawerSelectedItem", "Orders");
    navigate("/view-other-orders/" + order_id,{ state: { navigateFromSupply: true } });
  };
  
  const proofOfDeliveryDetails = async () => {
    const supplyOrderId = location.pathname.split("/").pop();
    const response = await supplyDetail(
      HTTPMethod.Get,
      OrdersComponentApi.supplyProofOfDelivery.replace(
        "{purchase_order_id}",
        supplyOrderId
      )
    );
    if (response && response.status_code == 200 && response.data) {
      setProofOfDelivery(response.data.proof_of_delivery);
    }
  };

  useEffect(() => {
    handleSupplyOrdersDetails();
    proofOfDeliveryDetails();
  }, []);

  return (
    <div className={styles.SupplyDetails} data-testid="SupplyDetails">
      {supplyOrdersDetailsData ? (
        <div>
          <Card
            variant="outlined"
            sx={{ textAlign: "left", marginTop: "50px" }}
            className={style.customBox2}
          >
            <CardContent>
              <Typography>
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                >
                  <Typography className={styles.orderMargin}>
                    <p className={style.marginClass}>
                      {supplyOrdersDetailsData?.orders?.purchase_order_id}
                    </p>
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={2}
                    display="flex"
                    marginBottom={"10px"}
                    alignItems={{ sm: "flex-start" }}
                    justifyContent={{
                      xs: "flex-start",
                      sm: "space-between",
                    }}
                  >
                    {isFollowButton && (
                      <FollowButton
                        toggleValue={isFollowFollowing}
                        toggleSetValue={setSelectedFollowFollowing}
                        followPayload={{
                          entityId: supplyOrdersDetailsData?.orders?.purchase_order_id,
                          userId: [],
                          entityType: EntityTypeForFollow.SupplyOrder,
                        }}
                      />
                    )}
                  </Stack>
                </Box>
                <Box style={{ position: "relative" }}>
                  <Box
                    display="flex"
                    flexDirection={{ xs: "column", sm: "row" }}
                    alignItems={{ sm: "flex-start" }}
                    justifyContent={{
                      xs: "flex-start",
                      sm: "space-between",
                    }}
                  >
                    <Typography className={styles.chipStyling}>
                      {supplyOrdersDetailsData?.orders?.order_status && (
                        <Chip
                          label={supplyOrdersDetailsData?.orders?.order_status}
                          size="small"
                          alignItems={{ sm: "flex-start" }}
                          className={styles.customChip}
                          sx={{
                            backgroundColor: supplyStatusColorForChip(
                              supplyOrdersDetailsData?.orders?.order_status
                            ),
                          }}
                        ></Chip>
                      )}
                    </Typography>
                  </Box>
                  <Grid container alignItems="center" marginTop={"10px"}>
                    <Grid item xs={12} sm={6}>
                      <div>
                        <Typography className={styles.branchText}>
                          {supplyOrdersDetailsData?.orders?.branch_name}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className={styles.gridAlignment}>
                        <div>
                          <div className={style.deliveryStatusImg}>
                            <InventoryIcon
                              className={`${style.iconSize} ${style.backgroundColorBlue}`}
                            />
                            <span className={style.deliveryStatusText}>
                              Supplies
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Typography>
            </CardContent>
          </Card>
      {supplyOrdersDetailsData?.patient_info?.patient_id !== 0 && <Card
            className={style.customBox2}
            variant="outlined"
            sx={{ textAlign: "left", marginTop: "30px" }}
          >
            <CardContent>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="h6" component="h2">
                    <Box
                      display="flex"
                      flexDirection={{ xs: "column", sm: "row" }}
                      alignItems={{ sm: "flex-start" }}
                      justifyContent={{
                        xs: "flex-start",
                        sm: "space-between",
                      }}
                    >
                      <Box
                        flex="1"
                        display="flex"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                      >
                        <Typography
                          sx={{ display: "flex", alignItems: "center" }}
                          className={style.customTypography}
                        >
                          <div className={`${style.marginClass}`}>
                            <Link
                              to={`/${LineOfBusinessEnum.patientsToMembers.toLowerCase()}/${LineOfBusinessEnum.patientToMember.toLowerCase()}-details/${
                                supplyOrdersDetailsData?.patient_info
                                  ?.patient_id
                              }`}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              className={styles.customLink}
                              data-testid="patientLink"
                            >
                              {
                                supplyOrdersDetailsData?.patient_info
                                  ?.patient_last_name
                              }
                              , <span> </span>
                              {
                                supplyOrdersDetailsData?.patient_info
                                  ?.patient_first_name
                              }{" "}
                              {
                                supplyOrdersDetailsData?.patient_info
                                  ?.patient_middle_initial
                              }{" "}
                            </Link>
                          </div>
                         {supplyOrdersDetailsData?.patient_info?.patient_status && 
                          <Chip
                            label={OrderDetailsPatientEnum[supplyOrdersDetailsData?.patient_info?.patient_status]}
                            size="small"
                            className={style.myCustomClass2}
                            sx={{color: "var(--bg-white)", backgroundColor: getStatusChipSupplyDetails(supplyOrdersDetailsData?.patient_info?.patient_status),
                            }}
                          />}
                          {supplyOrdersDetailsData?.patient_info?.emr_integration_flag && (
                            <EMRTooltip
                              title={
                                <span className={style.tooltipStyling}>
                                  {t("PatientDetails.EMRdescription")}{" "}
                                </span>
                              }
                              placement="right"
                            >
                              <Chip
                                label={t("PatientDetails.EMR")}
                                size="small"
                                alignItems={{ sm: "auto" }}
                                className={style.emr}
                              />
                            </EMRTooltip>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Typography>
                </Grid>
                {supplyOrdersDetailsData?.patient_info?.patient_id && (
                  <Grid item>
                    <Typography className={styles.scopedClassNameLine}>
                      <span className={style.customText4}>
                        {t("PatientsListing.dmeTrackId")}:{" "}
                      </span>
                      <span className={style.customTextBold1}>
                        {supplyOrdersDetailsData?.patient_info?.patient_id}
                      </span>
                    </Typography>
                  </Grid>
                )}
                {supplyOrdersDetailsData?.patient_info?.mrn && (
                  <Grid item>
                    <Typography className={styles.scopedClassNameLine}>
                      <span className={style.customText4}>
                        {t("PatientsListing.mrn")}:{" "}
                      </span>
                      <span className={style.customTextBold1}>
                        {supplyOrdersDetailsData?.patient_info?.mrn}
                      </span>
                    </Typography>
                  </Grid>
                )}
                {supplyOrdersDetailsData?.patient_info?.date_of_birth && (
                  <Grid item>
                    <Typography className={styles.scopedClassNameLine}>
                      <span className={style.customText4}>
                        {t("PatientsDetails.dateOfBirth")}:{" "}
                      </span>
                      <span className={style.customTextBold1}>
                        {supplyOrdersDetailsData?.patient_info?.date_of_birth}
                      </span>
                    </Typography>
                  </Grid>
                )}

                {supplyOrdersDetailsData?.patient_info?.height && (
                  <Grid item>
                    <Typography className={styles.scopedClassNameLine}>
                      <span className={style.customText4}>Height:</span>
                      <span className={style.customTextBold1}>
                        {supplyOrdersDetailsData?.patient_info?.height}
                      </span>
                    </Typography>
                  </Grid>
                )}

                {supplyOrdersDetailsData?.patient_info?.weight && (
                  <Grid item>
                    <Typography className={styles.scopedClassNameLine}>
                      <span className={style.customText4}>Weight:</span>
                      <span className={style.customTextBold1}>
                        {supplyOrdersDetailsData?.patient_info?.weight}
                      </span>
                    </Typography>
                  </Grid>
                )}

                {LineOfBusinessEnum.patientToMember?.toLocaleLowerCase() == "member" &&
                  supplyOrdersDetailsData?.patient_info?.sex && (
                    <Grid item>
                      <Typography className={styles.scopedClassNameLine}>
                        <span className={style.customText4}>Sex:</span>
                        <span className={style.customTextBold1}>
                          {
                            GenderEnum[
                              supplyOrdersDetailsData?.patient_info?.sex
                            ]
                          }
                        </span>
                      </Typography>
                    </Grid>
                  )}
                {supplyOrdersDetailsData?.patient_info?.liter_flow_min &&
                  supplyOrdersDetailsData?.patient_info?.liter_flow_max && (
                    <Grid item>
                      <Typography className={styles.scopedClassNameLine}>
                        <span className={style.customText4}>Liter Flow:</span>
                        <span className={style.customTextBold1}>
                          {supplyOrdersDetailsData?.patient_info?.liter_flow_min?.toFixed(
                            2
                          )}
                          -
                          {supplyOrdersDetailsData?.patient_info?.liter_flow_max?.toFixed(
                            2
                          )}
                        </span>
                      </Typography>
                    </Grid>
                  )}

                {supplyOrdersDetailsData?.patient_info?.liter_flow > 0 && (
                  <Grid item>
                    <Typography className={styles.scopedClassNameLine}>
                      <span className={style.customText4}>
                        Liter Flow Type:
                      </span>
                      <span className={style.customTextBold1}>
                        {
                          LiterFlowTypeEnum[
                            supplyOrdersDetailsData?.patient_info?.liter_flow
                          ]
                        }
                      </span>
                    </Typography>
                  </Grid>
                )}
                {supplyOrdersDetailsData?.patient_info?.archived !== null && (
                  <Grid item>
                    <Typography className={styles.scopedClassNameLine}>
                      <span className={style.customText4}> Archived: </span>
                      <span className={style.customTextBold1}>
                      {supplyOrdersDetailsData?.patient_info?.archived ? "True" : "False"}
                      </span>
                    </Typography>
                  </Grid>
                )}
                {supplyOrdersDetailsData?.patient_info?.discharge_date &&
                  !supplyOrdersDetailsData?.patient_info?.discharge_date.includes(
                    "01/01/0001"
                  ) && (
                    <Grid item>
                      <Typography className={styles.scopedClassNameLine}>
                        <span className={style.customText4}>
                          {" "}
                          Discharge Date:{" "}
                        </span>
                        &nbsp;
                        <span className={style.customTextBold1}>
                          {
                            supplyOrdersDetailsData?.patient_info
                              ?.discharge_date
                          }
                        </span>
                      </Typography>
                    </Grid>
                  )}
                {supplyOrdersDetailsData?.patient_info?.discharge_reason !==
                  " " &&
                  supplyOrdersDetailsData?.patient_info?.discharge_reason !==
                    null && (
                    <Grid item>
                      <Typography className={styles.scopedClassNameLine}>
                        <span className={style.customText4}>
                          {" "}
                          Discharge Reason:{" "}
                        </span>
                        &nbsp;
                        <span className={style.customTextBold1}>
                          {
                            DischargeReason[
                              supplyOrdersDetailsData?.patient_info
                                ?.discharge_reason
                            ]
                          }
                        </span>
                      </Typography>
                    </Grid>
                  )}
                <Grid item>
                  <Typography
                    className={`${styles.scopedClassNameLine} ${styles.idsLevel}`}
                  >
                    {supplyOrdersDetailsData?.has_other_order && (
                      <span className={style.customLink}>
                        <a data-testid="ViewOtherOrderPage"
                          onClick={() => {
                          handleViewOtherOrderPage(
                            supplyOrdersDetailsData?.orders?.purchase_order_id
                          );
                        }}
                        >
                          <div className={styles.displayFlexViewOtherLink}>
                            <span>View other orders</span>
                            <span>
                              <KeyboardArrowRightIcon />
                            </span>
                          </div>
                        </a>
                      </span>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>}
          {supplyOrdersDetailsData?.patient_info?.patient_id !== 0 && <Card variant="outlined" className={style.customBox2}>
            <Box className={style.scopedClassName}>
              <div
                className={style.scopedClassHeading}
                sx={{ marginBottom: "5px" }}
              >
                {t("PatientsDetails.contacts")}
              </div>
            </Box>
            <CardContent sx={{ paddingTop: "24px" }}>
              <Grid container sx={{ width: "100%" }}>
                {supplyOrdersDetailsData?.provider_contacts?.provider_name && (
                  <Grid item>
                    <Typography>
                      <span className={style.customText1}>
                        {
                          supplyOrdersDetailsData?.provider_contacts
                            ?.provider_name
                        }
                        {supplyOrdersDetailsData?.provider_contacts
                          ?.provider_location && (
                          <span>
                            &nbsp;(
                            {
                              supplyOrdersDetailsData?.provider_contacts
                                ?.provider_location
                            }
                            )
                          </span>
                        )}
                        <Chip
                          label="Provider"
                          size="small"
                          className={`${style.myCustomClass2} ${style.myCustomGreyChip} ${styles.customChipProvider}`}
                        />
                      </span>
                    </Typography>
                  </Grid>
                )}
                <Grid item sx={{ width: "100%" }}>
                  {supplyOrdersDetailsData?.provider_contacts?.phone_number && (
                    <Typography>
                      <span className={style.customText3}>
                        {supplyOrdersDetailsData?.provider_contacts?.phone_number.replace(
                          /(\d{3})-(\d{3})-(\d{4})/,
                          "($1) $2-$3"
                        )}
                        <PhoneIcon className={style.smallMargin} />
                        <span className={style.mobileText}>(Landline)</span>
                        {supplyOrdersDetailsData?.provider_contacts
                          ?.extension && (
                          <span className={style.mobileText}>
                            (
                            {
                              supplyOrdersDetailsData?.provider_contacts
                                ?.extension
                            }
                            )
                          </span>
                        )}
                      </span>
                    </Typography>
                  )}
                  {supplyOrdersDetailsData?.provider_contacts
                    ?.after_hours_phone && (
                    <span className={style.customText3}>
                      <span className={style.customText1}>
                        After hours phone:&nbsp;
                      </span>
                      <span className={style.customText3}>
                        {supplyOrdersDetailsData?.provider_contacts?.after_hours_phone.replace(
                          /(\d{3})-(\d{3})-(\d{4})/,
                          "($1) $2-$3"
                        )}
                        <PhoneIcon className={style.smallMargin} />
                        <span className={style.mobileText}>(Landline)</span>
                        {supplyOrdersDetailsData?.provider_contacts
                          ?.extension && (
                          <span className={style.mobileText}>
                            (
                            {
                              supplyOrdersDetailsData?.provider_contacts
                                ?.extension
                            }
                            )
                          </span>
                        )}
                      </span>
                    </span>
                  )}
                  {(supplyOrdersDetailsData?.provider_contacts?.provider_name ||
                    supplyOrdersDetailsData?.provider_contacts
                      ?.provider_location ||
                    supplyOrdersDetailsData?.provider_contacts?.extension ||
                    supplyOrdersDetailsData?.provider_contacts?.phone_number ||
                    supplyOrdersDetailsData?.provider_contacts
                      ?.after_hours_phone) &&
                    (supplyOrdersDetailsData?.hospice_branch_contact ||
                      ((supplyOrdersDetailsData?.technician_details
                        .technician_name ||
                        supplyOrdersDetailsData?.technician_details?.phone ||
                        supplyOrdersDetailsData?.technician_details
                          .phone_type) &&
                        supplyOrdersDetailsData?.patient_contacts?.length >
                          0)) && (
                      <Divider
                        sx={{
                          marginTop: "25px !important",
                          marginBottom: "23px !important",
                        }}
                        variant="middle"
                      />
                    )}
                </Grid>

                {supplyOrdersDetailsData?.hospice_branch_contact
                  ?.hospice_name && (
                  <Grid item>
                    <Typography>
                      <span className={style.customText1}>
                        {
                          supplyOrdersDetailsData?.hospice_branch_contact
                            ?.hospice_name
                        }
                        {supplyOrdersDetailsData?.hospice_branch_contact
                          ?.hospice_branch_name && (
                          <span>
                            &nbsp;(
                            {
                              supplyOrdersDetailsData?.hospice_branch_contact
                                ?.hospice_branch_name
                            }
                            )
                          </span>
                        )}
                        <Chip
                          label={LineOfBusinessEnum.hospiceToPace}
                          size="small"
                          className={`${style.myCustomClass2} ${style.myCustomGreyChip} ${styles.customChipProvider}`}
                        />
                      </span>
                    </Typography>
                  </Grid>
                )}
                <Grid item sx={{ width: "100%" }}>
                  {supplyOrdersDetailsData?.hospice_branch_contact
                    ?.phone_number && (
                    <Typography>
                      <span className={style.customText3}>
                        {supplyOrdersDetailsData?.hospice_branch_contact?.phone_number.replace(
                          /(\d{3})-(\d{3})-(\d{4})/,
                          "($1) $2-$3"
                        )}
                        <PhoneIcon className={style.smallMargin} />
                        <span className={style.mobileText}>(Landline)</span>
                        {supplyOrdersDetailsData?.hospice_branch_contact
                          ?.extension && (
                          <span className={style.mobileText}>
                            (
                            {
                              supplyOrdersDetailsData?.hospice_branch_contact
                                ?.extension
                            }
                            )
                          </span>
                        )}
                      </span>
                    </Typography>
                  )}
                  {supplyOrdersDetailsData?.hospice_branch_contact
                    ?.after_hours_phone && (
                    <span className={style.customText3}>
                      <span className={style.customText1}>
                        After hours phone:&nbsp;
                      </span>
                      <span className={style.customText3}>
                        {supplyOrdersDetailsData?.hospice_branch_contact?.after_hours_phone.replace(
                          /(\d{3})-(\d{3})-(\d{4})/,
                          "($1) $2-$3"
                        )}
                        <PhoneIcon className={style.smallMargin} />
                        <span className={style.mobileText}>(Landline)</span>
                        {supplyOrdersDetailsData?.hospice_branch_contact
                          ?.extension && (
                          <span className={style.mobileText}>
                            (
                            {
                              supplyOrdersDetailsData?.hospice_branch_contact
                                ?.extension
                            }
                            )
                          </span>
                        )}
                      </span>
                    </span>
                  )}
                  {(supplyOrdersDetailsData?.technician_details
                    ?.technician_name ||
                    supplyOrdersDetailsData?.technician_details?.phone ||
                    supplyOrdersDetailsData?.technician_details?.phone_type ||
                    supplyOrdersDetailsData?.technician_details?.phone_type !==
                      0) &&
                    supplyOrdersDetailsData?.patient_contacts?.length > 0 && (
                      <Divider
                        sx={{
                          marginTop: "25px !important",
                          marginBottom: "15px !important",
                        }}
                        variant="middle"
                      />
                    )}
                </Grid>
                <div className={styles.patientContact}>
                  {supplyOrdersDetailsData?.patient_contacts?.length > 0 &&
                    supplyOrdersDetailsData.patient_contacts?.map(
                      (contactData, index) =>
                        contactData?.is_primary_contact && (
                          <div key={contactData?.phone}>
                            <Grid item>
                              <Typography className={style.marginNurse}>
                                <span className={style.customText1}>
                                  {contactData?.contact_name && (
                                    <span>
                                      {contactData.contact_name}&nbsp;(
                                      {
                                        RelationShipEnum[
                                          contactData?.relationship
                                        ]
                                      }
                                      )
                                      {contactData.is_primary_contact && (
                                        <Chip
                                          label="Primary"
                                          size="small"
                                          className={`${style.myCustomClass2} ${style.myCustomChip} ${styles.customContactChip}`}
                                        />
                                      )}
                                    </span>
                                  )}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item style={{ width: "100%" }}>
                              <Typography>
                                <span className={style.customText3}>
                                  {contactData.phone.replace(
                                    /(\d{3})-?(\d{3})-?(\d{4})/,
                                    "($1) $2-$3"
                                  )}
                                  {contactData.phone_type &&
                                  contactData.phone_type != 0 ? (
                                    <>
                                      <StayCurrentPortraitIcon
                                        className={style.smallMargin}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <PhoneIcon
                                        className={style.smallMargin}
                                      />
                                      <span className={style.mobileText}>
                                        (Landline)
                                      </span>
                                    </>
                                  )}
                                  <span className={style.mobileText}>
                                    (
                                    {contactData.phone_type &&
                                    contactData.phone_type != 0
                                      ? PhoneEnum[contactData.phone_type]
                                      : "Landline"}
                                    )
                                  </span>
                                </span>
                              </Typography>
                              {contactData.extension && (
                                <div className={style.customFlexContact}>
                                  <Typography>
                                    <span className={style.customText4}>
                                      Ext:{" "}
                                    </span>
                                  </Typography>
                                  <span
                                    className={`${style.customText6} ${styles.paddingLeft}`}
                                  >
                                    {" "}
                                    {contactData.extension}
                                  </span>{" "}
                                </div>
                              )}
                              {contactData.email && (
                                <div className={style.customFlexContact}>
                                  <Typography>
                                    <span className={style.customText4}>
                                      Email:{" "}
                                    </span>
                                  </Typography>
                                  <span
                                    className={`${style.customText6} ${styles.paddingLeft}`}
                                  >
                                    {contactData.email}{" "}
                                  </span>
                                </div>
                              )}
                            </Grid>
                            {supplyOrdersDetailsData?.patient_contacts?.length >
                              1 &&
                              index !==
                                supplyOrdersDetailsData?.patient_contacts
                                  ?.length -
                                  1 && (
                                <Divider
                                  sx={{
                                    marginTop: "25px !important",
                                    marginBottom: "10px !important",
                                  }}
                                  variant="middle"
                                />
                              )}
                          </div>
                        )
                    )}
                </div>
              </Grid>
            </CardContent>
          </Card>}
          {supplyOrdersDetailsData?.patient_info?.patient_id !== 0 && <Card variant="outlined" className={style.customBox2}>
            <Box
              className={`${style.scopedClassName} ${styles.cardMarginBottom}`}
            >
              <div className={style.scopedClassHeading}>
                {t("PatientsDetails.addresses")}
              </div>
            </Box>
            <>
              <CardContent className={styles.cardContentPadding}>
                <Grid container direction="column">
                  {supplyOrdersDetailsData?.address?.address_type == "RES" &&
                    supplyOrdersDetailsData?.address?.address_type !== null && (
                      <Grid item>
                        <Typography>
                          <span className={style.customText4}>
                            Address Type:
                          </span>
                          <span className={style.customText6}>
                            {
                              FacilityTypeEnum[
                                supplyOrdersDetailsData?.address?.address_type
                              ]
                            }
                          </span>
                        </Typography>
                      </Grid>
                    )}
                  {supplyOrdersDetailsData?.address?.location_name && (
                    <Grid item>
                      <Typography>
                        <span className={style.customText4}>
                          {t("PatientsDetails.facilityName")}:{" "}
                        </span>
                        <span className={style.customText6}>
                          {supplyOrdersDetailsData?.address?.location_name}
                        </span>
                      </Typography>
                    </Grid>
                  )}
                  {supplyOrdersDetailsData?.address?.address_type !== "RES" &&
                    supplyOrdersDetailsData?.address?.address_type !== null && (
                      <Grid item>
                        <Typography>
                          <span className={style.customText4}>
                            {t("PatientsDetails.facilityType")}:{" "}
                          </span>
                          <span className={style.customText6}>
                            {
                              FacilityTypeEnum[
                                supplyOrdersDetailsData?.address?.address_type
                              ]
                            }
                          </span>
                        </Typography>
                      </Grid>
                    )}

                  <Grid item>
                    <Typography
                      className={`${style.marginTypography} ${styles.customContainer}`}
                    >
                      <Typography
                        className={`${style.scopedClassColorText} ${style.addressFontSize}`}
                      >
                        <p className="marginZero">
                          {supplyOrdersDetailsData?.address?.address_line1 && (
                            <span>
                              {supplyOrdersDetailsData?.address?.address_line1},
                            </span>
                          )}
                        </p>
                        <p className="marginZero">
                          {supplyOrdersDetailsData?.address?.address_line2 && (
                            <span>
                              {supplyOrdersDetailsData?.address?.address_line2},
                            </span>
                          )}
                        </p>
                        <p className="marginZero">
                          {supplyOrdersDetailsData?.address?.city && (
                            <span>
                              {supplyOrdersDetailsData?.address?.city},{" "}
                            </span>
                          )}
                          {supplyOrdersDetailsData?.address?.state && (
                            <span>
                              {supplyOrdersDetailsData?.address?.state},{" "}
                            </span>
                          )}
                          {supplyOrdersDetailsData?.address?.zip_code && (
                            <span>
                              {supplyOrdersDetailsData?.address?.zip_code},{" "}
                            </span>
                          )}
                          {supplyOrdersDetailsData?.address?.country && (
                            <span>
                              {supplyOrdersDetailsData?.address?.country}
                            </span>
                          )}
                        </p>
                      </Typography>
                    </Typography>
                  </Grid>
                  {supplyOrdersDetailsData?.address?.room_number && (
                    <Grid item>
                      <Typography>
                        <span className={style.customText4}>Room #:</span>
                        <span className={style.customText6}>
                          {supplyOrdersDetailsData?.address?.room_number}
                        </span>
                      </Typography>
                    </Grid>
                  )}
                  {supplyOrdersDetailsData?.address?.bed_number && (
                    <Grid item>
                      <Typography>
                        <span className={style.customText4}>Bed #:</span>
                        <span className={style.customText6}>
                          {supplyOrdersDetailsData?.address?.bed_number}
                        </span>
                      </Typography>
                    </Grid>
                  )}
                  {supplyOrdersDetailsData?.order_notes && (
                    <Grid item>
                      <Typography>
                        <span className={style.customText4}>
                          Address Notes:
                        </span>
                        <span className={style.customText6}>
                          {supplyOrdersDetailsData?.order_notes}
                        </span>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </>
          </Card>}
          {proofOfDelivery && proofOfDelivery?.length > 0 && (
            <Card variant="outlined" className={style.customBox2}>
              <Box className={style.scopedClassName}>
                <div className={style.scopedClassHeading}>
                  Proof of Delivery
                </div>
              </Box>
              <CardContent>
                <Grid item className={styles.paddingBottom}>
                  <Typography>
                    <span className={style.customText4}>Tracking number:</span>
                    <span className={style.customText6}>
                      <Link to={proofOfDelivery[0]?.carrier_url} className={styles.customLinkTrackingNumber}>
                      {proofOfDelivery[0]?.tracking_number}</Link>
                    </span>
                  </Typography>
                </Grid>
                <Grid item className={styles.paddingBottom}>
                  <Typography>
                    <span className={style.customText4}>Carrier name:</span>
                    <span className={style.customText6}>
                      {proofOfDelivery[0]?.carrier_name}
                    </span>
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          )}
          <Card variant="outlined" className={style.customBox2}>
            <Box
              className={`${style.scopedClassName} ${styles.cardMarginBottom}`}
            >
              <div className={style.scopedClassHeading}>Order Details</div>
            </Box>
            <>
              <CardContent className={styles.cardContentPadding}>
                <Grid container direction="column">
                  {supplyOrdersDetailsData?.orders?.supplier_id && (
                    <Grid item className={styles.paddingBottom}>
                      <Typography>
                        <span className={style.customText4}>Supplier:</span>
                        <span className={style.customText6}>
                          {
                            SupplierSpecial[
                              supplyOrdersDetailsData?.orders?.supplier_id
                            ]
                          }
                        </span>
                      </Typography>
                    </Grid>
                  )}
                  {supplyOrdersDetailsData?.orders?.supplier_sales && (
                    <Grid item className={styles.paddingBottom}>
                      <Typography>
                        <span className={style.customText4}>Supply items:</span>
                        <span className={style.customText6}>
                          {supplyOrdersDetailsData?.orders?.supplier_sales}
                        </span>
                      </Typography>
                    </Grid>
                  )}
                  {supplyOrdersDetailsData?.orders
                    ?.supplier_acknowledge_time && (
                    <Grid item className={styles.paddingBottom}>
                      <Typography>
                        <span className={style.customText4}>
                          Supplier acknowledge time:
                        </span>
                        <span className={style.customText6}>
                          {
                            supplyOrdersDetailsData?.orders
                              ?.supplier_acknowledge_time
                          }
                        </span>
                      </Typography>
                    </Grid>
                  )}
                   {supplyOrdersDetailsData?.total_equipment !== 0 &&
                supplyOrdersDetailsData?.total_equipment && (
                  <Grid item className={styles.paddingBottom}>
                    <Typography>
                      <span className={style.customText4}>
                      {t("NewOrder.uniqueSupplyItems")}:
                      </span>
                      <span className={style.customText6}>
                        {supplyOrdersDetailsData?.total_equipment}
                      </span>
                    </Typography>
                  </Grid>
                )}
                  {supplyOrdersDetailsData?.ordered_on && (
                    <Grid item className={styles.paddingBottom}>
                      <Typography>
                        <span className={style.customText4}>Ordered on:</span>
                        <span className={style.customText6}>
                          {supplyOrdersDetailsData?.ordered_on}
                        </span>
                      </Typography>
                    </Grid>
                  )}
                  {supplyOrdersDetailsData?.ordered_by && (
                    <Grid item className={styles.paddingBottom}>
                      <Typography>
                        <span className={style.customText4}>Ordered by:</span>
                        <span className={style.customText6}>
                          {supplyOrdersDetailsData?.ordered_by} (
                          {supplyOrdersDetailsData?.ordered_by_company_name})
                        </span>
                      </Typography>
                    </Grid>
                  )}
                  {supplyOrdersDetailsData?.orders?.delivery_method && (
                    <Grid item className={styles.paddingBottom}>
                      <Typography>
                        <span className={style.customText4}>
                          Delivery method:
                        </span>
                        <span className={style.customText6}>
                          {getSuppliesDeliveryMethod(supplyOrdersDetailsData?.orders?.delivery_method)}
                        </span>
                      </Typography>
                    </Grid>
                  )}
                  <Grid item>
                    <Box
                      flex="1"
                      display="flex"
                      alignItems={{ xs: "flex-start" }}
                      flexDirection="column"
                      sx={{ marginTop: { xs: 0, md: 0 } }}
                    >
                      {supplyOrdersDetailsData?.patient_nurse_details &&
                        supplyOrdersDetailsData?.patient_nurse_details?.length >
                          0 &&
                        supplyOrdersDetailsData?.patient_nurse_details.some(
                          (patient) =>
                            patient.nurse_name !== null ||
                            patient.nurse_phone !== null
                        ) && (
                          <div className={styles.clipAlignment}>
                            <span
                              className={`${style.customText4} ${styles.nurseAlignment}`}
                            >
                              Associated nurse:
                            </span>
                            {supplyOrdersDetailsData?.patient_nurse_details?.map(
                              (patient) => (
                                <div
                                  key={patient?.nurse_phone}
                                  className={styles.patientNurse}
                                >
                                  <Tooltip
                                    key={patient?.nurse_phone}
                                    title={
                                      patient?.nurse_phone && (
                                        <span className={style.tooltipStyling}>
                                          {patient?.nurse_phone?.replace(
                                            /(\d{3})(\d{3})(\d{4})/,
                                            "($1) $2-$3"
                                          )}
                                        </span>
                                      )
                                    }
                                  >
                                    {patient?.nurse_name && (
                                      <div key={patient?.nurse_name}>
                                        <Chip
                                          label={patient?.nurse_name}
                                          size="small"
                                          className={style.chipMargin}
                                        />
                                      </div>
                                    )}
                                  </Tooltip>
                                </div>
                              )
                            )}
                          </div>
                        )}
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </>
          </Card>
        </div>
      ) : (
        <div className={styles.loaderMargin}>
          {isLoading && (
            <NoData
              message={isLoading && <img alt="Loading..." src={loader} />}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default withTitle(SupplyDetails, "Supply Order Details");
