import React, { useState } from "react";
import styles from "./OrdersTab.module.scss";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/material";
import { Container } from "@mui/system";
import Breadcrumb from "../../../components/Breadcrumb/MuiBreadcrumb";
import {
  AntTabsForOrders,
  AntTabForOrders,
  defaultTheme,
} from "../../../components/MuiCustomComponent/MuiCustomComponent";
import { faFolderOpen} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InventoryIcon from "@mui/icons-material/Inventory";
import WheelchairPickupIcon from "@mui/icons-material/WheelchairPickup";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { useTranslation } from "react-i18next";
import OrdersDetails from "../OrdersDetails/OrdersDetails";
import OrdersEqiupment from "../OrdersEquipment/OrdersEquipment";
import OrdersNotes from "../OrdersNotes/OrdersNotes";
import DescriptionIcon from "@mui/icons-material/Description";
import PrescriptionListing from "../../prescription-module/prescription-listing/prescription-listing";
import { useSelector } from "react-redux";
import SupplyDetails from "../supply-details/supply-details";
import { useLocation } from "react-router-dom";
import SupplyEquipment from "../SupplyEquipment/SupplyEquipment";
import CustomTabPanel from "../../../components/CustomTabPanel/CustomTabPanel";
import PreAuthorizationTab from "../../../components/PreAuthorizationTab/PreAuthorizationTab";
import PageTour from "../../../common/WalkThrough/pageTour/pageTour";

const OrdersTab = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [orderDetails, setOrderDetails ] = useState("");
  const [supplyDetails, setSupplyDetails] = useState("");
  const [orderPatientId, setOrderPatientId ] = useState("");
  const breadcrumbItems = [
    { label: "Orders", link: `/orders` },
    { label: location.pathname.includes("supply") ? supplyDetails : orderDetails }
  ];
  const companySettings = useSelector(state => state.authState.userData?.data?.company_settings);
  const showOrdersPreAuthModule = companySettings?.find(val => val.module_id == 76)
  const isO2prescriptionModule = companySettings?.find(val => val.module_id == 91)

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabsData = [
    { icon: <DescriptionIcon />, label: "Details", component: <OrdersDetails setOrder={setOrderDetails} setOrderPatientId={setOrderPatientId} />, show: true },
    { icon: <WheelchairPickupIcon />, label: "Equipment", component: <OrdersEqiupment />, show: true },
    { icon: <PendingActionsIcon />, iconPosition: "top", label: t("PatientsTabs.preAuth"), component: <PreAuthorizationTab  patientId={orderPatientId}/>, show: showOrdersPreAuthModule },
    { icon: <FontAwesomeIcon icon={faFolderOpen} className={styles.myCustomClassFont} />, iconPosition:"top", label: t("PatientsTabs.documents"), component: <PrescriptionListing />, show: true},
    { icon: <ChatBubbleIcon />, iconPosition: "top", label: t("PatientsTabs.notes"), component: <OrdersNotes />, show: true }
  ];
  const supplyTabsData = [
    { icon: <DescriptionIcon />, label: "Details", component: <SupplyDetails setOrder={setSupplyDetails} />, show: true },
    { icon: <InventoryIcon />, label: "Supplies",component: <SupplyEquipment />,  show: true },
    { icon: <ChatBubbleIcon />, iconPosition: "top", label: t("PatientsTabs.notes"),  component: <OrdersNotes />, show: true }
  ];
  const filteredOrdersTabsData = location.pathname.includes("supply") ? supplyTabsData.filter(tab => tab.show) : tabsData.filter(tab => tab.show);

  return (
    <>
      { <PageTour prop={'OrderDetailsTab'}/>}
    <div className={styles.OrdersTab} data-testid="OrdersTab">
      <ThemeProvider theme={defaultTheme}>
        <Box
          component="main"
          className={styles.customContainer}
          sx={{ flexDirection: "column" }}
        >
          <CssBaseline />
          <Box component="main" className={styles.customContainer}>
            <Container className="mainContainer">
              <Breadcrumb items={breadcrumbItems} />
              <AntTabsForOrders
                value={value}
                onChange={handleChange}
                aria-label="ant example"
                variant="scrollable"
                scrollButtons="off"
                className={styles.iconContiner}
                numberOfTabs={filteredOrdersTabsData.length}
              >
              {filteredOrdersTabsData.map((tab) => (
                  <AntTabForOrders key={tab.label} className={tab.label} icon={tab.icon} iconPosition={tab.iconPosition} label={tab.label} />
                ))}
              </AntTabsForOrders>
              {filteredOrdersTabsData.map((tab, index) => (
                <CustomTabPanel key={tab.label} value={value} index={index}>
                  {tab.component}
                </CustomTabPanel>
              ))}
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
    </>
  );
};

export default OrdersTab;