import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../AuthSlice/AuthSlice";
import supportReducer from '../RelaunchSlice/RelaunchSlice'
import subMenuToggleSlice from "../SubMenuToggleSlice/SubMenuToggleSlice";
const Store = configureStore({
  reducer: {
    authState: authReducer,
    drawerSubNav : supportReducer,
    drawer: subMenuToggleSlice
  },
})
export default Store;