import { Card, CardContent, Grid, Box, Button } from '@mui/material'
import React, { useState } from 'react'
import OrdersProofDetail from '../OrdersProofDetail/OrdersProofDetail';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import styles from "./ProofOrderDetailCards.module.scss";

const ProofOrderDetailCards = ({ proofData, orderDetailsData }) => {
    const [orderDetailsProofOfDelivery, setOrderDetailsProofOfDelivery] = useState(null);

    const handleOpenDialog = () => {
        setOrderDetailsProofOfDelivery(orderDetailsData?.orders);
    };
    const viewOnMap = () => {
        if (proofData?.latitude && proofData?.longitude) {
            const url = `https://www.google.com/maps?q=${proofData?.latitude},${proofData?.longitude}`;
            window.open(url, "_blank");
        }
    };

    return (
        <>
            {proofData && (
                <Card variant="outlined" className={styles.customBox2}>
                    <Box sx={{ marginBottom: "8px" }} className={styles.scopedClassName}>
                        <div className={styles.scopedClassHeading}>
                            Proof of {proofData?.order_type}
                        </div>
                    </Box>
                    <CardContent>
                        <div>
                            <Grid container direction="column">
                                <div className={styles.flexContainer}>
                                    <div className={styles.gridContainer}>
                                        {proofData?.attachments?.map((image, index) => (
                                            <img
                                                key={`uniqueKey ${image}`}
                                                src={image?.url}
                                                alt={`Image ${index + 1}`}
                                                className={styles.imageContainer}
                                                data-testid="OpenDialog"
                                                onClick={() => handleOpenDialog()}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                            {orderDetailsProofOfDelivery && <OrdersProofDetail
                                proofData={proofData}
                                setProofData={setOrderDetailsProofOfDelivery}
                            />}
                        </div>
                        {proofData?.notes && <Box className={styles.customNotesStyle}>
                            <span className={styles.customText4}>Note(s): </span>
                            <span className={styles.wordWrapContact}>
                                {proofData?.notes}
                            </span>
                        </Box>}
                        <Box className={styles.flexContainerProofOfDelivery}>
                            <div className={styles.flexCenter}>
                                <span className={styles.textStyle}>
                                    Geo Verification:
                                </span>
                                {proofData.geo_verified ? (
                                    <>
                                        <span className={styles.greenBoldText}>
                                            Pass
                                        </span>
                                        <span className={styles.greenTextWithMargin}>
                                            <CheckCircleIcon className={styles.fontSizeLarge} />
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span className={styles.redBoldText}>
                                            Fail
                                        </span>
                                        <span className={styles.redTextMargin}>
                                            <ErrorIcon className={styles.textStyleProofOfDelivery} />
                                        </span>
                                    </>
                                )}
                            </div>
                            <div className={styles.flexButton}>
                                <span className={styles.marginLeftAuto}>
                                    <Button
                                        data-testid="viewOnMap"
                                        variant="contained"
                                        size="small"
                                        className={styles.customButtonStyle}
                                        onClick={viewOnMap}
                                    >
                                        <MapRoundedIcon className={styles.iconDimensions} />
                                        View On Map
                                    </Button>
                                </span>
                            </div>
                        </Box>
                    </CardContent>
                </Card>
            )}
        </>
    )
}

export default ProofOrderDetailCards