import React, { useEffect, useState } from 'react';
import styles  from './../OrdersEquipment/OrdersEquipment.module.scss'
import { Box, Card, CardContent, Chip, Grid } from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import { HTTPMethod, callApi as supplyEquipmentRequest} from '../../../services/HttpService/HttpService';
import { OrdersComponentApi } from '../../../constants/ApiConstants';
import NoData from '../../../components/noData/noData';
import loader from "../../../assets/loader.gif";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const SupplyEquipment = () => {
  const supplyDetailsData = JSON.parse(sessionStorage.getItem('supplyDetailsData'))
  const [isLoadingSupplyEquipment, setLoadingSupplyEquipment] = useState(true);
  const [supplyOrderEquipment, setSupplyOrderEquipment] = useState([])
  const [expandedStatesForSupply, setExpandedStatesForSupply] = useState(true);
  let showMessageForSupplyEquipment = `There is no supply item in this order.`;

  const fetchSupplyEquipmentData = async () => {
    setLoadingSupplyEquipment(true);
    const response = await supplyEquipmentRequest(HTTPMethod.Get,
      OrdersComponentApi.supplyEquipment.replace(':purchase_order_id', supplyDetailsData?.supply_details?.orders?.purchase_order_id).replace(':branchId', supplyDetailsData?.supply_details?.orders?.branch_id));
      if (response && response.status_code == 200 && response.data) {
        setSupplyOrderEquipment(response.data.supply_items);
        setLoadingSupplyEquipment(false);
      }
    } ;
  useEffect(() => {
    fetchSupplyEquipmentData()
  }, []);
  const toggleExpandedForSupply = () => {
    setExpandedStatesForSupply(!expandedStatesForSupply);
  };
  return (
    <div className={styles.SupplyEquipment} data-testid="SupplyEquipment">
      {supplyOrderEquipment && supplyOrderEquipment?.length > 0 ? (
        <Card variant="outlined" className={styles.customBox2}>
          <Box className={styles.scopedClassName} >
            <div className={styles.scopedClassHeading} >
              <span>Supply equipment</span>
              {expandedStatesForSupply && (
                <ExpandLessIcon
                data-testid="ExpandLessIcon"
                  onClick={toggleExpandedForSupply}
                  className={styles.toggleExpand}
                />
              )}
              {!expandedStatesForSupply && (
                <ExpandMoreIcon
                data-testid="ExpandMoreIcon"
                  onClick={toggleExpandedForSupply}
                  className={styles.toggleExpand}
                />
              )}
            </div>
          </Box>
          {supplyOrderEquipment && supplyOrderEquipment?.length > 0 &&
            expandedStatesForSupply &&
            supplyOrderEquipment?.map((item) => {
              return (
                <>
                  <CardContent className={styles.cardContainer}>
                    <div className={styles.dataMargin}>
                      <Grid container className={styles.gridClass}>
                        <Grid md={2} xs={12} className={`${styles.paddingTop20px} ${styles.equipmentImage}`}>
                          {item.image == "" ? (
                            <InventoryIcon className={styles.supplyImage} />
                          ) : ( <img
                            src={item.image}
                            className={styles.supplyImage}
                          />)}
                        </Grid>
                        <Grid md={7} xs={12} className={styles.paddingTop20px}>
                          <div className={styles.tags}>
                            <h3 className={styles.equipmentName}>
                              {item.name}
                            </h3>
                            {item.top_pick != 0 && item.top_pick && <div>
                              <Chip
                                label="Top pick"
                                size="small"
                                className={`${styles.myCustomChip} ${styles.topPick}`}
                              />
                            </div>}
                          </div>
                          {item.quantity && <div>
                            <span className={styles.content}>
                              Quantity:
                            </span>
                            <span className={styles.supplyValue}>
                              {item.quantity}
                            </span>
                          </div>}
                          {item.item_number && <div>
                            <span className={styles.content}>
                              Item number:
                            </span>
                            <span className={styles.supplyValue}>
                              {item.item_number}
                            </span>
                          </div>}
                          {item.unit_of_measurement && <div>
                            <span className={styles.content}>
                              Unit of Measurement:
                            </span>
                            <span className={styles.supplyValue}>
                              {item.unit_of_measurement}
                            </span>
                          </div>}
                          {item.price && <div>
                            <span className={styles.content}>
                              Price:
                            </span>
                            <span className={styles.supplyValue}>
                              ${item.price}
                            </span>
                          </div>}
                          {item.subtotal && <div>
                            <span className={styles.content}>
                              Subtotal:
                            </span>
                            <span className={styles.supplyValue}>
                              ${item.subtotal}
                            </span>
                          </div>}
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                </>
              );
            }
            )
          }
        </Card>) :
        (<div className={styles.loaderMargin} ><NoData message={isLoadingSupplyEquipment ? <img alt='Loading...' src={loader} /> : showMessageForSupplyEquipment} /></div>)
      }
    </div>
  );
};

export default SupplyEquipment;
