import { Box, Container } from '@mui/material';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Breadcrumb from "../Breadcrumb/MuiBreadcrumb";
import { HTTPMethod, callApi as mixOrderDataRequest } from "../../services/HttpService/HttpService";
import { OrdersComponentApi } from '../../constants/ApiConstants';
import ProofOrderDetailCards from '../ProofOrderDetailCards/ProofOrderDetailCards';
import { useTranslation } from "react-i18next";
import { getOrderTitle } from '../PatientsOrder/PatientsOrder';
import NoData from "../../components/noData/noData";
import loader from "../../assets/loader.gif";

const MixOrderDetail = () => {
    const [mixOrderData, setMixOrderData] = useState({});
    const [orderDetailsData, setOrdersDetailsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();
    const location = useLocation();

    const orderID = location?.state?.data?.order_id;
    const breadcrumbItems = [
        { label: "Orders", link: `/orders` },
        { label: orderID, link: `/orders/order-details/${orderID}` },
        { label: getOrderTitle(location?.state?.data?.mix_order_configuration, t, false)},
    ];

    const handleOrdersDetails = async () => {
        setOrdersDetailsData(null);
        setIsLoading(true);
        const response = await mixOrderDataRequest(
            HTTPMethod.Get,
            OrdersComponentApi.orderDetails + "/" + orderID
        );
        if (response && response.status_code == 200 && response.data) {
            setOrdersDetailsData(response.data);
            proofOfOrderDetails();
        }
    };

    const proofOfOrderDetails = async () => {
        const response = await mixOrderDataRequest(HTTPMethod.Get, OrdersComponentApi.orderProofOfDelivery.replace("{order_id}", orderID));
        if (response && response.status_code == 200 && response.data) {
             setMixOrderData(response.data);
        } 
        setIsLoading(false);
    };

    useEffect(() => {
        handleOrdersDetails();
    }, []);

    return (
        <Container className="mainContainer" >
            <Breadcrumb items={breadcrumbItems} />
            <Box style={{ marginTop: "20px" }}>
                {(Object.keys(mixOrderData).length > 0 && Object.values(mixOrderData).some(value => value !== null)) ?
                    (Object.keys(mixOrderData).map((key) => {
                        if (mixOrderData[key]) {
                            return (
                                <ProofOrderDetailCards
                                    key={key}
                                    proofData={mixOrderData[key]}
                                    orderDetailsData={orderDetailsData}
                                />
                            );
                        }
                        return null;
                    })) :
                    <NoData message={isLoading ? <img alt='Loading...' src={loader} /> : t("OrdersTab.noMixedOrderMessage")} />
                }
            </Box>
        </Container>
    )
}

export default MixOrderDetail;