import React, { useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

const CustomTablePagination = ({ totalRecord, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, isLoading = false}) => {
  
  const CustomActions = ({ count, page, rowsPerPage, onPageChange }) => {
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1), true);
    };

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2.5 }} data-testid='CustomTablePagination'>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </Box>
    );
  };

  return (
    <TablePagination data-testid="CustomPagination" sx={{
      "& .MuiTablePagination-toolbar": {
        "@media (max-width: 522px)": {
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          '& .MuiBox-root': { marginLeft: '-12px' }
        }
      }
    }}
      component="div"
      labelRowsPerPage={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isLoading && (
            <CircularProgress size={22} style={{ marginRight: '10px' }} />
          )}
          Records per page
        </div>
      }
      count={totalRecord}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[10, 25, 50, 100]}
      onRowsPerPageChange={handleChangeRowsPerPage}
      SelectProps={{
        style: {
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          backgroundColor: "white",
          borderRadius: "1px",
          border: "1px solid rgba(0, 0, 0, 0.12)",
        },
      }}
      ActionsComponent={CustomActions}
    />
  );
};

export const CustomPagination = (initialPage = 0, initialRowsPerPage = 25) => {
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [offset, setOffset] = useState(1);

  const handleChangePage = (_event, newPage, isLastPage = false) => {
    setPage(newPage);
    let offsetNext = offset;
    if (newPage === 0) offsetNext = 1;
    else if (isLastPage) offsetNext = newPage + 1;
    else if (newPage > page) offsetNext = offsetNext + 1;
    else offsetNext = offsetNext - 1;
    setOffset(offsetNext);
  };

  const handleChangeRowsPerPage = (event) => {
    setOffset(1);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
   
  return {
    page,
    rowsPerPage,
    offset,
    setPage,
    setOffset,
    setRowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  };
}

export default CustomTablePagination;

