import React, { useState } from "react";
import styles from "./edit-prescription.module.scss";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker,LocalizationProvider } from "@mui/x-date-pickers";
import { HTTPMethod, callApi as PrescriptionDetailsRequest } from "../../../services/HttpService/HttpService";
import { Button, DialogContent, FormControl, FormLabel, Grid, InputLabel, MenuItem, Select, Typography, } from "@mui/material";
import { Box } from "@mui/system";
import { PrescriptionApi } from "../../../constants/ApiConstants";
import dayjs from "dayjs";
import { successToast } from "../../../components/ToastMessage/ToastMessage";
import { useTranslation } from "react-i18next";
import { DocumentType } from "../../../common/enums";

const EditPrescription = ({ isEditDocumentOpen, handleClose, currentListData,setIsEdited,addPrescriptionSettings }) => {
  const { t } = useTranslation();
  let fileName = currentListData?.display_name;
  let dotIndex = fileName?.lastIndexOf('.');
  let fileNameVal = fileName?.slice(0, dotIndex);
  let fileExtension = fileName?.slice(dotIndex + 1);
  const [fileFirstName, setFileFirstName] = useState(fileNameVal)
  const [prescriptionFileType, setPrescriptionFileType] = useState(currentListData?.is_o2_prescription ? DocumentType.oxygenPrescription: DocumentType.otherFile);
  const [fileExpirationDate, setFileExpirationDate] = useState(dayjs(currentListData?.expiration_date))
  const [fileNameError, setFileNameError] = useState(false)
  const [fileNameSpecialChar,setFileNameSpecialChar] = useState(false)
  const [fileNameMaxLength,setFileNameMaxLength] = useState(false)
  const [expirationDateError,setExpirationDateError] = useState(false)
  const [datePickerErrors, setDatePickerErrors] = useState({});
  const [saveButtonDisabled,setSaveButtonDisabled] = useState(false)
  const minDate = currentListData?.is_o2_prescription == DocumentType.oxygenPrescription ? dayjs(currentListData?.expiration_date) : null
  const handleExpirationDate = (val) => {
     setFileExpirationDate(val)
     setExpirationDateError(false)
     setSaveButtonDisabled(true)
  };

  const handleFileNameChange = (e) => {
    const value = e.target.value;
    setFileFirstName(value)
    setSaveButtonDisabled(true)
  };

  const handleFileType = (event) => {
    if (event.target.value == DocumentType.oxygenPrescription) {
      setFileExpirationDate(null);
    }
    if (event.target.value) {
      setPrescriptionFileType(event.target.value);
      setSaveButtonDisabled(true);
    }
  };

  const onBlurHandleFileName = (event) => {
    const fileName = event.target.value.trim();
    const isValidFileName = /^[a-zA-Z0-9-_ ]+$/.test(fileName);
  
    if (fileName.length === 0) {
      setFileNameError(true);
    }
    else if(!isValidFileName){
      setFileNameSpecialChar(true)
    }
    else if(fileName.length > 200){
      setFileNameMaxLength(true)
    }
    else {
      setFileNameError(false);
      setFileNameSpecialChar(false);
      setFileNameMaxLength(false);
    }
  }


  const handlePrescriptionUpdate = async () => {
    const updatedFileObj = { fileFirstName };
    let hasInvalidDateEditPrescription = false;
    const errors = {};

    if(fileNameError || fileNameSpecialChar || fileNameMaxLength){
      return false
    }
    setFileFirstName(updatedFileObj.fileFirstName);
    
    setDatePickerErrors(errors);
    if (hasInvalidDateEditPrescription) {
      return;
    }

    if (prescriptionFileType === 1 &&
      (!fileExpirationDate ||
        fileExpirationDate.toLocaleString() === "Invalid Date" ||
        fileExpirationDate?.$y < 1900 ||
        fileExpirationDate?.$y >= 2100
        )
    ) {
      setExpirationDateError(true)
      return 
    }
    const formatDateEditPrescription = (date) => {
      const padEditPrescription = (num) => (num < 10 ? `0${num}` : num);
      const formattedDateEditPrescription = new Date(date);
      const yearEditPrescription =  formattedDateEditPrescription.getFullYear();
      const monthEditPrescription =  padEditPrescription(formattedDateEditPrescription.getMonth() + 1);
      const dayEditPrescription =  padEditPrescription(formattedDateEditPrescription.getDate());
      const hoursEditPrescription =  padEditPrescription(formattedDateEditPrescription.getHours());
      const minutesEditPrescription =  padEditPrescription(formattedDateEditPrescription.getMinutes());
      const secondsEditPrescription =  padEditPrescription(formattedDateEditPrescription.getSeconds());
      return `${yearEditPrescription}-${monthEditPrescription}-${dayEditPrescription}T${hoursEditPrescription}:${minutesEditPrescription}:${secondsEditPrescription}.000Z`;
    };
    const editPrescriptionRequestBody = {
      display_name: fileFirstName + "." + fileExtension,
      file_upload_id: `${currentListData.file_id}`,
      is_o2_prescription: prescriptionFileType === DocumentType.oxygenPrescription,
      expiration_date: prescriptionFileType === DocumentType.oxygenPrescription ? formatDateEditPrescription(fileExpirationDate) : null
    }
    const prescriptionEditResponse = await PrescriptionDetailsRequest(HTTPMethod.Post, PrescriptionApi.editFile, editPrescriptionRequestBody);
    if (prescriptionEditResponse && prescriptionEditResponse.status_code == 200) {
      setIsEdited(true)
      handleClose()
      successToast(t("Prescriptions.documentUpdatedSuccessfully"));
    }
  }
  return (
    <div className={styles.CommonAlert} data-testid="CommonAlert">
      <Dialog
        open={isEditDocumentOpen}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
      >
        <DialogTitle className={styles.commonDialogTitle}>
          <span className={styles.commonContainer}>
            {t('Prescriptions.editDocument')}
            <IconButton
              className={styles.handleCancel}
              aria-label="close"
              onClick={handleClose}
              data-testid="closeAddSupportPin"
            >
              <CloseIcon />
            </IconButton>
          </span>
        </DialogTitle>
        <DialogContent>
          <div className={`${styles.customMarginBottom} ${styles.customMarginTop}`}>
            <TextField
              data-testid="file-name-input"
              fullWidth
              id="displayName"
              label="File Name (required)"
              name="displayName"
              variant="outlined"
              size="small"
              onChange={handleFileNameChange}
              error={fileNameError || fileNameSpecialChar}
              onBlur={onBlurHandleFileName}
              onFocus={() => {
                setFileNameSpecialChar(false)
                setFileNameError(false)
              }}
              value={fileFirstName}
              InputProps={{
                inputProps: {
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                },
              }}
            />
            {fileNameError && (
              <FormLabel error >
                {t('AddPatient.fieldRequired')}
              </FormLabel>
            )}
            {fileNameSpecialChar && (
              <FormLabel error>
                {t('Prescriptions.invalidFileName')}
              </FormLabel>
            )}
            {fileNameMaxLength && (
              <FormLabel error>
                {t('Prescriptions.fileNameMaxLength')}
              </FormLabel>
            )}
            <Typography
            component="div"
              sx={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "2px",
              }}
            >
              <Box sx={{ minWidth: 120, marginBottom: "20px" }}>
                <FormControl fullWidth data-testid='form-control'>
                  <InputLabel id="demo-simple-select-label" >
                  {t("Prescriptions.fileTypeRequired")}
                  </InputLabel>
                  <Select
                    labelId="file_type"
                    id="file_type"
                    value={prescriptionFileType ?? (currentListData?.is_o2_prescription ? "" : DocumentType.otherFile)}
                    disabled={!currentListData?.is_o2_prescription}
                    label="File Type (Required)"
                    onChange={handleFileType}
                    sx={{ height: "3rem" }}
                    data-testid="select"
                    IconComponent={KeyboardArrowDownRoundedIcon}
                  >
                    {addPrescriptionSettings && 
                    <MenuItem value={1}>
                      {t("Prescriptions.oxygenPrescription")}
                    </MenuItem> }
                    <MenuItem value={2}>{t("Prescriptions.otherFile")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Typography>
          </div>
          {prescriptionFileType === DocumentType.oxygenPrescription &&
            <div>
              <FormControl fullWidth size="small" sx={{
                borderRadius: "8px",
              }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                  <DatePicker
                    sx={{
                      '& .Mui-disabled::placeholder': {
                        '-webkit-text-fill-color': 'var(--color-grey) !important',
                        'opacity': '1 !important'
                      }
                    }}
                    minDate ={minDate}
                    label="Expiration (required)"
                    value={fileExpirationDate}
                    className={styles.expirationLabel}
                    slotProps={{
                      textField: {
                        size: "small",
                        error: expirationDateError,
                      },
                    }}
                    onChange={(val) => { handleExpirationDate(val); }}
                  >
                    {datePickerErrors[fileFirstName] && (
                      <div style={{ color: 'red', marginTop: '8px', fontSize: '0.75rem' }}>
                        {datePickerErrors[fileFirstName]}
                      </div>
                    )}
                  </DatePicker>
                </LocalizationProvider>
                {expirationDateError && <FormLabel error>
                  {t('Prescriptions.validDate')}
                </FormLabel>}
              </FormControl>
            </div>
          }
          <div>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              justifyContent="flex-end"
              alignItems="center"
              className={styles.customButton}
            >
              <Button
                type="reset"
                variant="contained"
                className={styles.customButtonStyleReset}
                size="medium"
                onClick={handleClose}
                sx={{
                  width: { xs: "50px", md: "100px" },
                  height: { xs: "30px", md: "38px" },
                }}
              >
                {t("Prescriptions.cancel")}
              </Button>
              <Button size="medium" variant="contained" onClick={handlePrescriptionUpdate}
              disabled={!saveButtonDisabled}
                sx={{
                  width: { xs: "50px", md: "100px" },
                  height: { xs: "30px", md: "38px" },
                }}
                className={saveButtonDisabled?styles.customButtonStyleSearch : styles.DisabledButton }
              >
                {t("Prescriptions.save")}
              </Button>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditPrescription;
