import React from "react";
import { Typography, Button, FormGroup, Checkbox, FormControl, FormLabel, FormControlLabel,Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle } from "@mui/material";
import styles from './FormAuth.module.css';

 
const Group2AuthorizationForm = ({handleClose, preAuthFormData}) => {

  function formatHeight(inches) {
    inches = inches.toString();
    if (inches.length == 2) {
        return `${inches[0]}'${inches[1]}"`
    }
    if (inches.length == 1) {
        return `${inches[0]}'` ;
    }
}
    
    return (
        <>
      {preAuthFormData  && (
           <Dialog 
        fullWidth
        maxWidth="md"
        open={true} 
        onClose={handleClose} 
        >
        <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}> Group 2 Support Surface Pre-Authorization Documentation  </DialogTitle>
        
        
        <DialogContent>
          <Typography sx={{margin: '10px 0px'}}> <span className={styles.asterisk}>*</span> denotes required field</Typography>
           {preAuthFormData[0].BranchCode !== '' && preAuthFormData[0]?.BranchCode !== undefined  && <Grid container>
            <Grid xs={12} md={6}  sx={{marginTop:'0px !important'}} className={styles.spacingTop}>
            <FormControl className={styles.spacingRemoveTop}>  
              <div>
                <Typography className={styles.labelText}> Branch/State (3 letter code) <span className={styles.asterisk}>*</span> </Typography>
              </div>
              <div >
              <TextField
                  margin="normal"
                  type="text"
                  autoFocus
                  id="name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  maxLength="3"
                  disabled={true}
                  value={preAuthFormData[0].BranchCode}
                  sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                  inputProps={{ style: { cursor: 'not-allowed' } }}
              />
              </div>
              </FormControl>
            </Grid>
          </Grid>}

{preAuthFormData[0]?.IsStage2 !== undefined && <Grid container>
  <Grid xs={12} className={styles.spacingTop}>
  <FormControl className={styles.spacingRemoveTop}>  
<FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 1. Patient has multiple stage II pressure areas located on the trunk or pelvis that have not improved over the past month. During this time, the patient has been on a comprehensive ulcer treatment program that included:<span className={styles.asterisk}>*</span></FormLabel>
      <div style={{paddingLeft:'10px'}}>
        <ul>
            <li>a. Properly using a Group 1 support surface, <i>and</i></li>
            <li>b. Regular assessment by a nurse, physician, or other licensed health care practitioner, <i>and</i></li>
            <li>c. Proper turning and positioning, <i>and</i></li>
            <li>d. Proper wound care, <i>and</i></li>
            <li>e. Proper management of moisture and or incontinence, <i>and</i></li>
            <li>f. Nutritional assessment and intervention consistent with the overall plan of care</li>
        </ul>
      </div>
      <RadioGroup
        value={preAuthFormData[0].IsStage2}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }}/>
        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }}/>
        
      </RadioGroup>
    </FormControl>
  </Grid>
</Grid>}

{preAuthFormData[0]?.IsLargerUlcer !== undefined && preAuthFormData[0]?.IsLargerUlcer !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}>
  <FormControl className={styles.spacingRemoveTop}>  
<FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 2. Does the patient have a large(i.e., &#8805; 8 square centimeters [length x width]) ulcer or multiple stage III or IV pressure ulcers on the trunk or pelvis? <span className={styles.asterisk}>*</span></FormLabel>
      <RadioGroup
      value={preAuthFormData[0]?.IsLargerUlcer}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
        
      </RadioGroup>
    </FormControl>
  </Grid>
</Grid>}


{preAuthFormData[0]?.LargerUlcerDetails && preAuthFormData[0].LargerUlcerDetails !== '' && <Grid container sx={{paddingLeft:'10px'}}>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
         a. If yes, please provide details:
    </Typography>
  </div>
  <TextField
            fullWidth
            aria-label="minimum height"
            multiline
            rows={3}
            maxLength={4000}
            size="small"
            value={preAuthFormData[0].LargerUlcerDetails}
            disabled={true}
            sx={{marginTop:'5px',
            resize: 'verical',
            overflow: 'auto', 
            maxHeight: '300px',
            backgroundColor: "var(--color-disableField)"
            }}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}



{preAuthFormData[0]?.IsPressureUlcer !== undefined && preAuthFormData[0]?.IsPressureUlcer !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}>
  <FormControl className={styles.spacingRemoveTop}>  
<FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 3. Has the patient had recent myocutaneous flap or skin graft for a pressure ulcer on the trunk or pelvis (surgery within the past 60 days), and has been on a group 2 or 3 support surface immediately prior to a recent discharge from a hospital or nursing facility (discharged within the past 30 days)? <span className={styles.asterisk}>*</span></FormLabel>
      <RadioGroup
      value={preAuthFormData[0].IsPressureUlcer}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
        
      </RadioGroup>
    </FormControl>
  </Grid>
</Grid>}



{preAuthFormData[0]?.BariatricBed !== undefined && preAuthFormData[0]?.BariatricBed !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}>
  <FormControl className={styles.spacingRemoveTop}>  
<FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 4. Is a bariatric bed needed? <span className={styles.asterisk}>*</span></FormLabel>
      <RadioGroup
      value={preAuthFormData[0].BariatricBed}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
        
      </RadioGroup>
    </FormControl>
  </Grid>
</Grid>}


{preAuthFormData[0]?.PatientHeight !== undefined && preAuthFormData[0].PatientHeight !== '' && <Grid container sx={{paddingLeft:'10px'}}>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
         a. If yes, list the patient's height:
    </Typography>
  </div>
  <TextField
            fullWidth
            aria-label="minimum height"
            minRows={2}
            maxLength={50}
            size="small"
            value={formatHeight(preAuthFormData[0].PatientHeight)}
            sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
            disabled={true}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}

{preAuthFormData[0]?.PatientWeight !== undefined && preAuthFormData[0].PatientWeight !== '' && <Grid container sx={{paddingLeft:'10px'}}>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
       b. Patient's weight:
    </Typography>
  </div>
  <TextField
            fullWidth
            aria-label="minimum height"
            minRows={2}
            maxLength={50}
            size="small"
            value={`${preAuthFormData[0].PatientWeight} lbs`}
            sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
            disabled={true}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}



{preAuthFormData[0]?.IsRequestedBySNFALF !== undefined && <Grid container>
  <Grid xs={12}>
  <FormControl className={styles.spacingRemoveTop}>  
  <FormGroup>
      <FormControlLabel control={<Checkbox checked={preAuthFormData[0].IsRequestedBySNFALF} disabled={true} />} label="Requested by SNF/ALF as their facility standard" />
    </FormGroup>
    </FormControl>
  </Grid>
</Grid>}


{preAuthFormData[0]?.RequestingFacilityName !== undefined && preAuthFormData[0].RequestingFacilityName !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
            Name of requesting facility
    </Typography>
  </div>
  <TextField
            fullWidth
            aria-label="minimum height"
            minRows={2}
            maxLength={200}
            size="small"
            value={preAuthFormData[0].RequestingFacilityName}
            sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
            disabled={true}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}


{preAuthFormData[0]?.ClinicianName !== undefined && preAuthFormData[0].ClinicianName !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
            Clinician Name <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
            aria-label="cinician name field"
            maxLength={200}
            minRows={2}
            disabled={true}
            fullWidth
            size="small"
            sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
            value={preAuthFormData[0].ClinicianName}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}


{preAuthFormData[0]?.ApprovingManager !== undefined && preAuthFormData[0].ApprovingManager !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
            ED/Admin <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
            minRows={2}
            fullWidth
            maxLength={200}
            aria-label="ED admin field"
            value={preAuthFormData[0].ApprovingManager}
            disabled={true}
            sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
            size="small"
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}

{preAuthFormData[0]?.AVPO !== undefined && preAuthFormData[0].AVPO !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
            AVPO <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
            fullWidth
            maxLength={200}
            aria-label="Avpo field"
            disabled={true}
            minRows={2}
            size="small"
            value={preAuthFormData[0].AVPO}
            sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}



  </DialogContent>

<Grid container sx={{justifyContent:'end'}}>
  <Grid>
  <DialogActions
              style={{ justifyContent: "end", padding: "14px 16px" }}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{textTransform:'none'}}
                className={styles.customNoButton}
              >
                Close
              </Button>
            </DialogActions>
  </Grid>
</Grid>
        
      </Dialog>
      )}
     
    </>
    )
}

Group2AuthorizationForm.formName = "Group2AuthorizationForm";
export default Group2AuthorizationForm;