import React from 'react';
import { Navigate } from 'react-router-dom';

const Redirect = () => {
  const defaultPage = sessionStorage.getItem('redirectPath') ?? "/dashboard";
  return(
    <Navigate to={defaultPage}/>
  );
}

export default Redirect;
