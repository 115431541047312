import React from 'react';
import { createRoot } from 'react-dom/client';
import './IconLibrary.js';
import App from './App';

import './global.css';
import './assets/custom-css/_helper.scss';
import './assets/custom-css/_forms.scss';
import { Provider } from 'react-redux';
import Store from './redux/store/store.js';
import './components/i18n/i18n.js';
import { Auth0Provider } from "@auth0/auth0-react";
import SentryInit from './common/Utils/Sentry/Sentry.js';
import "@reactour/popover/dist/index.css";
import WalkThrough from './common/WalkThrough/WalkThrough.js';

const container = document.getElementById('root') || document.createElement('div');
const root = createRoot(container);
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const callbackUrl = window.location.origin;
const providerConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  audience: audience,
  authorizationParams: {
    redirect_uri: callbackUrl,
    ...(audience ? { audience: audience } : null),
  },
};
SentryInit();
root.render(
  <React.StrictMode>

    <Auth0Provider
      {...providerConfig}
    >
      <Provider store={Store}>
       < WalkThrough >
          <App />
        </WalkThrough>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);