import React from "react";
import { Typography, Button, FormGroup, Checkbox, FormControl, FormLabel, FormControlLabel,Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle } from "@mui/material";
import styles from './FormAuth.module.css';


const LiquidO2AuthorizationForm = ({handleClose, preAuthFormData}) => {
    return (
        <div>
          <>
        {preAuthFormData  && (
             
             <Dialog 
             fullWidth
             maxWidth="md"
             open={true} 
             onClose={handleClose} 
             >
             <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}> Liquid Oxygen System Pre-Authorization Documentation  </DialogTitle>
             
             
             <DialogContent>
             <Typography sx={{margin: '10px 0px'}}><span className={styles.asterisk}>*</span> denotes required field</Typography>
                {preAuthFormData[0]?.BranchCode !== undefined && preAuthFormData[0].BranchCode !== '' && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> Branch/State (3 letter code) <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       margin="normal"
                       fullWidth
                       size="small"
                       autoFocus
                       variant="outlined"
                       id="name"
                       maxLength="3"
                       type="text"
                       value={preAuthFormData[0].BranchCode}
                       disabled={true}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   </div>
                   </FormControl>
                 </Grid>
               </Grid>}
     
     {preAuthFormData[0]?.IsCurrentLiquidO2System !== undefined && <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">1. Is the patient currently on a liquid O2 system?<span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
           value={preAuthFormData[0].IsCurrentLiquidO2System}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }}/>
             <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
     </Grid>}
     
     {preAuthFormData[0]?.IsRefill !== undefined && <Grid container sx={{paddingLeft:'10px'}}>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">a. If yes, is this a refill? <span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
           value={preAuthFormData[0].IsRefill}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={true}/>
             <FormControlLabel value="No" control={<Radio />} label="No" disabled={true}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
     </Grid>}
     
     
     {preAuthFormData[0]?.ClinicalRationale !== undefined && preAuthFormData[0].ClinicalRationale !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}> 
       <div>
         <Typography className={styles.labelText}>
             b. If no, please provide the clinical rationale for using a liquid O2 system:
         </Typography>
       </div>
       <TextField
                 fullWidth
                 aria-label="minimum height"
                 multiline
                 rows={3}
                 maxLength={4000}
                 size="small"
                 value={preAuthFormData[0].ClinicalRationale}
                 disabled={true}
                 sx={{marginTop:'5px',
                 resize: 'verical',
                 overflow: 'auto', 
                 maxHeight: '300px',
                 backgroundColor: "var(--color-disableField)"
                 }}
                 inputProps={{ style: { cursor: 'not-allowed' } }}
               />
       </Grid>
     </Grid>}
     
     
     
     {preAuthFormData[0]?.IsHighO2 !== undefined && <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">2. Does the patient have high flow O2 needs greater than 8 L/min? <span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
           value={preAuthFormData[0].IsHighO2}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
             <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
     </Grid>}
     
     
     
     {preAuthFormData[0]?.IsFullAmbulatory !== undefined && <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 3. Is the patient fully ambulatory? <span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
           value={preAuthFormData[0].IsFullAmbulatory}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
             <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
     </Grid>}

     {preAuthFormData[0]?.IsFullAmbulatory && <Grid>
       <Grid xs={12} md={6} className={styles.spacingTop}> 
       <div>
         <Typography className={styles.labelText}>
            a. PPS
         </Typography>
       </div>
       <TextField
                 fullWidth
                 aria-label="minimum height"
                 multiline
                 rows={3}
                 maxLength={4000}
                 size="small"
                 value={preAuthFormData[0].Pps}
                 disabled={true}
                 sx={{marginTop:'5px',
                 resize: 'verical',
                 overflow: 'auto', 
                 maxHeight: '300px',
                 backgroundColor: "var(--color-disableField)"
                 }}
                 inputProps={{ style: { cursor: 'not-allowed' } }}
               />
       </Grid>

       <Grid xs={12} md={6} className={styles.spacingTop}> 
       <div>
         <Typography className={styles.labelText}>
         b. NYHA Class <span className={styles.asterisk}>*</span>
         </Typography>
       </div>
       <TextField
                 fullWidth
                 aria-label="minimum height"
                 multiline
                 rows={3}
                 maxLength={4000}
                 size="small"
                 value={preAuthFormData[0].Nyha}
                 disabled={true}
                 sx={{marginTop:'5px',
                 resize: 'verical',
                 overflow: 'auto', 
                 maxHeight: '300px',
                 backgroundColor: "var(--color-disableField)"
                 }}
                 inputProps={{ style: { cursor: 'not-allowed' } }}
               />
       </Grid>
    
      </Grid>
     }
     
     
     {preAuthFormData[0]?.CircumstancesLiquidO2 !== undefined && preAuthFormData[0].CircumstancesLiquidO2 !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}> 
       <div>
         <Typography className={styles.labelText}>
        4. Please describe other extenuating circumstances that require the patient to use liquid O2: <span className={styles.asterisk}>*</span>
         </Typography>
       </div>
       <TextField
                 fullWidth
                 multiline
                 rows={3}
                 maxLength={500}
                 size="small"
                 value={preAuthFormData[0].CircumstancesLiquidO2}
                 disabled={true}
                 aria-label="minimum height"
                 inputProps={{ style: { cursor: 'not-allowed' } }}
                 sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
               />
       </Grid>
     </Grid>}
     
    { preAuthFormData[0]?.IsRequestedBySNFALF !== '' && preAuthFormData[0]?.IsRequestedBySNFALF !== undefined && <Grid container>
       <Grid xs={12}>
       <FormControl className={styles.spacingRemoveTop}>  
       <FormGroup>
           <FormControlLabel control={<Checkbox disabled={true} checked={preAuthFormData[0].IsRequestedBySNFALF}  />} label="Requested by SNF/ALF as their facility standard" />
         </FormGroup>
         </FormControl>
       </Grid>
     </Grid>}
     
     
     {preAuthFormData[0]?.RequestingFacility !== undefined && preAuthFormData[0].RequestingFacility !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}> 
       <div>
         <Typography className={styles.labelText}>
                 Name of requesting facility
         </Typography>
       </div>
       <TextField
                 maxLength={200}
                 disabled={true}
                 minRows={2}
                 aria-label="requesting custom text"
                 size="small"
                 fullWidth
                 value={preAuthFormData[0].RequestingFacility}
                 inputProps={{ style: { cursor: 'not-allowed' } }}
                 sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
               />
       </Grid>
     </Grid>}
     
     
    {preAuthFormData[0]?.ClinicianName !== undefined && preAuthFormData[0].ClinicianName !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}> 
       <div>
         <Typography className={styles.labelText}>
                 Clinician Name <span className={styles.asterisk}>*</span>
         </Typography>
       </div>
       <TextField
                 aria-label="clinic text field"
                 value={preAuthFormData[0].ClinicianName}
                 fullWidth
                 disabled={true}
                 minRows={2}
                 maxLength={200}
                 size="small"
                 sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
                 inputProps={{ style: { cursor: 'not-allowed' } }}
               />
       </Grid>
     </Grid>}
     
     
     {preAuthFormData[0].ApprovingManager !== '' && preAuthFormData[0]?.ApprovingManager !== undefined && <Grid container>
       <Grid xs={12} className={styles.spacingTop}> 
       <div>
         <Typography className={styles.labelText}>
                 ED/Admin <span className={styles.asterisk}>*</span>
         </Typography>
       </div>
       <TextField
                 disabled={true}
                 fullWidth
                 maxLength={200}
                 aria-label="Admin field"
                 minRows={2}
                 value={preAuthFormData[0].ApprovingManager}
                 size="small"
                 sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
                 inputProps={{ style: { cursor: 'not-allowed' } }}
               />
       </Grid>
     </Grid>}
     
     {preAuthFormData[0]?.AVPO !== undefined && preAuthFormData[0].AVPO !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}> 
       <div>
         <Typography className={styles.labelText}>
                 AVPO <span className={styles.asterisk}>*</span>
         </Typography>
       </div>
       <TextField
       fullWidth
                 aria-label="avpo field"
                 disabled={true}
                 minRows={2}
                 value={preAuthFormData[0].AVPO}
                 size="small"
                 maxLength={200}
                 inputProps={{ style: { cursor: 'not-allowed' } }}
                 sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
               />
       </Grid>
     </Grid>}
     
           
      </DialogContent>
     
     <Grid container sx={{justifyContent:'end'}}>
       <Grid>
       <DialogActions
                   style={{ padding: "14px 16px", justifyContent: "end"  }}
                 >
                   <Button
                     onClick={handleClose}
                     variant="contained"
                     className={styles.customNoButton}
                     sx={{textTransform:'none'}}
                   >
                     Close
                   </Button>
                 </DialogActions>
       </Grid>
     </Grid>
             
           </Dialog>
        )}
       
      </>
        </div>
    )
}

LiquidO2AuthorizationForm.formName = "LiquidO2AuthorizationForm";
export default LiquidO2AuthorizationForm;