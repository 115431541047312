import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate, useLocation } from 'react-router-dom';
import style from './MuiBreadcrumb.module.scss';

const renderBreadcrumbItem = (item, index, items, location, style) => {
  if (index === 0) {
    if (location?.pathname === item.link) {
      return <span className={style.breadcrumbLabel}>{item.label}</span>;
    } else {
      return (
        <Link
          color="var(--color-base)" 
          underline={item.disabled ? 'none' : 'hover'}
          style={{
            fontWeight: '700',
            cursor: item.disabled ? 'default' : 'pointer',
            textDecoration: item.disabled ? 'none' : 'underline',
          }}
        >
          {item.label}
        </Link>
      );
    }
  } else if (index === items.length - 1) {
    return <span className={style.breadcrumbColor}>{item.label}</span>;
  } else {
    return (
      <Link
        style={{
          fontWeight: '700',
          color: 'var(--color-base)',
        }}
        className={style.breadcrumbColorUnderLine}
      >
        {item.label}
      </Link>
    );
  }
};

const BasicBreadcrumbs = ({ items }) => {
  const breadcrumbNavigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (link) => breadcrumbNavigate(link);

  return (
    <div className={style.breadcrumPosition} >
    <Breadcrumbs separator=" > " aria-label="breadcrumb">
      {items?.map((item, index) => (
        <span
          data-testid='breadcrumbLink'
          key={item.label}
          onClick={() => {
            if (item.link && !item.disabled) {
              handleNavigate(item.link);
            }
          }}
        >
         {renderBreadcrumbItem(item, index, items, location, style)}
        </span>
      ))}
    </Breadcrumbs>
    </div>
  );
};

export default BasicBreadcrumbs;
