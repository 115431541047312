import { useEffect, useState } from 'react';
import { useTour } from '@reactour/tour';
import { useSelector } from "react-redux";
import { LineOfBusinessEnum } from '../../../constants/BusinessConstants';


export default function PageTour({ prop, isRelaunch = false }) {
  const { setIsOpen, setSteps, setCurrentStep } = useTour();

  const isProfileUpdateInfoAcknowledged = useSelector(
    (state) => state.authState.userData?.data?.user_info?.is_profile_update_info_acknowledged
  );
  const walkThroughDetail = useSelector(
    (state) => state.drawerSubNav.walkThroughDetail?.page_names
  );

  const companySettings = useSelector(state => state.authState.userData?.data?.company_settings);
  const isO2prescriptionModule = companySettings?.find(val => val.module_id == 91)

  const storedTour = JSON.parse(localStorage.getItem('toursShown') || '{}');

  const [isTourShown, setIsTourShown] = useState({
    PatientsTour: false,
    OrdersTour: false,
    PatientDetailsTabTour: false,
    OrderDetailsTabTour: false,
    NewOrderTour: false,
    NewOrderAddEquipTour: false,
    ...storedTour,
  });

  const updatedTourState = { ...isTourShown };
  useEffect(() => {
    if (Array.isArray(walkThroughDetail)) {

      walkThroughDetail.forEach((detail) => {
        const matchingKey = `${detail}Tour`;
        if (updatedTourState.hasOwnProperty(matchingKey)) {
          updatedTourState[matchingKey] = true;
        }
      });

      setIsTourShown(updatedTourState);
      localStorage.setItem('toursShown', JSON.stringify(updatedTourState));
    }
  }, []);

  useEffect(() => {
    const tourKey = `${prop}Tour`;
    const isTourAlreadyShown = updatedTourState[tourKey];

    if (!isTourAlreadyShown || isRelaunch) {

      const steps = getTourSteps(prop, isO2prescriptionModule);
      setSteps(steps);
      setCurrentStep(0);

      if (!isProfileUpdateInfoAcknowledged || isRelaunch) {
        setIsOpen(true);

        setIsTourShown((prevState) => {
          const updatedTourState = { ...prevState, [tourKey]: true };
          localStorage.setItem('toursShown', JSON.stringify(updatedTourState));
          return updatedTourState;
        });
      }
    }
  }, [isRelaunch, setIsOpen, prop, setCurrentStep, isProfileUpdateInfoAcknowledged]);

  return null;
}


export const tourModalstyles = {
  popover: (base, state) => {
    return {
      ...base,
      borderRadius: 10,
      boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0.1em 0.5em',
      top: '-8px',
      padding: '24px 30px 15px 30px',
      '--reactour-accent': 'var(--color-orange)',
      borderRadius: '2px',
      ...doArrow(state.position, state.verticalAlign, state.horizontalAlign),
    };
  },
  controls: (base) => ({ ...base, marginTop: 20, backgroundColor: 'var(--color-orange)', padding: '0px', paddingTop: '2px', paddingBottom: '2px', marginLeft: '-31px', marginRight: '-31px', marginBottom: '-31px' }),  // dots and navigations
  close: (base) => ({ ...base, right: 'auto', right: 16, top: 14 }), // close button
  maskWrapper: (base) => ({ ...base, color: '#1f1e1e', opacity: '0.1' }),   // for overlay shadow
  badge: (base) => ({ ...base, left: 'auto', right: '-0.8125em', display: 'none' }),
};

const oppositeSide = {
  top: "bottom",
  bottom: "top",
  right: "left",
  left: "right",
};


export function doArrow(position, verticalAlign, horizontalAlign) {
  if (!position || position === "custom") {
    return {};
  }

  const width = 16;
  const height = 12;
  const color = "var(--bg-white)";
  const isVertical = position === "top" || position === "bottom";
  const spaceFromSide = 32;
  const topHeight = 28;

  const obj = {
    [`--rtp-arrow-${isVertical ? oppositeSide[horizontalAlign] : verticalAlign
      }`]: height + spaceFromSide + "px",
    [`--rtp-arrow-${oppositeSide[position]}`]: position !== 'top' ? -height + "px" : -topHeight + "px",
    [`--rtp-arrow-border-${isVertical ? "left" : "top"}`]: `${width / 2
      }px solid transparent`,
    [`--rtp-arrow-border-${isVertical ? "right" : "bottom"}`]: `${width / 2
      }px solid transparent`,
    [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`,
  };
  return obj;
}

export const getTourSteps = (page, isO2prescriptionModule=false) => {
  switch (page) {
    case 'Patients':
      return [
        {
          selector: '.toast-container',
          content: `View and select filters to refine the ${LineOfBusinessEnum.patientToMember.toLowerCase()} list.`,
        },
        {
          selector: '.routing-container',
          content: `Switch between viewing ${LineOfBusinessEnum.patientToMember.toLowerCase()} information in a list or table.`,
        },
        {
          selector: '.columnClass',
          content: `Update column visibility to show or hide ${LineOfBusinessEnum.patientToMember.toLowerCase()} details.`,
        },
        {
          selector: '.other-component-container',
          content: `Create a new ${LineOfBusinessEnum.patientToMember.toLowerCase()} without placing an order.`,
        }
      ];
    case 'Orders':
      return [
        {
          selector: '.toast-container',
          content: 'View and select filters to refine the order list.',
        },
        {
          selector: '.routing-container',
          content: 'Switch between viewing order information in a list or table.',
        },
        {
          selector: '.columnClass',
          content: 'Update column visibility to show or hide order details.',
        }
      ];
    case 'OrderDetailsTab': case 'PatientDetailsTab':
      return [
        {
          selector: '.Documents',
          content: isO2prescriptionModule ? 'View and upload files and prescriptions.' : 'View and upload files.'
        }
      ]
    case 'NewOrder':
      return [
        {
          selector: '.equipmentField',
          content: 'Type to display all equipment matching the text or select a specific equipment item.'
        },
        {
          selector: '.diagnosisField',
          content: 'You can also search diagnosis codes this way.'
        }
      ]
    default:
      return [
        {
          selector: '.generic-info',
          content: 'This is a generic tour.'
        },
      ];
  }
}