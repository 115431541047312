import React from 'react';

const NoData = ({message}) => (
  <div data-testid="NoData">
    <div
      style={{
        backgroundColor: "#F5f5f5",
        padding: "10px 0px",
        color: "#000",
        fontSize: "16px",
        fontWeight: "700",
        textAlign: "center"
      }}>
      {message}
    </div>
  </div>
);

export default NoData;
