import { useSelector } from "react-redux";
import { commonConstants } from "../../constants";
import { configurationCompanySetting, statusEnum } from "../../enums";
import { useTranslation } from "react-i18next";

export const etaTime = (text="") => {
    return text?.substring(text.indexOf('(') + 1, text.indexOf(')')).trim()
}

export const removeSecondsFromTime = (time = "") => {
  return time ? etaTime(time)?.substring(0, 5).concat(etaTime(time)?.substring(8)) : "";
}

export const gridEtaTime =(params) => {
  const { t } = useTranslation();
  const companySettings = useSelector(state => state.authState.userData?.data?.company_settings);
  const enRouteEtaSetting = companySettings?.some(setting => setting.module_name === configurationCompanySetting.EnRouteEtaVisible);

  const statusItem = params.row;
  const manualEta = params.value;
  const etaWindow = params?.row?.eta_window_excepted_time;
  const isDelayed = params?.row?.is_eta_delayed;
  const arrivalFormattedTime = removeSecondsFromTime(statusItem?.arrival_time_formatted);
  const arrivalTime = statusItem?.arrival_time;

  let etaText = (manualEta && manualEta != commonConstants?.defaultDateTime && statusItem.order_status === statusEnum["On my way"]) ? etaTime(manualEta) : "";

  if (enRouteEtaSetting && (statusItem.order_status === statusEnum["En-Route"] || statusItem.order_status === statusEnum["Picking Up"]) && etaWindow) {
    etaText = etaWindow;
  }
  else if (statusItem.order_status === statusEnum["On my way"] && isDelayed) {
    etaText = `${t("Dashboard.delayed")}`;
  }
  else if (arrivalTime) {
    etaText = `${t("OrdersTab.driverArrived")} : ${arrivalFormattedTime}`;
  }

  return (
    <span>
      {etaText ? etaText : "-"}
    </span>
  );
};
