import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSubMenuOpen: {
    support: false,
    reports: false,
  },
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    toggleSubMenus(state, action) {
      const { title } = action.payload;
      for (let key in state.isSubMenuOpen) {
        state.isSubMenuOpen[key] = key === title ? !state.isSubMenuOpen?.[key] : false;
      }
    },
    closeAllSubMenus(state) {
      for (let key in state.isSubMenuOpen) {
        state.isSubMenuOpen[key] = false;
      }
    },
  },
});

export const { toggleSubMenus, closeAllSubMenus } = drawerSlice.actions;
export default drawerSlice.reducer;
