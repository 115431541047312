import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { walkthrough } from '../../../constants/ApiConstants';
import {HTTPMethod, callApi as walkThroughSave } from '../../../services/HttpService/HttpService';
import { checkUrlPath } from '../../Utils/CommonFunctions/CommonFunctions';
import { useDispatch } from 'react-redux';
import { setRelaunchTooltip, setShowTour } from '../../../redux/RelaunchSlice/RelaunchSlice';
import { toggleSubMenus } from '../../../redux/SubMenuToggleSlice/SubMenuToggleSlice';
 

export const walkThroughSavePage = (isPlatform = false) => {
  const requestBody = {
    "page_name": isPlatform ? 'Platform' : checkUrlPath()
  }

    walkThroughSave(
    HTTPMethod.Post,
    walkthrough.saveWalkThrough, requestBody
  );
}

export const prevButton = ({ currentStep, setCurrentStep, steps }) => {
  const first = currentStep === 0
  return (
    <button
      style={{ border: 'none', backgroundColor: 'var(--color-orange)', color: 'var(--bg-white)', padding: '7px 14px', borderRadius: '4px', fontSize: 'var(--common-content)', fontWeight: 'var(--fontWeight600)', marginLeft: '16px' }}
    >
      Step {currentStep + 1 + " of " + steps.length}
    </button>
  )
}


export const nextButton=({
  currentStep,
  stepsLength,
  setIsOpen,
  setCurrentStep,
  steps,
}) => {
  const last = currentStep === stepsLength - 1
  const dispatch = useDispatch();
  const button = document.querySelector('.MuiButton-root.MuiButton-Primary');
  return (
    <button
      style={{ border: 'none', backgroundColor: 'var(--color-dimmergray)', color: 'var(--bg-white)', padding: '5px 14px', borderRadius: '4px', cursor: 'pointer', fontSize: 'var(--common-content)', marginRight: '20px' }}
      onClick={() => {
        if (last) {
          setIsOpen(false)
          walkThroughSavePage()
          dispatch(setShowTour({showTour: false}))
          dispatch(toggleSubMenus({ title: 'support' }));
          dispatch(setRelaunchTooltip({openRelaunchTooltip: true}))
          if (location.pathname === '/orders' && button) {
            button.click();
          }
        } else {
          if (currentStep === 1 || currentStep === 2) {
            if (button) {
              button.click();
            } 
          }

          setTimeout(() => {
            setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1))
          }, 20);
        }
      }}
    >
      {last ? 'Finish' : <><span style={{ verticalAlign: 'top' }}>Next </span> &nbsp; &nbsp;<ArrowForwardIosRoundedIcon style={{ fontSize: 16, verticalAlign: 'sub' }} /></>}
    </button>
  )
}
