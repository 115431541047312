import { OrdersComponentApi } from '../../constants/ApiConstants';
import { HTTPMethod, callApi as OrderDetailRequest } from '../../services/HttpService/HttpService';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { Button } from "@mui/base";
import EditIcon from "@mui/icons-material/Edit";
import styles from './editOrderButton.module.scss';
import { errorToast } from '../ToastMessage/ToastMessage';

const EditOrderButton = ({data, index}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const editOrder = async (item) => {
    if ( item?.status_description == "Incomplete" || item?.status_description == "Queued" ) {
      errorToast(t("toastMessage.editOrderErrorMsg"));
    }
    else {
      const response = await OrderDetailRequest(
        HTTPMethod.Get,
        OrdersComponentApi.orderDetails + "/" + item.order_id
      );
  
      if (response && response.status_code == 200 && response.data) {
        const patientDetails = response?.data?.patient_info?.patient_id;
        navigate(`/orders/edit-order/${item.order_id}`, {
          state: {
            newRecurringOrder: true,
            patientRecurring: true,
            branchObject: {
              id: response?.data?.patient_info?.client_id,
              branch_name:
                response?.data?.hospice_branch_contact?.hospice_branch_name,
            },
            patient_full_name: `${response.data?.patient_info?.patient_last_name}, ${response.data?.patient_info?.patient_first_name} ${response.data?.patient_info?.patient_middle_initial}`,
            patient_id: patientDetails,
            provider_id: response.data?.provider_contacts?.provider_id,
            equipment_details: response.data?.order_equipment,
            order_type: response.data?.orders?.order_type,
            is_delivery_order: response.data?.orders?.is_delivery_order,
            is_service_order: response.data?.orders?.is_service_order,
            order_reason: response.data?.order_reason,
            orders: response.data?.orders,
            reason: response.data?.reason
          },
        });
      }
    }
  };

  return (
    <>
      <Tooltip title={ <span className={styles.tooltipFontSize} > Edit Order </span> } >
        <Button className={styles.blueButton} data-testid={"EditOrder" + index} onClick={(e) => {
          e.preventDefault();
          editOrder(data);
        }}
          variant="contained" size="small">
          <EditIcon className={styles.customSizeImage} />
        </Button>
      </Tooltip>
    </>
  )
}

export default EditOrderButton;