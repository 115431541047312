import React from 'react';
import styles from './CommonNotes.module.scss';
import { Card, Box, styled, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PatientNoteTextField = styled(TextField)({
  borderRadius: '2px !important'
});

const CommonNotes = ({ noteContent, setNoteContent}) => {
  const { t } = useTranslation();
  const handleNoteChange = (event) => {
    setNoteContent(event.target.value);
  };
  return (
    <div className={styles.CommonNotes} data-testid="CommonNotes">
      <Card className={styles.boxShadow}>
        <Box className={styles.notesStyles}>
          {t('PatientNote.note')}
        </Box>
        <PatientNoteTextField
          multiline
          inputProps={{ maxLength: 500 }}
          minRows={5}
          label={t('PatientNote.typeNote')}
          value={noteContent}
          onChange={handleNoteChange}
          className={styles.MarginTop}
        />
      </Card>
    </div>
  )
};

export default CommonNotes;
