import React, { useEffect, useState } from "react";
import styles from './OrdersEquipment.module.scss';
import { HTTPMethod, callApi as patientRequest} from "../../../services/HttpService/HttpService";
import { LocationStateApi, OrdersComponentApi } from "../../../../src/constants/ApiConstants";
import NoData from "../../../components/noData/noData";
import loader from "../../../assets/loader.gif";
import Button from "@mui/material/Button";
import { Box, Card, CardContent, Typography, Grid , Tooltip} from "@mui/material";
import Chip from "@mui/material/Chip";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import InventoryIcon from '@mui/icons-material/Inventory';
import CommonAlert from "../../../components/common-alert/common-alert";
import { successToast } from "../../../components/ToastMessage/ToastMessage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import StatusColor from "../../../common/Utils/StatusColor/StatusColor";
import GenericEquipmentTags from "../../../common/Utils/GenericEquipmentTags/GenericEquipmentTags";
import { commonConstants } from "../../../common/constants";

export const GenericEquipmentItemDetails = ({ item, orderStatus, t }) => {

  return (
    <div>
      {item.orders && (
        <Typography>
          <span className={styles.content}>{t("EquipmentsTab.order")} </span>
          <span>
            <a href="#" className={styles.customLink}>{item.orders}</a>
          </span>
        </Typography>
      )}
      {item.quantity && (
        <Typography>
          <span className={styles.content}>Quantity:</span>
          <span className={`${styles.value} ${styles.marginLeft8px}`}>
            {item.quantity}
          </span>
        </Typography>
      )}
      {item.provider && (
        <Typography>
          <span className={styles.content}>{t("EquipmentsTab.provider")}</span>
          <span className={`${styles.value} ${styles.marginLeft8px}`} key={item.equipment_id}>
            {item.provider}
          </span>
        </Typography>
      )}
      {item.asset_tag && (
        <Typography>
          <span className={styles.content}>{t("EquipmentsTab.asset")}</span>
          <span className={`${styles.value} ${styles.marginLeft8px}`}>
            {item.asset_tag}
          </span>
        </Typography>
      )}
      {item.serial_number && (
        <Typography>
          <span className={styles.content}>{t("EquipmentsTab.serial")}</span>
          <span className={`${styles.value} ${styles.marginLeft8px}`}>
            {item.serial_number}
          </span>
        </Typography>
      )}
      {item.lot_number && (
        <Typography>
          <span className={styles.content}>{t("EquipmentsTab.lot")}</span>
          <span className={`${styles.value} ${styles.marginLeft8px}`}>
            {item.lot_number}
          </span>
        </Typography>
      )}
      {item.approved_on !== commonConstants.defaultDateTime && item.approved_on && (
        <Typography>
          <span className={styles.content}>{t("EquipmentsTab.approvedOn")}</span>
          <span className={`${styles.value} ${styles.marginLeft8px}`}>
            {item.approved_on}
          </span>
        </Typography>
      )}
      {item.approved_by && (
        <Typography>
          <span className={styles.content}>{t("EquipmentsTab.approvedBy")}</span>
          <span className={`${styles.value} ${styles.marginLeft8px}`}>
            {item.approved_by}
          </span>
        </Typography>
      )}
      {item.rejected_on !== commonConstants.defaultDateTime && item.rejected_on && orderStatus === "Rejected" && (
        <Typography>
          <span className={styles.content}>Rejected on:</span>
          <span className={`${styles.value} ${styles.marginLeft8px}`}>
            {item.rejected_on}
          </span>
        </Typography>
      )}
      {item.rejected_by && orderStatus === "Rejected" && (
        <Typography>
          <span className={styles.content}>Rejected by:</span>
          <span className={`${styles.value} ${styles.marginLeft8px}`}>
            {item.rejected_by}
          </span>
        </Typography>
      )}
      {item.delivered_on !==  commonConstants.defaultDateTime && item.delivered_on && orderStatus === "Delivered" && (
        <Typography>
          <span className={styles.content}>{t("EquipmentsTab.deliveredOn")}</span>
          <span className={`${styles.value} ${styles.marginLeft8px}`}>
            {item.delivered_on}
          </span>
        </Typography>
      )}
      {item.deliver_by && item.deliver_by !== commonConstants.defaultDateTime && orderStatus !== "Delivered" && (
        <Typography>
          <span className={styles.content}>Deliver by:</span>
          <span className={`${styles.value} ${styles.marginLeft8px}`}>
            {item.deliver_by}
          </span>
        </Typography>
      )}
      {item.pickup_by !== commonConstants.defaultDateTime && item.pickup_by && orderStatus === "Picked Up" && (
        <Typography>
          <span className={styles.content}>Pick up by:</span>
          <span className={`${styles.value} ${styles.marginLeft8px}`}>
            {item.pickup_by}
          </span>
        </Typography>
      )}
      {item.service_by !== commonConstants.defaultDateTime && item.service_by && (
        <Typography>
          <span className={styles.content}>Serviced by:</span>
          <span className={`${styles.value} ${styles.marginLeft8px}`}>
            {item.service_by}
          </span>
        </Typography>
      )}
      {item.reference_order_id !== 0 && (
        <Typography>
          <span className={styles.content}>Reference order:</span>
          <a href={`/orders/order-details/${item.reference_order_id}`} className={styles.customLink}>
            {item.reference_order_id}
          </a>
        </Typography>
      )}
    </div>
  );
};
const OrdersEquipment = () => {
  const [equipmentDataForOrders, setEquipmentDataForOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [serviceOrderEquipment, setServiceOrderEquipment] = useState([])
  const [pickupOrderEquipment, setPickupOrderEquipment] = useState([])
  const [isLoadingOrdersEquipment, setLoadingOrdersEquipment] = useState(true);
  const [isNoteForOrderEquipment, setOrderEquipment] = useState(false);
  const [cancelDetailsId, setCancelDetailsId] = useState(null)
  const [settingForBranch,setSettingForBranch] = useState(null)  
  const { t } = useTranslation();
  const location = useLocation();
  const [alertEquipemntName, setAlertEquipemntName]  = useState(null); 
  let showMessageForEquipment = `No equipment on this order.`;
  const [expandedStatesForService, setExpandedStatesForService] = useState(true);
  const [expandedStatesForDelivery, setExpandedStatesForDelivery] = useState(true);
  const [expandedStatesForPickup, setExpandedStatesForPickup] = useState(true);

  const fetchData = async () => {
    setLoadingOrdersEquipment(true);
    const requestBody = location.pathname.split('/').pop();
    const apiEndpoint = OrdersComponentApi.ordersEquipment.replace(
      "{order_id}",
      requestBody
    );
      const response = await patientRequest(HTTPMethod.Get, apiEndpoint);
      if (response && response.status_code == 200 && response.data) {
        setOrderStatus(response.data.orders.order_status);
        setEquipmentDataForOrders(response.data.order_equipment);
        setServiceOrderEquipment(response.data.service_order_equipment);
        setPickupOrderEquipment(response.data.pickup_equipments);
        handleBranchSettingsOrderEquipment(response.data);
      } 
      setLoadingOrdersEquipment(false);
  };
  useEffect(() => {
    fetchData()
  }, []);

  const handleCancelEquipment = async () => {
    const cancelEquipmentPayload = {
      "order_detail_id": cancelDetailsId?.split(","),
      "order_id": location.pathname.split('/').pop()
    }
    
    const response = await patientRequest(
      HTTPMethod.Post,
      OrdersComponentApi.ordersCancelEquipment,
      cancelEquipmentPayload
    );

    if (response && response.status_code == 200) {
      const message = response?.return_message[0];
      successToast(message);
      setOrderEquipment(false);
      fetchData();
    }
  };

  const toggleExpandedService = () => {
    setExpandedStatesForService(!expandedStatesForService);
  };
  const toggleExpandedDelivery = () => {
    setExpandedStatesForDelivery(!expandedStatesForDelivery);
  };

  const toggleExpandedPickup = () => {
    setExpandedStatesForPickup(!expandedStatesForPickup);
  };

const handleBranchSettingsOrderEquipment = async (equipmentData) => {
    const response = await patientRequest(HTTPMethod.Get, `${LocationStateApi.orderSettingsForBranch}?branch_id=${equipmentData?.patient_info?.client_id}`);
    if (response && response.status_code == 200 && response.data) {
        setSettingForBranch(response.data.setting_list);
    }
}
  return (
    <>
      <div className={styles.PatientsEquipment} data-testid="PatientsEquipment">
        {(equipmentDataForOrders && equipmentDataForOrders?.length > 0) || (serviceOrderEquipment && serviceOrderEquipment?.length > 0) || (pickupOrderEquipment && pickupOrderEquipment?.length > 0) ? (
        <div>
           {equipmentDataForOrders && equipmentDataForOrders?.length > 0 &&
             <Card variant="outlined" className={styles.customBox2}>
                <Box className={styles.scopedClassName}>
                  <div className={styles.scopedClassHeading} data-testid = "DMEItems">
                    <span>Delivery</span>
                    {expandedStatesForDelivery && (
                      <ExpandLessIcon
                      data-testid="expandLessIcon"
                        onClick={() => {
                          toggleExpandedDelivery();
                        }}
                        style={{ cursor: "pointer", marginLeft: "auto", color: "black", marginRight: "5px" }}
                      />
                    )}
                    {!expandedStatesForDelivery && (
                      <ExpandMoreIcon
                      data-testid="expandMoreIcon"
                        onClick={() => {
                          toggleExpandedDelivery();
                        }}
                        style={{ cursor: "pointer", marginLeft: "auto", color: "black", marginRight: "5px" }}
                      />
                    )}
                  </div>
                </Box>
              {equipmentDataForOrders && equipmentDataForOrders?.length > 0 ? (
                expandedStatesForDelivery &&
                equipmentDataForOrders?.map((item) => {
                  return (
                    <>
                      <CardContent className={styles.cardContainer}>
                        <div key={item.equipment_id} className={styles.dataMargin}>
                          <Grid container className={styles.gridClass}>
                            <Grid md={3} xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  padding: "0px",
                                }}
                              >
                                <Chip
                                  label={item?.equipment_complete_status}
                                  sx={{
                                    padding: "0px 40px",
                                    borderRadius: "0px 0px 0px 6px !important",
                                    fontSize: "var(--badges-font-size) !important",
                                    fontWeight: "800 !important",
                                    boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.4) !important",
                                    color: "white",
                                    flex: 1,
                                    maxWidth: "200px",
                                    backgroundColor: StatusColor(item?.equipment_complete_status),  
                                  }}
                                ></Chip>
                              </div>
                              {!(item.can_be_cancel === false || (item.can_be_cancel === true && item?.equipment_complete_status === "Canceled")) &&
                                <Tooltip
                                  title={
                                    <span style={{ fontSize: "13px" }}>
                                      Cancel Equipment
                                    </span>
                                  }
                                >
                                  <Box className={styles.customButtonContainer1}>
                                    <Button className={styles.redButton} onClick={() => {
                                      setOrderEquipment(true);
                                      setCancelDetailsId(item.order_detail_id || item.order_detail_ids);
                                      setAlertEquipemntName(item.equipment_name)
                                    }}
                                      variant="contained" size="small">
                                      <ClearIcon className={styles.customSizeImage} data-testid="CancelEquipment"/>
                                    </Button>
                                  </Box>
                                </Tooltip>
                              }
                            </Grid>
                            <Grid md={2} xs={12} className={`${styles.paddingTop20px} ${styles.equipmentImage}`}>
                              {item.equipment_image == "" ? (
                                <InventoryIcon style={{
                                  width: "100%"
                                }} />
                              ) : (<img
                                src={item.equipment_image}
                                alt="Order Image"
                                style={{
                                  width: "100%",
                                }}
                              />)}
                            </Grid>

                            <Grid md={7} xs={12} className={styles.paddingTop20px}>
                            <div className={styles.marginLeft20px}>
                              <div className={styles.tags}>
                                <h3 className={styles.equipmentName}>
                                  {item.equipment_name}
                                </h3>
                                <GenericEquipmentTags item={item?.equipment_tags} settingForBranch={settingForBranch} />
                              </div>
                              <GenericEquipmentItemDetails item={item} orderStatus={item?.equipment_complete_status} t={t} />
                            </div>
                            </Grid>
                            <Grid md={4} xs={12} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  padding: "0px",

                                }}
                              >
                                <Chip

                                  label={item?.equipment_complete_status}
                                  sx={{
                                    padding: "0px 30px",
                                    borderRadius: "0px 0px 0px 6px !important",
                                    fontSize: "var(--badges-font-size) !important",
                                    fontWeight: "800 !important",
                                    boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.4) !important",
                                    color: "white",
                                    flex: 1,
                                    maxWidth: "200px",
                                    backgroundColor: StatusColor(item?.equipment_complete_status),    
                                  }}
                                ></Chip>
                              </div>
                              <Box className={styles.customButtonContainer1}>
                                {!(item.can_be_cancel === false || (item.can_be_cancel === true && item?.equipment_complete_status === "Canceled")) &&
                                  <Tooltip
                                    title={
                                      <span style={{ fontSize: "13px" }}>
                                        Cancel Equipment
                                      </span>
                                    }
                                  >
                                    <Button variant="contained" size="small" className={styles.redButton} onClick={() => {
                                      setOrderEquipment(true);
                                      setCancelDetailsId(item.order_detail_id || item.order_detail_ids);
                                      setAlertEquipemntName(item.equipment_name)
                                    }}>
                                      <ClearIcon className={styles.customSizeImage} />
                                    </Button>
                                  </Tooltip>
                                }
                              </Box>
                            </Grid>
                          </Grid>
                        </div>
                      </CardContent>
                    </>
                  );
                }
                )) : ''}
          </Card>
          }
          {serviceOrderEquipment && serviceOrderEquipment?.length > 0 &&
            <Card variant="outlined" className={styles.customBox2}>     
                  <Box className={styles.scopedClassName}>
                    <div className={styles.scopedClassHeading} data-testid= "ServiceOrderEquipment">
                      <span>Service</span>
                      {expandedStatesForService && (
                        <ExpandLessIcon
                          data-testid= "expandLessIcon"
                          onClick={() => {
                            toggleExpandedService();
                          }}
                          style={{ cursor: "pointer", marginLeft: "auto", color: "black", marginRight: "5px" }}
                        />
                      )}
                      {!expandedStatesForService && (
                        <ExpandMoreIcon
                          data-testid="expandMoreIcon"
                          onClick={() => {
                            toggleExpandedService();
                          }}
                          style={{ cursor: "pointer", marginLeft: "auto", color: "black", marginRight: "5px" }}
                        />
                      )}
                    </div>
                  </Box>
                  
                  {serviceOrderEquipment && serviceOrderEquipment?.length > 0 ? (
                    expandedStatesForService &&
                    serviceOrderEquipment?.map((item) => {
                      return (
                        <>
                          <CardContent className={styles.cardContainer} variant="outlined">
                            <div key={item.equipment_id} className={styles.dataMargin}>
                              <Grid container className={styles.gridClass}>
                                <Grid md={3} xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      padding: "0px",
                                    }}
                                  >
                                    <Chip
                                      label={item?.equipment_complete_status}
                                      sx={{
                                        padding: "0px 40px",
                                        borderRadius: "0px 0px 0px 6px !important",
                                        fontSize: "var(--badges-font-size) !important",
                                        fontWeight: "800 !important",
                                        boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.4) !important",
                                        color: "white",
                                        flex: 1,
                                        maxWidth: "200px",
                                        backgroundColor: StatusColor(item?.equipment_complete_status),   
                                      }}
                                    ></Chip>
                                  </div>
                                  { orderStatus === "Received" && !(item.can_be_cancel === false || (item.can_be_cancel === true && (item?.equipment_complete_status === "Canceled" || item?.equipment_complete_status === "Delivered")))   &&
                                    <Tooltip
                                      title={
                                        <span style={{ fontSize: "13px" }}>
                                          Cancel Equipment
                                        </span>
                                      }
                                    >
                                      <Box className={styles.customButtonContainer1}>
                                        <Button className={styles.redButton} onClick={() => {
                                          setOrderEquipment(true);
                                          setCancelDetailsId(item.order_detail_id || item.order_detail_ids);
                                          setAlertEquipemntName(item.equipment_name)
                                        }}
                                          variant="contained" size="small">
                                          <ClearIcon className={styles.customSizeImage} />
                                        </Button>
                                      </Box>
                                    </Tooltip>
                                  }
                                </Grid>
                                <Grid md={2} xs={12} className={`${styles.paddingTop20px} ${styles.equipmentImage}`}>
                                  {item.equipment_image == "" ? (
                                    <InventoryIcon style={{
                                      width: "100%",
                                    }} />
                                  ) : (<img
                                    src={item.equipment_image}
                                    alt="Order Image"
                                    style={{
                                      width: "100%",
                                    }}
                                  />)}
                                </Grid>

                                <Grid md={7} xs={12} className={`${styles.paddingTop20px} ${styles.paddingLeft20px}`}>
                                  <div className={styles.tags}>
                                    <h3 className={styles.equipmentName}>
                                      {item.equipment_name}
                                    </h3>
                                    <GenericEquipmentTags item={item?.equipment_tags} settingForBranch={settingForBranch} />
                                  </div>
                               <GenericEquipmentItemDetails item={item} orderStatus={item?.equipment_complete_status} t={t} />
                                </Grid>
                                <Grid md={4} xs={12} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      padding: "0px",

                                    }}
                                  >
                                    <Chip

                                      label={item?.equipment_complete_status}
                                      sx={{
                                        padding: "0px 30px",
                                        borderRadius: "0px 0px 0px 6px !important",
                                        fontSize: "var(--badges-font-size) !important",
                                        fontWeight: "800 !important",
                                        boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.4) !important",
                                        color: "white",
                                        flex: 1,
                                        maxWidth: "200px",
                                        backgroundColor: StatusColor(item?.equipment_complete_status),    
                                      }}
                                    ></Chip>
                                  </div>
                                  <Box className={styles.customButtonContainer1}>
                                    {orderStatus === "Received" && !(item.can_be_cancel === false || (item.can_be_cancel === true && (item?.equipment_complete_status === "Canceled" || item?.equipment_complete_status === "Delivered"))) &&
                                      <Tooltip
                                        title={
                                          <span style={{ fontSize: "13px" }}>
                                            Cancel Equipment
                                          </span>
                                        }
                                      >
                                        <Button variant="contained" size="small" className={styles.redButton} onClick={() => {
                                          setOrderEquipment(true);
                                          setCancelDetailsId(item.order_detail_id || item.order_detail_ids);
                                          setAlertEquipemntName(item.equipment_name)
                                        }}>
                                          <ClearIcon className={styles.customSizeImage} />
                                        </Button>
                                      </Tooltip>
                                    }
                                  </Box>
                                </Grid>
                              </Grid>
                            </div>
                          </CardContent>
                        </>
                      );
                    }
                    )) : ''
                  }
            </Card>}
          
          {pickupOrderEquipment && pickupOrderEquipment?.length > 0 &&
            <Card variant="outlined" className={styles.customBox2}>     
                  <Box className={styles.scopedClassName}>
                    <div className={styles.scopedClassHeading} data-testid= "pickupOrderEquipment">
                      <span>Pick up</span>
                      {expandedStatesForPickup && (
                        <ExpandLessIcon
                          data-testid= "expandLessIcon"
                          onClick={() => {
                            toggleExpandedPickup();
                          }}
                          style={{ cursor: "pointer", marginLeft: "auto", color: "black", marginRight: "5px" }}
                        />
                      )}
                      {!expandedStatesForPickup && (
                        <ExpandMoreIcon
                          data-testid="expandMoreIcon"
                          onClick={() => {
                            toggleExpandedPickup();
                          }}
                          style={{ cursor: "pointer", marginLeft: "auto", color: "black", marginRight: "5px" }}
                        />
                      )}
                    </div>
                  </Box>
                  
                  {pickupOrderEquipment?.length > 0 && (
                    expandedStatesForPickup &&
                    pickupOrderEquipment?.map((item) => {
                      return (
                        <>
                          <CardContent className={styles.cardContainer} variant="outlined">
                            <div key={item.equipment_id} className={styles.dataMargin}>
                              <Grid container className={styles.gridClass}>
                                <Grid md={3} xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      padding: "0px",
                                    }}
                                  >
                                    <Chip
                                      label={item?.equipment_complete_status}
                                      sx={{
                                        padding: "0px 40px",
                                        borderRadius: "0px 0px 0px 6px !important",
                                        fontSize: "var(--badges-font-size) !important",
                                        fontWeight: "800 !important",
                                        boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.4) !important",
                                        color: "var(--bg-white)",
                                        flex: 1,
                                        maxWidth: "200px",
                                        backgroundColor: StatusColor(item?.equipment_complete_status),   
                                      }}
                                    ></Chip>
                                  </div>
                                  {!(item.can_be_cancel === false || (item.can_be_cancel === true && item?.equipment_complete_status === "Canceled")) &&
                                    <Tooltip
                                      title={
                                        <span style={{ fontSize: "13px" }}>
                                          Cancel Equipment
                                        </span>
                                      }
                                    >
                                      <Box className={styles.customButtonContainer1}>
                                        <Button className={styles.redButton} onClick={() => {
                                          setOrderEquipment(true);
                                          setCancelDetailsId(item.order_detail_id || item.order_detail_ids);
                                          setAlertEquipemntName(item.equipment_name)
                                        }}
                                          variant="contained" size="small">
                                          <ClearIcon className={styles.customSizeImage} />
                                        </Button>
                                      </Box>
                                    </Tooltip>
                                  }
                                </Grid>
                                <Grid md={2} xs={12} className={`${styles.paddingTop20px} ${styles.equipmentImage}`}>
                                  {item.equipment_image == "" ? (
                                    <InventoryIcon style={{
                                      width: "100%",
                                    }} />
                                  ) : (<img
                                    src={item.equipment_image}
                                    alt="Order Image"
                                    style={{
                                      width: "100%",
                                    }}
                                  />)}
                                </Grid>

                                <Grid md={7} xs={12} className={`${styles.paddingTop20px} ${styles.paddingLeft20px}`}>
                                  <div className={styles.tags}>
                                    <h3 className={styles.equipmentName}>
                                      {item.equipment_name}
                                    </h3>
                                    <GenericEquipmentTags item={item?.equipment_tags} settingForBranch={settingForBranch} />
                                  </div>
                               <GenericEquipmentItemDetails item={item} orderStatus={item?.equipment_complete_status} t={t} />
                                </Grid>
                                <Grid md={4} xs={12} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      padding: "0px",

                                    }}
                                  >
                                    <Chip

                                      label={item?.equipment_complete_status}
                                      sx={{
                                        padding: "0px 30px",
                                        borderRadius: "0px 0px 0px 6px !important",
                                        fontSize: "var(--badges-font-size) !important",
                                        fontWeight: "800 !important",
                                        boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.4) !important",
                                        color: "var(--bg-white)",
                                        flex: 1,
                                        maxWidth: "200px",
                                        backgroundColor: StatusColor(item?.equipment_complete_status),    
                                      }}
                                    ></Chip>
                                  </div>
                                  <Box className={styles.customButtonContainer1}>
                                    {!(item.can_be_cancel === false || (item.can_be_cancel === true && item?.equipment_complete_status === "Canceled")) &&
                                      <Tooltip
                                        title={
                                          <span style={{ fontSize: "13px" }}>
                                            Cancel Equipment
                                          </span>
                                        }
                                      >
                                        <Button variant="contained" size="small" className={styles.redButton} onClick={() => {
                                          setOrderEquipment(true);
                                          setCancelDetailsId(item.order_detail_id || item.order_detail_ids);
                                          setAlertEquipemntName(item.equipment_name)
                                        }}>
                                          <ClearIcon className={styles.customSizeImage} />
                                        </Button>
                                      </Tooltip>
                                    }
                                  </Box>
                                </Grid>
                              </Grid>
                            </div>
                          </CardContent>
                        </>
                      );
                    }
                    ))
                  }
            </Card>
          }

        </div>
        )  :
          (<div style={{ marginTop: '50px' }} ><NoData message={isLoadingOrdersEquipment ? <img alt='Loading...' src={loader} /> : showMessageForEquipment} /></div>)
        }
        <CommonAlert 
          isOpen={isNoteForOrderEquipment} handleCancel={() => { setOrderEquipment(false); }} handleSubmit={handleCancelEquipment}
          contentText={` Are you sure you want to cancel the equipment   ${alertEquipemntName}?`}
          okButtonStyle="red" headerText="Cancel Equipment" cancelText="Go Back" okText="Proceed" />
      </div>
    </>
  );
};


export default OrdersEquipment;
