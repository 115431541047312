import React from "react";
import { Typography, Button, FormGroup, Checkbox, FormControl, FormLabel, FormControlLabel,Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle } from "@mui/material";
import styles from './FormAuth.module.css';


const PortableO2 = ({handleClose, preAuthFormData}) => {
    
    return (
        <>
        {preAuthFormData  && (
             <Dialog 
                fullWidth
                maxWidth="md"
                open={true} 
                onClose={handleClose} 
                >
        <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}> Portable Oxygen Concentrator Pre-Authorization Documentation </DialogTitle>
        
        
        <DialogContent>
        <Typography sx={{margin: '10px 0px'}}><span className={styles.asterisk}>*</span> denotes required field</Typography>
           {preAuthFormData[0]?.BranchCode !== undefined && preAuthFormData[0].BranchCode !== '' && <Grid container>
            <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important'}}>
            <FormControl className={styles.spacingRemoveTop}>  
              <div>
                <Typography className={styles.labelText}> Branch/State (3 letter code) <span className={styles.asterisk}>*</span> </Typography>
              </div>
              <div >
              <TextField
                  fullWidth
                  autoFocus
                  size="small"
                  margin="normal"
                  maxLength="3"
                  id="name"
                  type="text"
                  variant="outlined"
                  value={preAuthFormData[0].BranchCode}
                  sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                  disabled={true}
                  inputProps={{ style: { cursor: 'not-allowed' } }}
              />
              </div>
              </FormControl>
            </Grid>
          </Grid>}

          {preAuthFormData[0]?.Diagnosis !== undefined && preAuthFormData[0]?.Diagnosis !== '' && <Grid container>
            <Grid xs={12} className={styles.spacingTop} sx={{marginTop:'0px !important'}}>
            <FormControl className={styles.spacingRemoveTop}>  
              <div>
                <Typography className={styles.labelText}> 1. What is the diagnosis that requires a portable O2 concentrator? <span className={styles.asterisk}>*</span> </Typography>
              </div>
              <div >
              <TextField
                  fullWidth
                  autoFocus
                  id="name"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  maxLength="3"
                  value={preAuthFormData[0].Diagnosis}
                  sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                  type="text"
                  disabled={true}
                  inputProps={{ style: { cursor: 'not-allowed' } }}
              />
              </div>
              </FormControl>
            </Grid>
          </Grid>}

        {preAuthFormData[0]?.HasPortableO2 !== undefined && preAuthFormData[0].HasPortableO2 !== '' &&  <Grid container>
        <Grid xs={12} className={styles.spacingTop}>
        <FormControl className={styles.spacingRemoveTop}>  
        <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 2. Does the patient currently have a portable O2 concentrator that would be a transfer over to hospice? <span className={styles.asterisk}>*</span></FormLabel>
            <RadioGroup
                value={preAuthFormData[0].HasPortableO2}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
            >
                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }}/>
                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }}/>
                
            </RadioGroup>
            </FormControl>
        </Grid>
        </Grid>
        }

        {preAuthFormData[0]?.Vendor !== undefined && preAuthFormData[0]?.Vendor !== '' && <Grid container sx={{paddingLeft:'10px'}}>
            <Grid xs={12} className={styles.spacingTop} sx={{marginTop:'0px !important'}}>
            <FormControl className={styles.spacingRemoveTop}>  
              <div>
                <Typography className={styles.labelText}>  a. If yes, provide the name and phone number of vendor: </Typography>
              </div>
              <div >
              <TextField
                  fullWidth
                  autoFocus
                  margin="normal"
                  id="name"
                  type="text"
                  variant="outlined"
                  size="small"
                  maxLength="3"
                  value={preAuthFormData[0].Vendor}
                  sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                  disabled={true}
                  inputProps={{ style: { cursor: 'not-allowed' } }}
              />
              </div>
              </FormControl>
            </Grid>
          </Grid>}

        {preAuthFormData[0]?.ClinicalRationale !== undefined && preAuthFormData[0]?.ClinicalRationale !== '' && <Grid container sx={{paddingLeft:'10px'}}>
            <Grid xs={12} className={styles.spacingTop} sx={{marginTop:'0px !important'}}>
            <FormControl className={styles.spacingRemoveTop} sx={{width:'100%'}}>  
              <div>
                <Typography className={styles.labelText}>  b. What is the clinical rationale for medical necessity of a portable O2 concentrator? <span className={styles.asterisk}>*</span></Typography>
              </div>
              <div >
              <TextField
                  fullWidth
                  autoFocus
                  multiline
                  margin="normal"
                  id="name"
                  type="text"
                  variant="outlined"
                  size="small"
                  maxLength="3"
                  value={preAuthFormData[0].ClinicalRationale}
                  sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                  disabled={true}
                  inputProps={{ style: { cursor: 'not-allowed' } }}
              />
              </div>
              </FormControl>
            </Grid>
          </Grid>}


{preAuthFormData[0]?.HasGreater8LM !== undefined && preAuthFormData[0]?.HasGreater8LM !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}>
  <FormControl className={styles.spacingRemoveTop}>  
<FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 3. Does the patient have high flow O2 needs greater than 8 L/min? <span className={styles.asterisk}>*</span></FormLabel>
      <RadioGroup
        value={preAuthFormData[0].HasGreater8LM}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
        
      </RadioGroup>
    </FormControl>
  </Grid>
</Grid>}



{preAuthFormData[0]?.IsFullyAmbulatory !== undefined && preAuthFormData[0]?.IsFullyAmbulatory !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}>
  <FormControl className={styles.spacingRemoveTop}>  
<FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 4. Is the patient fully ambulatory? <span className={styles.asterisk}>*</span></FormLabel>
      <RadioGroup
        value={preAuthFormData[0].IsFullyAmbulatory}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
        
      </RadioGroup>
    </FormControl>
  </Grid>
</Grid>}

{preAuthFormData[0]?.IsFullyAmbulatory && <Grid container sx={{paddingLeft:'10px', justifyContent:'space-evenly'}}>

<Grid xs={12} md={5}> 
<div>
  <Typography className={styles.labelText}>
     a. PPS <span className={styles.asterisk}>*</span>
  </Typography>
</div>
<TextField
          fullWidth
          aria-label="minimum height"
          rows={3}
          maxLength={4000}
          size="small"
          value={preAuthFormData[0].Pps}
          disabled={true}
          sx={{marginTop:'5px',
          resize: 'verical',
          overflow: 'auto', 
          maxHeight: '300px',
          backgroundColor: "var(--color-disableField)"
          }}
          inputProps={{ style: { cursor: 'not-allowed' } }}
        />
</Grid>

<Grid xs={12} md={5}> 
<div>
  <Typography className={styles.labelText}>
  b. NYHA Class <span className={styles.asterisk}>*</span>
  </Typography>
</div>
<TextField
          fullWidth
          aria-label="minimum height"
          rows={3}
          maxLength={4000}
          size="small"
          value={preAuthFormData[0].Nyha}
          disabled={true}
          sx={{marginTop:'5px',
          resize: 'verical',
          overflow: 'auto', 
          maxHeight: '300px',
          backgroundColor: "var(--color-disableField)"
          }}
          inputProps={{ style: { cursor: 'not-allowed' } }}
        />
</Grid>

</Grid>
}


{preAuthFormData[0]?.HasDyspnea !== undefined && preAuthFormData[0]?.HasDyspnea !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}>
  <FormControl className={styles.spacingRemoveTop}>  
<FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 5. Does the patient have disabling dyspnea? <span className={styles.asterisk}>*</span></FormLabel>
      <RadioGroup
        value={preAuthFormData[0].HasDyspnea}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
        
      </RadioGroup>
    </FormControl>
  </Grid>
</Grid>}

{ preAuthFormData[0]?.OtherCircumstancesDesc !== undefined && preAuthFormData[0].OtherCircumstancesDesc !== '' && <Grid container>
        <Grid xs={12} className={styles.spacingTop}> 
        <div>
            <Typography className={styles.labelText}>
           6. Please describe other extenuating circumstances (medical necessity) that require the patient to use a portable oxygen concentrator instead of small M6 tanks: <span className={styles.asterisk}>*</span>
            </Typography>
        </div>
        <TextField
                    fullWidth
                    aria-label="minimum height"
                    multiline
                    rows={3}
                    maxLength={500}
                    size="small"
                    value={preAuthFormData[0].OtherCircumstancesDesc}
                    disabled={true}
                    sx={{marginTop:'5px',
                    resize: 'verical',
                    overflow: 'auto', 
                    maxHeight: '300px',
                    backgroundColor: "var(--color-disableField)"
                    }}
                    inputProps={{ style: { cursor: 'not-allowed' } }}
                />
        </Grid>
        </Grid>}


        {preAuthFormData[0]?.EstimatedUse !== undefined && preAuthFormData[0]?.EstimatedUse !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}>
  <FormControl className={styles.spacingRemoveTop}>  
<FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 7. Estimated length of use: <span className={styles.asterisk}>*</span></FormLabel>
      <RadioGroup
        value={preAuthFormData[0].EstimatedUse}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="1" control={<Radio />} label="PRN" disabled={true}/>
        <FormControlLabel value="2" control={<Radio />} label="1x use" disabled={true}/>
        <FormControlLabel value="3" control={<Radio />} label="short term" disabled={true}/>
        <FormControlLabel value="4" control={<Radio />} label="other (describe below)" disabled={true}/>
        
      </RadioGroup>

      {preAuthFormData[0]?.OtherDesc !== undefined && preAuthFormData[0]?.OtherDesc !== '' && <TextField
            fullWidth
            aria-label="minimum height"
            minRows={2}
            maxLength={200}
            size="small"
            sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
            value={preAuthFormData[0].OtherDesc}
            disabled={true}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />}
    </FormControl>
  </Grid>
</Grid>}

{ preAuthFormData[0]?.IsRequestedBySNFALF !== undefined && <Grid container>
  <Grid xs={12}>
  <FormControl className={styles.spacingRemoveTop}>  
  <FormGroup>
      <FormControlLabel control={<Checkbox disabled={true} checked={preAuthFormData[0].isRequestedBySNFALF} />} label="Requested by SNF/ALF as their facility standard" />
    </FormGroup>
    </FormControl>
  </Grid>
</Grid>}


{preAuthFormData[0]?.RequestingFacility !== undefined && preAuthFormData[0].RequestingFacility !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
            Name of requesting facility
    </Typography>
  </div>
  <TextField
            fullWidth
            aria-label="minimum height"
            minRows={2}
            maxLength={200}
            size="small"
            sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
            value={preAuthFormData[0].RequestingFacility}
            disabled={true}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}


{preAuthFormData[0]?.ClinicianName !== undefined && preAuthFormData[0].ClinicianName !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
            Clinician Name <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
            fullWidth
            aria-label="minimum height"
            minRows={2}
            maxLength={200}
            size="small"
            value={preAuthFormData[0].ClinicianName}
            sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
            disabled={true}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}


{preAuthFormData[0]?.ApprovingManager !== undefined && preAuthFormData[0].ApprovingManager !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
            ED/Admin <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
            fullWidth
            aria-label="minimum height"
            minRows={2}
            maxLength={200}
            size="small"
            value={preAuthFormData[0].ApprovingManager}
            sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
            disabled={true}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}

{preAuthFormData[0]?.AVPO !== undefined && preAuthFormData[0].AVPO !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
            AVPO <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
  fullWidth
            aria-label="minimum height"
            minRows={2}
            maxLength={200}
            size="small"
            value={preAuthFormData[0].AVPO}
            sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
            disabled={true}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}



      
        </DialogContent>

<Grid container sx={{justifyContent:'end'}}>
  <Grid>
  <DialogActions
              style={{ justifyContent: "end", padding: "14px 16px" }}
            >
              <Button
                className={styles.customNoButton}
                sx={{textTransform:'none'}}
                onClick={handleClose}
                variant="contained"
              >
                Close
              </Button>
            </DialogActions>
  </Grid>
</Grid>
        
      </Dialog>
        )}
       
      </>
    )
}

PortableO2.formName = "PortableO2";
export default PortableO2;