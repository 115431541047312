import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import styles from './common-alert.module.scss';

const CommonAlert = ({ isOpen, handleCancel, handleSubmit, headerText, contentText,secondaryContentText,cancelText,okText, okButtonStyle, showCancelButton = true, dangerAlert=""}) => (
  <div className={styles.CommonAlert} data-testid="CommonAlert">
    <Dialog open={isOpen} onClose={handleCancel}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px",
          },
        },
      }}
    >
      <DialogTitle className={styles.commonDialogTitle}>
        {dangerAlert === "" && <span className={styles.commonFexContainer}>{headerText}</span>}
        {dangerAlert !== "" && <div className={styles.iconContainer}><span><WarningRoundedIcon sx={{ color: "var(--color-red)", marginLeft: "5px", marginTop: "7px" }} /></span><span>&nbsp;{headerText}</span></div>}
      </DialogTitle>
      {dangerAlert === "" && <DialogContent style={{ padding: '15px 25px' }}>
        <p className={styles.dialogDescription}>
          <span>{contentText}</span>
          {secondaryContentText && <span> {secondaryContentText}</span>}
        </p>
      </DialogContent>}
      {dangerAlert !== "" && <DialogContent style={{ padding: '15px 25px' }}>
        <p className={styles.dialogDescription}>
          <span>This delivery is being placed after hours.</span>
            <span style={{color: "var(--color-red)"}}> To ensure timely delivery</span><span>, you need to contact your DME provider.</span>
          {secondaryContentText && <span> {secondaryContentText}</span>}
        </p>
      </DialogContent>}
      <DialogActions style={{ justifyContent: "space-around", padding: '14px 16px' }}>
        {showCancelButton && <Button
          onClick={handleCancel}
          className={styles.customCancelButton}
          variant="contained" size="small">
          {cancelText}
        </Button>}
        <Button
        sx={okButtonStyle=="red"? {backgroundColor: "var(--color-red) !important"} : {}}
          onClick={handleSubmit}
          data-testid="confirmButton"
          className={styles.customYesButton}
          variant="contained" size="small">
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  </div>
  );


export default CommonAlert;
