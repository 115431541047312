import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,AlertTitle } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import styles from "./OrderConfirmationpageThird.module.scss";
import Alert from '@mui/material/Alert';

const headerStyle = {
  fontSize: "16px",
  fontWeight: 600,
}
const cellStyle = {
  lineHeight: '0.5'
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    textAlign: "left",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    textAlign: "left",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const OrderConfirmationService = ({state}) => {
  const orderTypeOnConfirmation = sessionStorage.getItem("orderType")
  const navigate = useNavigate();
  const afterFive = state?.priorityTimeFrame?.afterFive;
  const afterThree = state?.priorityTimeFrame?.afterThree;
  const isUrgent = state?.priorityTimeFrame?.isUrgent;
  const isRoutine = state?.priorityTimeFrame?.isRoutine;
  const doNotCallProvider = state?.doNotCallProvider;

  const handleNewOrder = (event) => {
    event.preventDefault();
    localStorage.setItem('orderTypeConfirmation', orderTypeOnConfirmation)
    localStorage.setItem('selectedOrderType', orderTypeOnConfirmation);
    navigate("/orders/new-order",{replace:true});
  };
  const parsedServiceConfirmationOrder = state?.serviceOrder;
  const parsedConfirmationAllData = state?.allOrderData;
  const providerName = state?.provider?.providerName;
  const providerContact = state?.provider?.providerContact;
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' , paddingRight: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Order submitted successfully!
      </Typography>


      {afterThree && (isRoutine || isUrgent) && !doNotCallProvider &&
        <Alert icon={false} severity='info' sx={{textAlign:"left"}} >
          {afterThree && !afterFive && <AlertTitle style={{fontWeight:"600",fontSize:"var(--secondary-heading)"}} >Delivery Timeframe Notice</AlertTitle>}
          {afterFive && <>
            <AlertTitle style={{ fontWeight: "600", fontSize: "var(--secondary-heading)" }}> After-Hours Order Notice </AlertTitle></>}
          {afterThree && !afterFive && <p >This delivery is being placed after 3 pm. If you need this order processed tonight, you may need to contact your DME provider using the information below. Otherwise, it could be processed on the next business day.</p>}
          {afterFive && isRoutine && <p  >This delivery is being placed after hours. If you need this order processed tonight, please contact your DME provider using the information below. Otherwise, it will be processed on the next business day.</p>}
          {afterFive && isUrgent && <p >  This delivery is being placed after hours.<span style={{ textDecoration: "underline" }}> To ensure timely delivery,</span> you need to contact your DME provider using the information below.</p>}

          <div style={{lineHeight:"8px"}}>
            <p>{providerName}</p>
            <p>{providerContact}</p>
          </div>
        </Alert>
      }


      <Button
        data-testid='anotherOrderBtn'
        className={styles.buttonResponsive}
        onClick={handleNewOrder}
        variant="contained"
        color="primary"
        size="large"
        startIcon={<FontAwesomeIcon icon={faClipboardList} />}>
        Place Another Order
      </Button>
         <TableContainer component={Paper} sx={{ mt: 2 }}>
         <Box className={styles.scopedClassName}>
           <span className={styles.scopedClassHeading}>
             Order #<RouterLink to={"/orders/order-details/" + parsedConfirmationAllData[0]?.order_id}
               style={{
                 fontSize: "20px",
                 color: "var(--color-base)",
                 textDecoration: 'underline',
                 cursor: "pointer"
               }}>
               <span>
                 <b>{parsedConfirmationAllData[0]?.order_id}  </b>
               </span>
             </RouterLink>
           </span>
         </Box>
         <Table aria-label="customized table">
           <TableHead>
             <TableRow>
               <StyledTableCell sx={{ borderBottom: 'none',padding:'5px 16px' }}><span style={headerStyle}>Provider:</span> <span className={styles.scopedClassNameLine}  style={{fontSize:'16px'}}>{parsedConfirmationAllData[0]?.provider}</span></StyledTableCell>
             </TableRow>
             <TableRow>
               <StyledTableCell sx={{padding:'5px 16px'}} style={headerStyle}>Service Equipment</StyledTableCell>
             </TableRow>
           </TableHead>
           <TableBody>
             {parsedServiceConfirmationOrder.map((orderItem) => (
              <StyledTableRow key={orderItem?.equipment_name}>
                 <StyledTableCell className={styles.scopedClassNameLine} style={cellStyle} component="th" scope="row">
                   {orderItem?.equipment_name}
                 </StyledTableCell>
               </StyledTableRow>
             ))}
           </TableBody>
         </Table>
       </TableContainer>
    </div>
  );
};

export default OrderConfirmationService;
