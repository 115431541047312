import React from "react";
import styles from "./prescription-listing-actions.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Stack } from "@mui/system";
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

const PrescriptionListingActions = ({handlePdfView, handleEditFile, downloadFile, setDeletedFileName, setFileDeleted, item}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.PrescriptionListingActions} data-testid="PrescriptionListingActions">
      <Stack container direction="row" className={styles.buttonStyling}>
        <Tooltip
          title={
            <span className={styles.tooltipFontSize}>
              {t("Prescriptions.viewDocument")}
            </span> }
        >
          <Button
            className={styles.greenButton}
            variant="contained"
            size="small"
            data-testid="ViewDocument"
            onClick={() => handlePdfView(item)}
          >
            <RemoveRedEyeIcon className={styles.customSizeImage} />
          </Button>
        </Tooltip>

        <Tooltip
          title={
            <span className={styles.tooltipFontSize}>
              {t("Prescriptions.editDocument")}
            </span>
          }
        >
          <Button
            className={styles.blueButton}
            variant="contained"
            size="small"
            data-testid="Edit"
            onClick={() => {
              handleEditFile(item)
            }}
          >
            <EditIcon className={styles.customSizeImage} />
          </Button>
        </Tooltip>
        <Tooltip
          title={
            <span className={styles.tooltipFontSize}>
              {t("Prescriptions.downloadDocument")}
            </span>
          }
        >
          <Button
            className={styles.blueButton}
            variant="contained"
            size="small"
            data-testid="DownloadDocument"
            onClick={() => downloadFile(item)}
          >
            <SaveAltIcon className={styles.customSizeImage} />
          </Button>
        </Tooltip>
        <Tooltip
          title={
            <span className={styles.tooltipFontSize}>
              {t("Prescriptions.removeDocument")}
            </span>
          }
        >
          <Button
            className={styles.redButton}
            variant="contained"
            size="small"
            data-testid="Delete"
            onClick={(e) => {
              e.preventDefault();
              setDeletedFileName(`Are you sure you want to remove the document ${item?.display_name ?? ""}?`);
              setFileDeleted(item);
            }}
          >
            <DeleteIcon className={styles.customSizeImage} />
          </Button>
        </Tooltip>
      </Stack>
    </div>
  );
};

export default PrescriptionListingActions;
