import { NotificationApi } from "../../../constants/ApiConstants"
import { callApi as followApi, HTTPMethod } from "../../../services/HttpService/HttpService"

export const handleFollowToggle = async (followPayload) => {
    const { entityId, userId, entityType, status } = followPayload;

    const requestBody = {
        "entity_id": entityId,
        "user_id": userId,
        "entity_type": entityType,
        "status": status
    }
    const response = await followApi(HTTPMethod.Post, NotificationApi.notification, requestBody)
    if (response && response?.status_code === 200) { return response; }
}