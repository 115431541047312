import React from 'react';
import styles from './order-feedback.module.scss';
import { Grid } from "@mui/material";
import { Controller } from 'react-hook-form';

const CustomRadioButton = ({ title, noButton, yesButton, id, control, value}) => {
    return (
        <div data-testid="custom-radio-button">
            <p className='fontWeight600'>{title}</p>
            <Grid container spacing={1}>
                <Grid xs={12} md={1.5}>
                    <div className={styles.orderStatusNo}>
                        <Controller
                            name={id}
                            control={control}
                            defaultValue={value}
                            render={({ field }) => (
                                <label>
                                    <input
                                        {...field}
                                        type="radio"
                                        name={title || id}
                                        value="NO"
                                        checked={ field.value ===  "NO"}
                                    />
                                    <span className='fontWeight600'>{noButton}</span>
                                </label>
                            )}
                        />
                    </div>
                </Grid>
                <Grid xs={12} md={1.5}>
                    <div className={styles.orderStatusYes}>
                        <Controller
                            name={id}
                            control={control}
                            defaultValue={value}
                            render={({ field }) => (
                                <label>
                                    <input
                                        {...field}
                                        type="radio"
                                        name={title || id}
                                        value="YES"
                                        checked={field.value ===  "YES"  }
                                    />
                                    <span className='fontWeight600'>{yesButton}</span>
                                </label>
                            )}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
export default CustomRadioButton