import React from "react";
import { Typography, FormControl, FormLabel, FormControlLabel,Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle } from "@mui/material";
import styles from './FormAuth.module.css';
import CustomButtons from "../../../common/CustomButtons/CustomButtons";

 
const RespiratoryTherapyForm = ({handleClose, preAuthFormData}) => {
    return (
        <>
        {preAuthFormData  && (
             
             <Dialog 
             fullWidth
             maxWidth="md"
             open={true} 
             onClose={handleClose} 
             >
             <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}> Respiratory Therapy Order Documentation  </DialogTitle>
             
             
             <DialogContent>
             <Typography sx={{margin: '10px 0px'}}><span className={styles.asterisk}>*</span> denotes required field</Typography>
                {preAuthFormData[0]?.diagnosis && preAuthFormData[0].diagnosis !== '' && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> 1. Diagnosis <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].diagnosis}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   </div>
                   </FormControl>
                 </Grid>
               </Grid>}
     
    {preAuthFormData[0]?.TherapyType !== undefined && <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">2. Therapy type:<span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
           value={preAuthFormData[0].TherapyType}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="CPAP" control={<Radio />} label="CPAP" disabled={true} sx={{ cursor: 'not-allowed' }}/>
             <FormControlLabel value="APAP (Auto CPAP)" control={<Radio />} label="APAP (Auto CPAP)" disabled={true} sx={{ cursor: 'not-allowed' }}/>
             <FormControlLabel value="BIPAP Therapy" control={<Radio />} label="BIPAP Therapy" disabled={true} sx={{ cursor: 'not-allowed' }}/>
             <FormControlLabel value="BiPAP S/T (Spontaneous/ Timed)" control={<Radio />} label="BiPAP S/T (Spontaneous/ Timed)" disabled={true} sx={{ cursor: 'not-allowed' }}/>
             <FormControlLabel value="Auto BiPAP" control={<Radio />} label="Auto BiPAP" disabled={true} sx={{ cursor: 'not-allowed' }}/>
             <FormControlLabel value="AVAPS (Trilogy)" control={<Radio />} label="AVAPS (Trilogy)" disabled={true} sx={{ cursor: 'not-allowed' }}/>
             <FormControlLabel value="Ventilator" control={<Radio />} label="Ventilator" disabled={true} sx={{ cursor: 'not-allowed' }}/>
             <FormControlLabel value="AirVo" control={<Radio />} label="AirVo" disabled={true} sx={{ cursor: 'not-allowed' }}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
     </Grid>}


     {preAuthFormData[0]?.CpapEpapSetting !== undefined && preAuthFormData[0].CpapEpapSetting !== '' && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> a. EPAP Setting <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       variant="outlined"
                       margin="normal"
                       maxLength="3"
                       id="name"
                       type="text"
                       size="small"
                       disabled={true}
                       value={preAuthFormData[0].CpapEpapSetting}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   <span>cmH2O</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

     {preAuthFormData[0]?.ApapEpapRange !== undefined && preAuthFormData[0].ApapEpapRange !== '' && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> a. EPAP Range <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].ApapEpapRange}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   <span class="input-group-addon">cmH2O</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.BipapIpapSetting !== undefined && preAuthFormData[0].BipapIpapSetting !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> a. IPAP Setting <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       margin="normal"
                       type="text"
                       fullWidth
                       autoFocus
                       size="small"
                       id="name"
                       maxLength="3"
                       variant="outlined"
                       disabled={true}
                       value={preAuthFormData[0].BipapIpapSetting}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                   />
                   <span class="input-group-cmH2O">cmH2O</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.BipapEpapSetting !== undefined && preAuthFormData[0].BipapEpapSetting !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> a. EPAP Setting <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].BipapEpapSetting}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   <span class="input-group-addon">cmH2O</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.BipapStIpap !== undefined && preAuthFormData[0].BipapStIpap !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> a. IPAP Setting <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       margin="normal"
                       type="text"
                       id="name"
                       maxLength="3"
                       autoFocus
                       variant="outlined"
                       fullWidth
                       size="small"
                       value={preAuthFormData[0].BipapStIpap}
                       disabled={true}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   <span>breaths per minute (bpm)</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.BipapStEpap !== undefined && preAuthFormData[0].BipapStEpap !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> b. EPAP Setting <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].BipapStEpap}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   <span>breaths per minute (bpm)</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.AutoMinEpap !== undefined && preAuthFormData[0].AutoMinEpap !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> a. Min EPAP <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].AutoMinEpap}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   <span>cmH2O</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.AutoMaxIpap !== undefined && preAuthFormData[0].AutoMaxIpap !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> b. Max IPAP <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       type="text"
                       autoFocus
                       margin="normal"
                       id="name"
                       variant="outlined"
                       maxLength="3"
                       size="small"
                       value={preAuthFormData[0].AutoMaxIpap}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                       disabled={true}
                   />
                   <span>cmH2O</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.AutoMinPress !== undefined && preAuthFormData[0].AutoMinPress !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> c. Min Pressure Support <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].AutoMinPress}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   <span>cmH2O</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.AutoMaxPress !== undefined && preAuthFormData[0].AutoMaxPress !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> d. Max Pressure Support <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].AutoMaxPress}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   <span>cmH2O</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.AvapsMinIpap !== undefined && preAuthFormData[0].AvapsMinIpap !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> a. Min IPAP <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].AvapsMinIpap}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   <span>cmH2O</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.AvapsMaxIpap !== undefined && preAuthFormData[0].AvapsMaxIpap !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> b. Max IPAP <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       margin="normal"
                       fullWidth
                       id="name"
                       type="text"
                       autoFocus
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].AvapsMaxIpap}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   <span>cmH2O</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}


        {preAuthFormData[0]?.AvapsTidal !== undefined && preAuthFormData[0].AvapsTidal !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> c. Target Tidal Volume (200 - 1500 ml) <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].AvapsTidal}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   <span>ml</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.AvapsRiseTime !== undefined && preAuthFormData[0].AvapsRiseTime !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> d. Rise Time (1 - 3 sec) <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].AvapsRiseTime}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   <span>sec</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.AvapsInspTime !== undefined && preAuthFormData[0].AvapsInspTime !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}>  e. Inspiratory Time (0.5 - 3.0 sec, or off) <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].AvapsInspTime}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   <span>sec</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.AvapsInspRate !== undefined && preAuthFormData[0].AvapsInspRate !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> f. Rate (1 - 30, or off) <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].AvapsInspRate}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.VentMode !== undefined && <Grid container>
            <Grid xs={12} className={styles.spacingTop} sx={{paddingLeft:'10px'}}>
            <FormControl className={styles.spacingRemoveTop}>  
          <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> a. Mode <span className={styles.asterisk}>*</span></FormLabel>
                <RadioGroup
                value={preAuthFormData[0].VentMode}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="ac" control={<Radio />} label="ac" disabled={true}/>
                  <FormControlLabel value="simv" control={<Radio />} label="simv" disabled={true}/>
                  <FormControlLabel value="pcv" control={<Radio />} label="pcv" disabled={true}/>
                  
                </RadioGroup>
              </FormControl>
            </Grid>
        </Grid>}

        {preAuthFormData[0]?.VentRate !== undefined && preAuthFormData[0].VentRate !== ''  && <Grid container>
            <Grid xs={12} className={styles.spacingTop} sx={{paddingLeft:'10px'}}>
            <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> b. Rate <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].VentRate}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   </div>
                   </FormControl>
            </Grid>
        </Grid>}

        {preAuthFormData[0]?.VentTidal !== undefined && preAuthFormData[0].VentTidal !== ''  && <Grid container>
            <Grid xs={12} className={styles.spacingTop} sx={{paddingLeft:'10px'}}>
            <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> c. Tidal Volume <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].VentTidal}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   </div>
                   </FormControl>
            </Grid>
        </Grid>}

        {preAuthFormData[0]?.VentPeep !== undefined && preAuthFormData[0].VentPeep !== ''  && <Grid container>
            <Grid xs={12} className={styles.spacingTop} sx={{paddingLeft:'10px'}}>
            <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> d. PEEP <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].VentPeep}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   </div>
                   </FormControl>
            </Grid>
        </Grid>}

        {preAuthFormData[0]?.VentInspTime !== undefined && preAuthFormData[0].VentInspTime !== ''  && <Grid container>
            <Grid xs={12} className={styles.spacingTop} sx={{paddingLeft:'10px'}}>
            <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> e. Inspiratory Time <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].VentInspTime}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   </div>
                   </FormControl>
            </Grid>
        </Grid>}

        {preAuthFormData[0]?.VentPress !== undefined && preAuthFormData[0].VentPress !== ''  && <Grid container>
            <Grid xs={12} className={styles.spacingTop} sx={{paddingLeft:'10px'}}>
            <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> f. Pressure Support <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].VentPress}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   </div>
                   </FormControl>
            </Grid>
        </Grid>}

        {preAuthFormData[0]?.AirvoOxygen !== undefined && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> 3. Oxygen at <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div>
                    {preAuthFormData[0]?.OxygenLpm !== undefined && preAuthFormData[0].OxygenLpm !== '' && <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].OxygenLpm}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   }
                   <span>lpm</span>

                   <Typography style={{ textAlign: 'center' }} className="text-center">
      OR
    </Typography>

                   {preAuthFormData[0]?.OxygenRate !== undefined && preAuthFormData[0].OxygenRate !== '' && <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].OxygenRate}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />}
                   
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.Interface !== undefined && <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">a. Interface<span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
           value={preAuthFormData[0].Interface}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="CPAP" control={<Radio />} label="Nasal Cannula" disabled={true} sx={{ cursor: 'not-allowed' }}/>
             <FormControlLabel value="APAP (Auto CPAP)" control={<Radio />} label="Trach" disabled={true} sx={{ cursor: 'not-allowed' }}/>
             <FormControlLabel value="BIPAP Therapy" control={<Radio />} label="Mask" disabled={true} sx={{ cursor: 'not-allowed' }}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
        </Grid>}

        {preAuthFormData[0]?.AirvoFlow !== undefined && preAuthFormData[0].AirvoFlow !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> b. Liter Flow <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].AirvoFlow}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   <span>lpm</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        {preAuthFormData[0]?.AirvoOxygen !== undefined && preAuthFormData[0].AirvoOxygen !== ''  && <Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important', paddingLeft:'10px'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> c. Oxygen Percentage <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].AirvoOxygen}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   <span>%</span>
                   </div>
                   </FormControl>
                 </Grid>
        </Grid>}

        


        {preAuthFormData[0]?.ClinicianName !== undefined && preAuthFormData[0].ClinicianName !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
    <div>
      <Typography className={styles.labelText}>
              Clinician Name <span className={styles.asterisk}>*</span>
      </Typography>
    </div>
    <TextField
              maxLength={200}
              fullWidth
              minRows={2}
              size="small"
              value={preAuthFormData[0].ClinicianName}
              aria-label="minimum height"
              disabled={true}
              inputProps={{ style: { cursor: 'not-allowed' } }}
              sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}/>
  </Grid></Grid>
}
{preAuthFormData[0]?.ApprovingManager !== undefined && preAuthFormData[0].ApprovingManager !== '' && <Grid container data-testid='approvingManagerContainer'>
  <Grid xs={12} className={styles.spacingTop}> 
  <div data-testid='edAdminTextField'><Typography className={styles.labelText}>ED/Admin <span className={styles.asterisk}>*</span></Typography></div>
  <TextField
            maxLength={200}
            aria-label="minimum height"
            minRows={2}
            size="small"
            fullWidth
            disabled={true}
            value={preAuthFormData[0].ApprovingManager}
            inputProps={{ style: { cursor: 'not-allowed' } }}
            sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
          />
  </Grid>
</Grid>}

{preAuthFormData[0]?.AVPO !== undefined && preAuthFormData[0].AVPO !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
            AVPO <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
  fullWidth
            aria-label="minimum height"
            size="small"
            minRows={2}
            maxLength={200}
            sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
            disabled={true}
            value={preAuthFormData[0].AVPO}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
        </Grid>
    </Grid>}
     
           
             </DialogContent>
     
     <Grid container sx={{justifyContent:'end'}}>
       <Grid>
          <DialogActions style={{ padding: "14px 16px",  justifyContent: "end" }}>
              <CustomButtons type="button"  text="Close"   onClick={handleClose} />
          </DialogActions>
       </Grid>
     </Grid>
             
           </Dialog>
        )}
       
      </>
    )
}

RespiratoryTherapyForm.formName = "RespiratoryTherapyForm";
export default RespiratoryTherapyForm;