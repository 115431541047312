import React, { useState } from 'react';
import styles from '../SilentTransfer.module.scss';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from 'react-i18next';
import { FacilityTypeEnum, PhoneEnum, RelationShipEnum } from '../../../../common/enums';
import { PhoneNumberMaskLink } from '../../../../components/PhoneMasking/PhoneMasking';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

const PatientDetailsCard = ({patientsDetails, isLoading}) => {
  const { t } = useTranslation();
  const [expandedStates, setExpandedStates] = useState(true);
  const toggleExpanded = () => {
    setExpandedStates(!expandedStates);
  };
  return(
    <div data-testid="PatientDetailsCard">
   {!isLoading && patientsDetails && <Card variant="outlined" className={styles.customBox2}>
        <Box className={styles.scopedClassName}>
          <div className={styles.customStyle}>
            <span className={styles.personIcon}><PersonIcon /></span>
            <span className={styles.zipCode}>
              {patientsDetails?.patient_full_name}
              &nbsp;</span>
          </div>
          {expandedStates && <ExpandLessIcon data-testid="ExpandLessIcon" onClick={toggleExpanded} className={styles.expandStyle} />}
          {!expandedStates && <ExpandMoreIcon data-testid="ExpandMoreIcon" onClick={toggleExpanded} className={styles.expandStyle} />}
        </Box>
        <CardContent className={styles.cardContainer}>
          {expandedStates && (
            <Grid container className={styles.gridClass}>
              <Grid item md={7} xs={12} className={styles.paddingTop20px}>
                <Typography>
                  <span className={styles.branchName}>
                    {patientsDetails?.hospice_branch}
                  </span>
                </Typography>
              </Grid>
              {patientsDetails?.address?.length > 0 &&
                <Grid item md={7} xs={12} className={styles.paddingTop20px}>
                  {patientsDetails?.address[0]?.address_type &&
                    <Typography>
                      <span className={styles.content}>
                        {t('PatientsDetails.addressType')}:
                      </span>
                      <span className={styles.content2}>
                        {(patientsDetails?.address[0]?.address_type == "RES") ?
                          t('PatientsDetails.residence') : t('PatientsDetails.facility')}
                      </span>
                    </Typography>
                  }
                  {patientsDetails?.address[0]?.facility_name &&
                    <Typography>
                      <span className={styles.content}>
                      {t('PatientsDetails.facilityName')}:
                      </span>
                      <span className={styles.content2}>
                        {patientsDetails?.address[0]?.facility_name}
                      </span>
                    </Typography>
                  }
                  {patientsDetails?.address[0]?.facility_type &&
                    <Typography>
                      <span className={styles.content}>
                      {t('PatientsDetails.facilityType')}:
                      </span>
                      <span className={styles.content2}>
                        {FacilityTypeEnum[patientsDetails?.address[0]?.facility_type]}
                      </span>
                    </Typography>
                  }
                  {patientsDetails?.address[0]?.address_line1 &&
                    <Typography className={styles.marginTop}>
                      <span className={styles.branchName}>
                        {patientsDetails?.address[0]?.address_line1},
                      </span>
                    </Typography>
                  }
                  {patientsDetails?.address[0]?.address_line2 &&
                    <Typography className={styles.marginTop}>
                      <span className={styles.branchName}>
                        {patientsDetails?.address[0]?.address_line2},
                      </span>
                    </Typography>
                  }
                  <Typography>
                    <span className={styles.branchName}>
                      {patientsDetails?.address[0]?.city + ","} {patientsDetails?.address[0]?.state} {patientsDetails?.address[0]?.zip_code}
                    </span>
                  </Typography>
                  {patientsDetails?.address[0]?.room_number &&
                    <Typography>
                      <span className={styles.content}>
                      {t('PatientsDetails.room')}:
                      </span>
                      <span className={styles.content2}>
                        {patientsDetails?.address[0]?.room_number}
                      </span>
                    </Typography>
                  }
                  {patientsDetails?.address[0]?.bed_number &&
                    <Typography>
                      <span className={styles.content}>
                      {t('PatientsDetails.bed')}:
                      </span>
                      <span className={styles.content2}>
                        {patientsDetails?.address[0]?.bed_number}
                      </span>
                    </Typography>
                  }
                  {patientsDetails?.address[0]?.address_notes &&
                    <Typography>
                      <span className={styles.content}>
                      {t('PatientsDetails.addressNotes')}:
                      </span>
                      <span className={styles.content2}>
                        {patientsDetails?.address[0]?.address_notes}
                      </span>
                    </Typography>
                  }
                </Grid>
              }
              {patientsDetails?.contacts?.length > 0 && <Grid item md={7} xs={12} className={styles.paddingTop20px}>
                <Typography>
                  {patientsDetails?.contacts[0]?.is_primary_contact ?
                    <span className={styles.content}>
                       {t('PatientsDetails.primaryContact')}:
                    </span>
                    :
                    <span className={styles.content}>
                       {t('PatientsDetails.contact')}:
                    </span>
                  }
                  <span className={styles.content2}>
                    {patientsDetails?.contacts[0]?.contact_name} {" "}
                    ({RelationShipEnum[patientsDetails?.contacts[0]?.relationship]})
                  </span>
                </Typography>
                <Typography>
                  <span className={styles.content}>
                  {t('PatientsDetails.phone')}:
                  </span>
                  <span className={styles.content2}>
                    {<PhoneNumberMaskLink
                      phoneNumber={
                        patientsDetails?.contacts[0]?.phone
                      }
                      tooltip={false}
                    />}
                  </span>
                  <span className={styles.contactIcons}>
                    <SmartphoneIcon className={styles.smartPhoneStyling} />
                  </span>
                  <span className={styles.content3}>
                    ({PhoneEnum[patientsDetails?.contacts[0]?.phone_type]})
                  </span>
                </Typography>
                {patientsDetails?.contacts[0]?.extension && (
                  <Typography>
                    <span className={styles.content}> {t('PatientsDetails.extension')}:</span>
                    <span className={styles.content2}>
                      {patientsDetails?.contacts[0]?.extension}
                    </span>
                  </Typography>
                )}
                {(patientsDetails?.contacts[0]?.email) &&
                  (
                    <Typography>
                      <span className={styles.content}>
                        {t('UserDetails.email')}:
                      </span>
                      <span className={styles.content2}>
                        {patientsDetails?.contacts[0]?.email}
                      </span>
                    </Typography>
                  )}
              </Grid>}
            </Grid>
          )}
        </CardContent>
      </Card>}
  </div>
  )
};

export default PatientDetailsCard;