import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    openRelaunch: false,
    platformModal: false,
    openRelaunchTooltip: false,
    showTour: false,
    walkThroughDetail: []
}

const relaunch = createSlice({
    name: 'supportReducer',
    initialState,
    reducers: {
        setOpenRelaunch(state, action) {
            return { ...state, openRelaunch: action.payload.openRelaunch }
        },
        setPlatformModal(state, action) {
            return { ...state, platformModal: action.payload.platformModal }
        },
        setRelaunchTooltip(state, action) {
            return {...state, openRelaunchTooltip: action.payload.openRelaunchTooltip}
        },
        setShowTour(state, action) {
            return {...state, showTour: action.payload.showTour}
        },
        setWalkThroughData(state, action) {
            return {...state, walkThroughDetail: action.payload}
        }

    }
})

export const { setOpenRelaunch, setPlatformModal, setRelaunchTooltip, setShowTour, setWalkThroughData} = relaunch.actions;
export default relaunch.reducer;