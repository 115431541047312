import React from 'react';
import styles from './PriorityIcon.module.scss';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FlightLandIcon from '@mui/icons-material/FlightLand';

const PriorityIcon = (priorityName) => {

  switch (priorityName) {
    case "STAT (Within 2 Hours)":
      return (
        <div className={`${styles.deliveryStatusImg} ${styles.statIcon}`}>
          <ReportProblemRoundedIcon
          className={`${styles.customSizeImage2} ${styles.backgroundColorRed}`} />
          <span
            className={`${styles.deliveryStatusText} ${styles.lineHeight}`}>
            STAT
          </span>
        </div>
      );
    case "Urgent (Within 4 Hours)":
      return (
          <div className={styles.deliveryStatusImg}>
            <ReportProblemRoundedIcon
              className={`${styles.customSizeImage2} ${styles.urgentIcon}`}
            />
            <span className={styles.deliveryStatusText}>
              Urgent
            </span>
          </div>
      );
    case "Priority (Within 6 Hours)":
      return (
        <div className={`${styles.deliveryStatusImg} ${styles.priorityMarginBottom}`}>
            <ReportProblemRoundedIcon
            className={`${styles.customSizeImage2} ${styles.backgroundColorYellow}`} />
            <span
              className={`${styles.deliveryStatusText} ${styles.lineHeight}`}>
              Priority
            </span>
          </div>
      );
    case "Routine (Same day if ordered before 3 PM local time)":
      return (
          <div className={styles.deliveryStatusImg}>
                <WatchLaterIcon
                 className={`${styles.customSizeImage2} ${styles.backgroundColorGreen}`}/>
                <span
                 className={`${styles.deliveryStatusText} ${styles.lineHeight}`}>
                  Routine
                </span>
              </div>
      );
    case "Standard (Within 24 Hours)":
      return (
          <div className={styles.deliveryStatusImg}>
            <WatchLaterIcon
              className={`${styles.backgroundColorBlue} ${styles.customSizeImage2}`}
            />
            <span
              className={`${styles.deliveryStatusText} ${styles.lineHeight}`}>
              Standard
            </span>
          </div>
      );
      case "Coordination (Scheduled Date and Time)":
        return (
            <div className={`${styles.deliveryStatusImg} ${styles.marginRight6px}`}>
              <CalendarMonthIcon
                className={`${styles.customSizeImage2} ${styles.backgroundColorBlue}`}
              />
              <span
               className={`${styles.deliveryStatusText} ${styles.lineHeight}`}>
                Coordination
              </span>
            </div>
        );
    case "Drop Ship (Freight Times)":
      return (
        <div className={styles.deliveryStatusImg}>
          <FlightLandIcon
            className={`${styles.customSizeImage2} ${styles.backgroundColorBlue} ${styles.lineHeightDropshipIcon}`}/>
          <span
            className={`${styles.deliveryStatusText} ${styles.lineHeight}`}>
            Dropship
          </span>
        </div>
      );
    default:
      return null;
  }
};


export default PriorityIcon;
