import { HTTPMethod, callApi } from "../../../services/HttpService/HttpService";
import { v4 as uuidv4 } from 'uuid';
import { successToast } from "../../../components/ToastMessage/ToastMessage";
import { OrdersComponentApi } from "../../../constants/ApiConstants";

export const handleAddNote = async (location, noteContent, setNoteContent, setRefreshOrders) => {
  const notesOrderId = location.pathname.split("/").pop();
  const requestBody = {
    order_Id: notesOrderId,
    note: noteContent,
    message_id: uuidv4()
  };
  const response = await callApi(
    HTTPMethod.Post,
    OrdersComponentApi.ordersNotes.replace('{order_id}', notesOrderId),
    requestBody
  );
  if (response && response.status_code == 200) {
    setNoteContent("");
    successToast("Note added successfully.");
    setRefreshOrders(true);
  }
};

export const formatCanceledDateTime = (params) => {
    const date = new Date(params);
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    return (
      <>{formattedDate} {" "} ({formattedTime})</>
    );
}