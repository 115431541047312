import React from 'react';
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WhereToVoteOutlinedIcon from '@mui/icons-material/WhereToVoteOutlined';
import { useTranslation } from "react-i18next";
import StatusColor from '../../common/Utils/StatusColor/StatusColor';
import { etaTime } from '../../common/Utils/etaTime/etaTime';
import styles from './ETA.module.scss';
import { useSelector } from 'react-redux';
import { commonConstants } from '../../common/constants';
import { configurationCompanySetting, statusEnum } from '../../common/enums';

const ETA = ({ manualEta, orderStatus, arrivalTime, formattedArrivalTime, etaWindow, isDelayed }) => {
    const { t } = useTranslation();

    const companySettings = useSelector(state => state.authState.userData?.data?.company_settings);
    const enRouteEtaSetting = companySettings?.some(setting => setting.module_name === configurationCompanySetting.EnRouteEtaVisible);

    const removeSecondsFromTime = (time = "") => etaTime(time)?.substring(0, 5).concat(etaTime(time)?.substring(8));

    let etaText = (manualEta && manualEta != commonConstants?.defaultDateTime && orderStatus === statusEnum["On my way"]) ? `${t("OrdersTab.eta")} : ${etaTime(manualEta)}` : "";
    let Icon = AccessTimeIcon;

    if (enRouteEtaSetting && (orderStatus === statusEnum["En-Route"] || orderStatus === statusEnum["Picking Up"]) && etaWindow) {
        etaText = `${t("OrdersTab.eta")} : ${etaWindow}`
    }
    else if (orderStatus === statusEnum["On my way"] && isDelayed) {
        etaText = `${t("Dashboard.delayed")}`;
    }
    else if (arrivalTime) {
        etaText = `${t("OrdersTab.driverArrived")} : ${removeSecondsFromTime(formattedArrivalTime)}`;
        Icon = WhereToVoteOutlinedIcon;
    }

    return (
        etaText && <div className={styles.etaMain}>
            <Icon className={styles.timeIcon} style={{ color: StatusColor(orderStatus) }} />
            <div className={styles.etaText} style={{ color: StatusColor(orderStatus) }} >
                {etaText}
            </div>
        </div>
    )
}

export default ETA;