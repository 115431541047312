import React from 'react';
import styles from './progress-bar.module.scss';

const ProgressBar = ({ value, label }) => {
  return (
    <div className={styles.progressBar} data-testid="ProgressBar">
      <div className={styles.progress} style={{ width: `${value}%` }}>
      </div>
    </div>
  );
};



export default ProgressBar;
