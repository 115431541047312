import { CustomFieldsApi } from '../../constants/ApiConstants';
import { HTTPMethod,callApi as customFieldsRequest } from '../../services/HttpService/HttpService';

export const CustomFields = async (locationId, companyId = 0) => {
  const customFieldArray = [];
  const customFieldUrl = CustomFieldsApi.customFields + "?branch_id=" + locationId + "&company_id=" + (companyId)  + "&table_name=tblpatient";
  const customFieldsResponse = await customFieldsRequest(HTTPMethod.Get, customFieldUrl);
  if (customFieldsResponse && customFieldsResponse.status_code == 200 && customFieldsResponse.data?.custom_field_list?.length > 0){
    customFieldsResponse.data?.custom_field_list.forEach((item)=>{
      customFieldArray.push(item);
    })
  }
  return customFieldArray;
}

