import React from "react";
import styles from "./ShareFeedbackThankYouPage.module.scss";
import { Container } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/base";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb/MuiBreadcrumb";

const ShareFeedbackThankYouPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const openContactCustomerSupportPage = () => {
    navigate("/contact-customer-support");
  };
  const breadcrumbItems = [
    { label: "Support", link: "/support" },
    { label: "Share your feedback" },
  ];
  return (
    <div className={styles.ShareFeedbackThankYouPage} data-testid="ShareFeedbackThankYouPage" >
      <Container className='mainContainer'>
      <Breadcrumb items={breadcrumbItems} />
        <p className={styles.commonHeading}>Thanks for your feedback!</p>
        <div>
          <p>
            We appreciate you sharing your ideas and comments. If you need help
            resolving a problem, feel free to{" "}
            <a href="/contact-customer-support" className={styles.contactLink}>
              {t("ContactCustomerSupportPage.ContactCustomerSupport")}.
            </a>
          </p>
        </div>
        <hr />
        <p className={styles.subHeading}>Need to get in touch?</p>
        <p className={styles.commonContent}>
          We'll start with some questions and get you to the right place.
        </p>
        <div>
          <Button
            onClick={openContactCustomerSupportPage}
            autoFocus
            sx={{ mt: 2, mb: 2 }}
            className={styles.SelectButton}
          >
            Contact customer support
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default ShareFeedbackThankYouPage;
