import React from 'react';
import { Helmet } from 'react-helmet';

const withTitle = (Component, title) => {
  return (props) => (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Component {...props} />
    </>
  );
};

export default withTitle;
