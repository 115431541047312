import React, { useState } from 'react';
import LocationPopup from '../../common/Utils/LocationPopup/LocationPopup';
import styles from "./MapLocationButton.module.scss";
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import { Tooltip, Button } from '@mui/material';
import { useTranslation } from "react-i18next";
const MapLocationButton = ({ orderStatus, eta, orderId, latitude, longitude, addressLine1, addressLine2, city, state, zipcode, country, arrivalTime, formattedArrivalTime, isDelayed, etaWindow }) => {
    const { t } = useTranslation();
    const [openLocationPopup, setOpenLocationPopup] = useState(false);
    const location = {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
    }
    const addressDetails = {
        addressLine1: addressLine1 ? addressLine1 : '',
        addressLine2: addressLine2 ? addressLine2 : '',
        city: city,
        state: state,
        zipcode: zipcode,
        country: country
    };
    return (
        <>
            <Tooltip
                title={
                    <span className={styles.mapLocationTitle}>
                        {t("PatientsDetails.fulfillmentLocation")}
                    </span>
                }
            >
                <div className={styles.MapLocationButton} spacing={2} data-testid="mapIcon" onClick={() => setOpenLocationPopup(!openLocationPopup)}>
                    <Button className={styles.mapButton} variant="contained" size="small">
                        <MapRoundedIcon className={styles.mapIcon} />
                    </Button>
                </div>
            </Tooltip>
            {openLocationPopup && <LocationPopup orderId={orderId} location={location} eta={eta} orderStatus={orderStatus} openLocationPopup={openLocationPopup} setOpenLocationPopup={setOpenLocationPopup} addressDetails={addressDetails} arrivalTime={arrivalTime} formattedArrivalTime={formattedArrivalTime} isDelayed={isDelayed} etaWindow={etaWindow}/>}
        </>
    )
}

export default MapLocationButton;