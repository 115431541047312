import style from "./PhoneMasking.module.scss";

function PhoneNumberMask({ phoneNumber }) {
  // Assuming phoneNumber is in the format "555-123-4567"
  let parts = "";
  let maskedPhoneNumber = "";

  if (typeof phoneNumber === "string" && phoneNumber.includes("-")) {
     parts = phoneNumber.split("-");
     maskedPhoneNumber = `(${parts[0]}) ${parts[1]}-${parts[2]}`;
    return <span className={style.customColorText}>{maskedPhoneNumber}</span>;
  } else {
    // Handle the case where phoneNumber is not in the expected format
    return (
      <span className={style.customColorText}>Invalid phone number format</span>
    );
  }
}

export function PhoneNumberMaskLink({ phoneNumber,tooltip }) {
  let maskedPhoneNumber = "";
  const dynamicClassName = tooltip ? style.customColorTextWithTooltip : style.customColorText;
  let phoneNumberFormatChange = phoneNumber?.replace(/-/g, "");
  if (typeof phoneNumberFormatChange === "string") {
      maskedPhoneNumber = phoneNumberFormatChange.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");      ;
     return <span className={dynamicClassName}>{maskedPhoneNumber}</span>;
  }

  const linkStyle = {
    textDecoration: "none",
  };

  return (
    <a
      style={linkStyle}
      className={style.customColorText}
    >
      {maskedPhoneNumber}
    </a>
  );
}

export default PhoneNumberMask;
