import * as Sentry from "@sentry/react";
export const sentryInfo = (text, param) => {
    const userData = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {user_id:0,email:''};
    if (process.env.REACT_APP_SENTRY_DSN){
        if (param && typeof param == "object") {
            Sentry.captureMessage(text, scope => {
                scope.clear();
                scope.setUser({id: userData?.user_id, email: userData?.email})
                scope.setLevel('info');
                scope.setContext('Request Body', param);
            });
        } else {
            const level = param ?? 'info';
            setSentryUser(userData?.user_id,userData?.email);
            Sentry.captureMessage(text, level);
        }
    }
};
export const setSentryUser = (sentryUser,sentryEmail) => {
    if(sentryUser && sentryEmail){
        Sentry.setUser({id: sentryUser, email: sentryEmail});
    }
}
const SentryInit = () => {
    const sentryDsn = process.env.REACT_APP_SENTRY_DSN; // This contains sentry DSN
    if (sentryDsn && window.location.origin !== "http://localhost:3000") {
        Sentry.init({
            dsn: sentryDsn,
            integrations: [
                Sentry.browserTracingIntegration(), //Capture performance data for the Browser.
                Sentry.captureConsoleIntegration({ levels: ['info'] }), //Captures all Console API calls via `captureException` or `captureMessage`.
                Sentry.httpClientIntegration(), //Captures errors on failed requests from Fetch and XHR and attaches request and response information.
                // Sentry.replayIntegration() //Capture a video-like reproduction of what was happening in the user's browser.
            ],
            sendDefaultPii: true,
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],
            // Session Replay
            // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
};
export default SentryInit;