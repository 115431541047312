import { Link } from 'react-router-dom';
import { LineOfBusinessEnum } from '../../../constants/BusinessConstants';
import React from 'react';
import style from './NotificationTitle.module.scss';

const NotificationTitle = ({ title, data, readNotification }) => {
    const { redirect_id } = data;
    const parsedRedirectId = redirect_id && JSON.parse(redirect_id);
    const patientToMember = LineOfBusinessEnum?.patientToMember;
    const patientsToMembers = LineOfBusinessEnum?.patientsToMembers;

    const modifiedTitle = title
        ?.replace('#Verbias', `${patientToMember}`);

    const parts = modifiedTitle?.split(' ');
    const linkProps = {
        '#PatientId': `/${patientsToMembers.toLowerCase()}/${patientToMember.toLowerCase()}-details/`,
        '(#PatientId)': `/${patientsToMembers.toLowerCase()}/${patientToMember.toLowerCase()}-details/`,
        '#RecurringOrderId': `/recurring-orders/recurring-order-details/`,
        '#OrderApprovalId': `/order-approvals/order-approval-details/`,
        '#OrderId': `/orders/order-details/`,
        '#SupplyOrderId':'/orders/supply-order-details/',
        '(#ParentOrderId)': '/orders/order-details/',
        '(#ParentOrderId).': '/orders/order-details/'
    };

    return (
        <>
            {parts?.map((part) => {
                const linkTo = linkProps[part];
                const id = part.startsWith('(#') ? parsedRedirectId[part.replace(/[().]/g, '')] : parsedRedirectId?.[part];

                if (linkTo) {
                    return (
                        <>
                            {part.startsWith('(#') ?
                                <>
                                    <span>(</span><Link key={id} to={`${linkTo}${id}`} className={style.titleLink} data-testid='link'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            readNotification(e, data, `${linkTo}${id}`)
                                        }}>
                                        {id}
                                    </Link><span>){part.includes('.') && '.'}</span>
                                </>
                                :
                                <>
                                    <Link key={id} to={`${linkTo}${id}`} className={style.titleLink} data-testid='linkwithNo#'
                                        onClick={(e) => { 
                                            e.stopPropagation();
                                            readNotification(e, data, `${linkTo}${id}`)
                                        }}>
                                        {id}

                                    </Link>&nbsp;
                                </>
                            }
                        </>
                    );
                } else {
                    return <span key={id} className={style.displayLineBreak}>{part} </span>;
                }
            })}
        </>
    );
};

export default NotificationTitle;