import dayjs from "dayjs";
import { OrdersTypeEnum, WalkThroughPathType } from "../../enums";
import { LineOfBusinessEnum } from "../../../constants/BusinessConstants";

export const handlePrimaryContact = (contactData) => {
    const tempPatientData = [];
      const primaryContact = contactData?.find(contact => contact.is_primary_contact);
      if (primaryContact) 
      tempPatientData[0] = primaryContact;

      contactData?.forEach(contact => {
        if (!contact.is_primary_contact) {
            tempPatientData.push(contact);
        }
      });
      return tempPatientData;
}

export const mixedOrderTitle = (mixOrderConfig) => {
  const orders = mixOrderConfig.split(",");
  let ordersTooltip = orders.map(order => OrdersTypeEnum[order]);

  if (ordersTooltip.length === 2) {
    return `Proof of ${ordersTooltip.join(" and ")}`;
  } else if (ordersTooltip.length > 2) {
    const lastOrder = ordersTooltip.pop();
    return `Proof of ${ordersTooltip.join(", ")} and ${lastOrder}`;
  }

  return `Proof of ${ordersTooltip.join(", ")}`;
}

export const checkFirstLetterValid = (val) => {
  return !(/^\s/.test(val));
};

export const thirtyDaysBackDate = () => {
  const priorDate = dayjs().add(-30, 'day');
  return dayjs(priorDate).format('MM/DD/YYYY')
}


const patientsToMembers = LineOfBusinessEnum.patientsToMembers.toLowerCase();
const patientToMember = LineOfBusinessEnum.patientToMember.toLowerCase();

export const checkUrlPath = () => {
  const pathname = location.pathname;
  if (pathname === `/${patientsToMembers}` || pathname === '/members' || pathname === '/residents') {
      return WalkThroughPathType.Patients;
  } else if (pathname === '/orders') {
      return WalkThroughPathType.Orders;
  } else if (pathname === '/orders/new-order') {
      return WalkThroughPathType.NewOrder;
  } else if (pathname.includes(`/${patientsToMembers}/${patientToMember}-details`) || pathname.includes(`/members/member-details/`) || pathname.includes(`residents/resident-details`)) {
      return `PatientDetailsTab`;
  } else if (pathname.includes('/orders/order-details')) {
      return WalkThroughPathType.OrdersDetailsTab;
  } else {
      return '';
  }
};