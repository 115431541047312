export const regExPattern = {
    notAllowedSpecialCharAtStart: /^[\sa-zA-Z0-9](.*[a-zA-Z0-9])?/,
    emailPattern: /^[\w\-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/,
    phoneNumber: /[-() ]/g,
    specialCharacters: /[-[\]\/{}()*+?.\\^$|]/g,
    numberOnly: /[^0-9]/g,
    phoneNumberRegex:/(\d{3})(\d{3})(\d{4})/,
    phoneNumberFormat:"($1) $2-$3"
}
export const commonConstants = {
    defaultDate: "01/01/0001",
    defaultDateTime: "01/01/0001 (12:00 AM)",
    invalidDate:"Invalid Date",
    minValidDate:1900,
    maxValidDate:2100
}

export const patientRelationshipData = ["Caregiver", "Child", "Immediate Family", "Other", "Parent", "Self", "Spouse", "Unknown"];

export const numberLength = (val) => {
    const number = val?.replace(/[-() ]/g, '')
    return (number?.length === 10 || number?.length === 0);
  }

export const serviceItemId = 227;




