import React from "react";
import { Typography, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle } from "@mui/material";
import styles from './FormAuth.module.css';
import CustomButtons from "../../../common/CustomButtons/CustomButtons";


const BariatricBedAuthorizationForm = ({handleClose, preAuthFormData}) => {

  function formatHeight(inches) {
    inches = inches.toString();
    if (inches.length == 3) {
      return `${inches[0]}'${inches[1]}${inches[2]}"`
  }
    if (inches.length == 2) {
        return `${inches[0]}'${inches[1]}"`
    }
    if (inches.length == 1) {
        return `${inches[0]}'` ;
    }
}
  
    return (  
      <> 
      {preAuthFormData  && (
           
           <Dialog 
           fullWidth
           maxWidth="md"
           open={true} 
           onClose={handleClose} 
           >
           <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}> Bariatric Bed Pre-Authorization Documentation </DialogTitle>
           
           
           <DialogContent>
           <Typography sx={{margin: '10px 0px'}}><span className={styles.asterisk}>*</span> denotes required field</Typography>
              {preAuthFormData[0].BranchCode !== '' && preAuthFormData[0].BranchCode !== undefined  && <Grid container>
               <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important'}}>
               <FormControl className={styles.spacingRemoveTop}>  
                 <div>
                   <Typography className={styles.labelText}> Branch/State (3 letter code) <span className={styles.asterisk}>*</span> </Typography>
                 </div>
                 <div >
                 <TextField
                     value={preAuthFormData[0].BranchCode}
                     variant="outlined"
                     margin="normal"
                     autoFocus
                     disabled={true}
                     id="name"
                     fullWidth
                     size="small"
                     maxLength="3"
                     type="text"
                     sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                     inputProps={{ style: { cursor: 'not-allowed' } }}
                 />
                 </div>
                 </FormControl>
               </Grid>
             </Grid>}
   
   {preAuthFormData[0]?.ResidenceType !== undefined && preAuthFormData?.ResidenceType !== '' &&  <Grid container>
     <Grid xs={12} className={styles.spacingTop}>
     <FormControl className={styles.spacingRemoveTop}>  
   <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 1. Where will the patient reside?<span className={styles.asterisk}>*</span></FormLabel>
         <RadioGroup
         value={preAuthFormData[0].ResidenceType}
           row
           aria-labelledby="demo-row-radio-buttons-group-label"
           name="row-radio-buttons-group"
         >
           <FormControlLabel value="1" control={<Radio />} label="Home" disabled={true} sx={{ cursor: 'not-allowed' }}/>
           <FormControlLabel value="2" control={<Radio />} label="SNF/ALF/ILF" disabled={true} sx={{ cursor: 'not-allowed' }}/>
           
         </RadioGroup>
       </FormControl>
     </Grid>
   </Grid>}
   
   
   {preAuthFormData[0].PatientHeight !== undefined && preAuthFormData[0].PatientHeight !== ''  && <Grid container>
     <Grid xs={12} className={styles.spacingTop}> 
     <div>
       <Typography className={styles.labelText}>
          2. Patient's Height
       </Typography>
     </div>
     <TextField
     fullWidth
               aria-label="minimum height"
               minRows={2}
               maxLength={500}
               size="small"
               value={formatHeight(preAuthFormData[0].PatientHeight)}
               sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
               disabled={true}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
     </Grid>
   </Grid>}

   {preAuthFormData[0].PatientWeight !== undefined  && preAuthFormData[0].PatientWeight !== '' && <Grid container>
     <Grid xs={12} className={styles.spacingTop}> 
     <div>
       <Typography className={styles.labelText}>
          3.  Patient's Weight
       </Typography>
     </div>
     <TextField
     fullWidth
               aria-label="minimum height"
               minRows={2}
               maxLength={50}
               size="small"
               value={`${preAuthFormData[0].PatientWeight} lbs`}
               sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
               disabled={true}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
             <i>Please note: The minimum weight for a bariatric bed is 350 lbs and the maximum weight is 600 lbs.</i>
     </Grid>
   </Grid>}
   
   
   {preAuthFormData[0].ClinicalRationale !== undefined && preAuthFormData[0].ClinicalRationale !== '' && <Grid container>
     <Grid xs={12} className={styles.spacingTop}> 
     <div>
       <Typography className={styles.labelText}>
        4. If the patient does not meet the weight requirements above, what is the clinical rationale requiring a bariatric bed? <span className={styles.asterisk}>*</span>
       </Typography>
     </div>
     <TextField
               fullWidth
               aria-label="minimum height"
               minRows={2}
               maxLength={500}
               size="small"
               value={preAuthFormData[0].ClinicalRationale}
               sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
               disabled={true}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
     </Grid>
   </Grid>}
   
   
   
   {preAuthFormData[0].ExtenuatingCircumstances !== undefined && preAuthFormData[0].ExtenuatingCircumstances !== '' && <Grid container>
     <Grid xs={12} className={styles.spacingTop}> 
     <div>
       <Typography className={styles.labelText}>
          5. Please explain any extenuating circumstances that demonstrate the need for a bariatric bed vs. a standard bed. <span className={styles.asterisk}>*</span>
       </Typography>
     </div>
     <TextField
               fullWidth
               aria-label="minimum height"
               minRows={2}
               maxLength={500}
               size="small"
               value={preAuthFormData[0].ExtenuatingCircumstances}
               sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
               inputProps={{ style: { cursor: 'not-allowed' } }}
               disabled={true}
             />
     </Grid>
   </Grid>}
   
   
   {preAuthFormData[0].ClinicianName !== '' && preAuthFormData[0].ClinicianName !== undefined && <Grid container>
     <Grid xs={12} className={styles.spacingTop}> 
     <div>
       <Typography className={styles.labelText}>
               Clinician Name <span className={styles.asterisk}>*</span>
       </Typography>
     </div>
     <TextField
               fullWidth
               maxLength={500}
               aria-label="clinic name field"
               size="small"
               value={preAuthFormData[0].ClinicianName}
               minRows={2}
               sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
               inputProps={{ style: { cursor: 'not-allowed' } }}
               disabled={true}
             />
     </Grid>
   </Grid>}
   
   
   {preAuthFormData[0].ApprovingManager !== '' && preAuthFormData[0].ApprovingManager !== undefined && <Grid container>
     <Grid xs={12} className={styles.spacingTop}> 
     <div>
       <Typography className={styles.labelText}>
               ED/Admin <span className={styles.asterisk}>*</span>
       </Typography>
     </div>
     <TextField
               aria-label="adminTextLabel"
               minRows={2}
               fullWidth
               maxLength={500}
               size="small"
               value={preAuthFormData[0].ApprovingManager}
               inputProps={{ style: { cursor: 'not-allowed' } }}
               sx={{backgroundColor:'var(--color-disableField)', marginTop:'5px',cursor:'not-allowed' }}
               disabled={true}
             />
     </Grid>
   </Grid>}
   
   {preAuthFormData[0].AVPO !== undefined && preAuthFormData[0].AVPO !== '' && <Grid container>
     <Grid xs={12} className={styles.spacingTop}> 
     <div>
       <Typography className={styles.labelText}>
               AVPO <span className={styles.asterisk}>*</span>
       </Typography>
     </div>
     <TextField
     fullWidth
               aria-label="textField Avpo"
               minRows={2}
               maxLength={500}
               size="small"
               value={preAuthFormData[0].AVPO}
               sx={{backgroundColor:'var(--color-disableField)', marginTop:'5px'}}
               disabled={true}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
     </Grid>
   </Grid>}
         
           </DialogContent>
   
   <Grid container sx={{justifyContent:'end'}}>
     <Grid>
     <DialogActions
                 style={{ justifyContent: "end", padding: "14px 16px" }}
               >
                 <CustomButtons type="button" text="Close" onClick={handleClose} />
               </DialogActions>
     </Grid>
   </Grid>
           
         </Dialog>
      )}
     
    </>
    )
}

BariatricBedAuthorizationForm.formName = "BariatricBedAuthorizationForm";
export default BariatricBedAuthorizationForm;