import React, { useEffect, useState } from 'react';
import styles from '../SilentTransfer.module.scss';
import { Box, Card, CardContent, Grid, Typography, Autocomplete, TextField, FormLabel, Alert } from '@mui/material';
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { HTTPMethod, callApi as silentTransferRequest } from '../../../../services/HttpService/HttpService';
import { PatientTransfer } from '../../../../constants/ApiConstants';
import { LineOfBusinessEnum } from '../../../../constants/BusinessConstants';

const BranchAutocomplete = ({branchName, hospiceError, setHospiceError, hospiceBranch, setSelectedHospiceBranch, patientsDetails, isLoading}) => {
  const { t } = useTranslation();
  const [expandedStateTransfer, setExpandedStateTransfer] = useState(true);
  const [branchOptions, setBranchOptions] = useState(null);
  const [isBranchList, setIsBranchList] = useState(false);
  const [isEmptyBranchList, setIsEmptyBranchList] = useState(false);
  const lineOfBusinessEnum = LineOfBusinessEnum.patientToMember.toLowerCase();
  const toggleTransferExpanded = () => {
    setExpandedStateTransfer(!expandedStateTransfer);
  };
  const handleBranchToTransfer = async () => {
    const response = await silentTransferRequest(
      HTTPMethod.Get,
      PatientTransfer.branchToTransfer
      .replace("{companyId}", patientsDetails?.company_id)
      .replace("{locationId}", patientsDetails?.hospice_branch_id)
      .replace("{patientId}", patientsDetails?.patient_id)
    );
    if (response && response.status_code == 200 && response.data) {
      setBranchOptions(response?.data?.branches);
      if (response?.data?.branches?.length > 0) {
        setIsBranchList(true);
      }
      else {
        setIsEmptyBranchList(true);
      }
    }
  };
  const handleHospiceBlur = (event) => {
    if (hospiceBranch || hospiceBranch?.location_id) {
      setHospiceError(false);
    }else{
      setHospiceError(true);
    }
  };
  const handleBranchFilterOption = (option, state) => {
    if (option.length > 0 && state.inputValue && state.inputValue?.trim().length >= 3) {
      return option.filter((item) =>
        String(item.name)
          .toLowerCase()
          .includes(state.inputValue.toLowerCase().trimStart().trimEnd())
      );
    }
    return option;
  };
  const isBranchValueMatchesOption = (option, value) => {
    return (option?.location_id == value?.location_id)
  };
  const handleOnBranchChange = (event, newValue) => {
    setSelectedHospiceBranch(newValue);
  }
  useEffect(() => {
    if(patientsDetails?.patient_id && patientsDetails?.hospice_branch_id && patientsDetails?.company_id){
      handleBranchToTransfer();
    }
  }, []);
  return(
    <div data-testid="BranchAutocomplete">
   {!isLoading && <Card variant="outlined" className={styles.customBox2} data-testid="TransferInformation">
        <Box className={styles.scopedClassName}>
          <div className={styles.container}>
            <span className={styles.personIcon}>
              <FontAwesomeIcon icon={faTruck} />
            </span>
            <span className={styles.zipCode}>
              {t('SilentTransfer.transferInformation')}
              &nbsp;</span>
          </div>
          {expandedStateTransfer && <ExpandLessIcon data-testid="ExpandLessIcon" onClick={toggleTransferExpanded} className={styles.expandStyle} />}
          {!expandedStateTransfer && <ExpandMoreIcon data-testid="ExpandMoreIcon" onClick={toggleTransferExpanded} className={styles.expandStyle} />}
        </Box>
        <CardContent className={styles.cardContainer}>
          {expandedStateTransfer && (
            <Grid container className={styles.gridClass}>
              {isBranchList && <Grid item md={12} xs={12} className={styles.paddingTop20px}>
                <Alert severity="warning"
                  icon={false}>
                  <div className={styles.stylingHeading}>
                    {t("SilentTransfer.patientMovedToNewBranch").replace('#patient', lineOfBusinessEnum).replace('#patient', lineOfBusinessEnum)}
                  </div>
                </Alert>
              </Grid>}
              <Grid item md={12} xs={12}>
                {isEmptyBranchList && <Typography className={styles.marginTopBottom}>
                  <Alert severity="error" icon={false}>
                    <div className={styles.stylingHeading}>
                      {t("SilentTransfer.branchListEmptyMessage").replace('#patient', lineOfBusinessEnum)}
                    </div>
                  </Alert>
                </Typography>}
                <Typography className={styles.marginTopBottom}>
                  <span className={styles.content}>
                  {t('SilentTransfer.currentBranch')}:
                  </span>
                  <span className={styles.content2}>
                    {branchName}
                  </span>
                </Typography>
                <Typography>
                  <span className={styles.content3}>
                  {t('SilentTransfer.transferBranch')}
                  </span>
                  <span className={styles.colorRed}> *</span>
                  <div className={styles.marginTop}>
                    <Autocomplete
                      id="hospiceBranch"
                      data-testid="hospiceBranch"
                      popupIcon={<KeyboardArrowDownIcon />}
                      noOptionsText={t("Dashboard.noDataFound")}
                      onBlur={handleHospiceBlur}
                      options={branchOptions?.length > 0 ? branchOptions : []}
                      getOptionLabel={(option) => option?.name}
                      filterOptions={handleBranchFilterOption}
                      isOptionEqualToValue={isBranchValueMatchesOption}
                      value={hospiceBranch}
                      onChange={handleOnBranchChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="hospiceBranch"
                          error={!!hospiceError}
                        />
                      )}
                      size="small"
                    />
                    {hospiceError && (
                      <FormLabel error>
                        {t("PatientsListing.hospiceBranchError")}
                      </FormLabel>
                    )}
                  </div>
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>}
  </div>
  )
};

export default BranchAutocomplete;