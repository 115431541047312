import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageHistory = () => {
const location = useLocation();

  useEffect(() => {
    const lastFourPages = JSON.parse(localStorage.getItem("lastFourPages")) || [];
    const pageIndex = lastFourPages.findIndex((value) => value === location.pathname);
    if (pageIndex > -1) {
      lastFourPages.splice(pageIndex, 1);
    }
    
    if (!location.pathname.includes('error') && !location.pathname.includes('/support/share-your-feedback')) {
      const duplicatePageIndex = lastFourPages.findIndex((value) => value.replace(/\d/g, '') === location.pathname.replace(/\d/g, ''));
      if(duplicatePageIndex !== -1){
        lastFourPages.splice(duplicatePageIndex, 1);
      }
      lastFourPages.push(location.pathname);
      const previousPages = lastFourPages.slice(-4);
      localStorage.setItem("lastFourPages", JSON.stringify(previousPages));
    }
  }, [location.pathname]);
}

export default PageHistory;
