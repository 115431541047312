import React from "react";
import { Typography, Button, FormControl, FormLabel, FormControlLabel,Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle } from "@mui/material";
import styles from './FormAuth.module.css';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

 
const TrilogyAuthorizationForm = ({handleClose, preAuthFormData}) => {
    
    return (
        <>
        {preAuthFormData  && (
             
             <Dialog 
             fullWidth
             maxWidth="md"
             open={true} 
             onClose={handleClose} 
             >
             <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}> Trilogy Pre-Authorization Documentation  </DialogTitle>
             
             
             <DialogContent>
              <Typography sx={{margin: '10px 0px'}}><span className={styles.asterisk}>*</span> denotes required field</Typography>
                {preAuthFormData[0]?.BranchCode !== undefined && preAuthFormData[0].BranchCode !== '' &&<Grid container>
                 <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important'}}>
                 <FormControl className={styles.spacingRemoveTop}>  
                   <div>
                     <Typography className={styles.labelText}> Branch/State (3 letter code) <span className={styles.asterisk}>*</span> </Typography>
                   </div>
                   <div >
                   <TextField
                       fullWidth
                       autoFocus
                       margin="normal"
                       id="name"
                       type="text"
                       variant="outlined"
                       size="small"
                       maxLength="3"
                       value={preAuthFormData[0].BranchCode}
                       sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                       disabled={true}
                       inputProps={{ style: { cursor: 'not-allowed' } }}
                   />
                   </div>
                   </FormControl>
                 </Grid>
               </Grid>}
     

     <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
                <Typography sx={{fontWeight:'600'}}>All testing results including ABG's, sleep apnea testing and documentation of BiPap failure must be included/attached with authorization request for review and approval.</Typography>
                <Typography > Initial Approval:  <Typography sx={{fontWeight:'600'}}>COPD, ALS</Typography> (limited approval, 2 months at a time)</Typography>
       </Grid>
     </Grid>
     
     {preAuthFormData[0]?.HasStaff !== undefined && preAuthFormData[0].HasStaff !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">1. Have an appropriate number of staff at the agency been deemed competent to provide care to a patient with a trilogy vent prior to this admission? <span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
           value={preAuthFormData[0].HasStaff}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
             <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
     </Grid>}

     {preAuthFormData[0]?.Diagnosis !== undefined && preAuthFormData[0].Diagnosis !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">2. Patient Diagnosis <span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
           value={preAuthFormData[0].Diagnosis}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="copd" control={<Radio />} label="S/S Severe COPD" disabled={true}/>
             <FormControlLabel value="als" control={<Radio />} label="ALS" disabled={true}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
     </Grid>}

     {preAuthFormData[0]?.IsPC02_GTE_55 !== undefined && preAuthFormData[0]?.IsPC02_GTE_55 !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">3. Arterial blood gases with PCO2 &gt;= 55? <span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
           value={preAuthFormData[0].IsPC02_GTE_55}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
             <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
     </Grid>}

     {preAuthFormData[0]?.IsCO2Trial !== undefined && preAuthFormData[0]?.IsCO2Trial !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">a. ABGs from hospital should show increased CO2 trial<span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
           value={preAuthFormData[0].IsCO2Trial}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
             <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
     </Grid>}

     {preAuthFormData[0]?.HasFailure !== undefined && preAuthFormData[0]?.HasFailure !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">b. And failure of other modalities<span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
           value={preAuthFormData[0].HasFailure}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
             <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
     </Grid>}

     {preAuthFormData[0]?.HasApnea !== undefined && preAuthFormData[0]?.HasApnea !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">4. Prior to Trilogy, sleep apnea has been considered and ruled out<span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
           value={preAuthFormData[0].HasApnea}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
             <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
     </Grid>}

     {preAuthFormData[0]?.HasBiPapCPAP !== undefined && preAuthFormData[0]?.HasBiPapCPAP !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> <span style={{marginRight:'10px', fontWeight:'600'}}>AND </span> BiPap/CPAP without backup rate failure has been considered and failed<span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
             value={preAuthFormData[0].HasBiPapCPAP}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
             <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
     </Grid>}

     {preAuthFormData[0]?.TrilogyType !== undefined && preAuthFormData[0]?.TrilogyType !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">5. Please select the type of Trilogy:<span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
           value={preAuthFormData[0].TrilogyType}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="Invasive" control={<Radio />} label="Invasive (trach)" disabled={true}/>
             <FormControlLabel value="non-invasive" control={<Radio />} label="Non-invasive (mask)" disabled={true}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
     </Grid>}

     {preAuthFormData[0]?.TrilogySettings !== undefined && preAuthFormData[0].TrilogySettings !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}> 
       <div>
         <Typography className={styles.labelText}>
               6. What are the Trilogy settings?
         </Typography>
       </div>
       <TextField
                 fullWidth
                 aria-label="minimum height"
                 minRows={2}
                 maxLength={500}
                 size="small"
                 value={preAuthFormData[0].TrilogySettings}
                 sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
                 disabled={true}
                 inputProps={{ style: { cursor: 'not-allowed' } }}
               />
       </Grid>
     </Grid>}

     {preAuthFormData[0]?.DischargeDate !== undefined && preAuthFormData[0].DischargeDate !== '' &&  <Grid container>
       <Grid xs={12} className={styles.spacingTop}> 
       <div>
         <Typography className={styles.labelText}>
              7.  What is the anticipated hospital discharge date?
         </Typography>
       </div>
      
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <DatePicker slotProps={{ textField: { fullWidth: true, variant: 'outlined' } }} sx={{
                        '& .MuiInputBase-input': {
                          height: '10px',
                        },
                        marginTop:'5px',
                        backgroundColor:'var(--color-disableField)',
                      }} disabled={true} name={preAuthFormData[0].DischargeDate} value={dayjs(preAuthFormData[0].DischargeDate)} />
                    </LocalizationProvider>
       </Grid>
     </Grid>}

     {preAuthFormData[0]?.RefusingAntibiotics !== undefined && preAuthFormData[0]?.RefusingAntibiotics !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">8. Is the patient going to be refusing all further antibiotics for infections?<span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
             value={preAuthFormData[0].RefusingAntibiotics}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
             <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
     </Grid>}

     {preAuthFormData[0]?.ReturnToHospital !== undefined && preAuthFormData[0]?.ReturnToHospital !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}>
       <FormControl className={styles.spacingRemoveTop}>  
     <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">9. Will the patient be returning to the hospital/ER?<span className={styles.asterisk}>*</span></FormLabel>
           <RadioGroup
           value={preAuthFormData[0].ReturnToHospital}
             row
             aria-labelledby="demo-row-radio-buttons-group-label"
             name="row-radio-buttons-group"
           >
             <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
             <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
             
           </RadioGroup>
         </FormControl>
       </Grid>
     </Grid>}
     
     
     {preAuthFormData[0]?.ClinicianName !== undefined && preAuthFormData[0].ClinicianName !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}> 
       <div>
         <Typography className={styles.labelText}>
                 Clinician Name <span className={styles.asterisk}>*</span>
         </Typography>
       </div>
       <TextField
                 fullWidth
                 aria-label="minimum height"
                 minRows={2}
                 maxLength={200}
                 size="small"
                 value={preAuthFormData[0].ClinicianName}
                 sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
                 disabled={true}
                 inputProps={{ style: { cursor: 'not-allowed' } }}
               />
       </Grid>
     </Grid>}
     
     
     {preAuthFormData[0]?.ApprovingManager !== undefined && preAuthFormData[0].ApprovingManager !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}> 
       <div>
         <Typography className={styles.labelText}>
                 ED/Admin <span className={styles.asterisk}>*</span>
         </Typography>
       </div>
       <TextField
                 fullWidth
                 aria-label="minimum height"
                 minRows={2}
                 maxLength={200}
                 size="small"
                 value={preAuthFormData[0].ApprovingManager}
                 sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
                 disabled={true}
                 inputProps={{ style: { cursor: 'not-allowed' } }}
               />
       </Grid>
     </Grid>}
     
    {preAuthFormData[0]?.AVPO !== undefined && preAuthFormData[0].AVPO !== '' && <Grid container>
       <Grid xs={12} className={styles.spacingTop}> 
       <div>
         <Typography className={styles.labelText}>
                 AVPO <span className={styles.asterisk}>*</span>
         </Typography>
       </div>
       <TextField
       fullWidth
                 aria-label="minimum height"
                 minRows={2}
                 maxLength={200}
                 size="small"
                 value={preAuthFormData[0].AVPO}
                 sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
                 disabled={true}
                 inputProps={{ style: { cursor: 'not-allowed' } }}
               />
       </Grid>
     </Grid>
     }

    <Typography sx={{marginTop:'5px', fontSize:'13px'}}>
      Please allow 3-5 days for Trilogy/vent approval and deliver.
    </Typography>
     
     
           
             </DialogContent>
     
     <Grid container sx={{justifyContent:'end'}}>
       <Grid>
       <DialogActions
                   style={{ justifyContent: "end", padding: "14px 16px" }}
                 >
                   <Button
                     sx={{textTransform:'none'}}
                     onClick={handleClose}
                     className={styles.customNoButton}
                     variant="contained"
                   >
                     Close
                   </Button>
                 </DialogActions>
       </Grid>
     </Grid>
             
           </Dialog>
        )}
       
      </>
    )
}

TrilogyAuthorizationForm.formName = "TrilogyAuthorizationForm";
export default TrilogyAuthorizationForm;