import React from "react";
import { Card, CardContent, Typography, Grid, TextField, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import NoData from "../../../components/noData/noData";
import loader from "../../../assets/loader.gif";
import { useTranslation } from "react-i18next";

const NoteTextField = styled(TextField)({
  borderRadius: '2px !important'
});

const borderRadiusNotes = (index,length) => {
  if (index === 0) {
    return "4px 4px 0 0";
  } else if (index === length - 1) {
    return "0 0 4px 4px";
  } else {
    return "0px";
  }
}

const Notes = ({
  uniqueKey,
  noteContent,
  notesData,
  isLoading,
  noNoteExistMessage,
  onNoteChange,
  onAddNote,
  formatDateTime,
  classes,
  imageArray
}) => {
  const { t } = useTranslation();
  return (
    <>
      <NoteTextField
        multiline
        inputProps={{ maxLength: 500 }}
        aria-label="minimum height"
        minRows={5}
        label="Type note"
        value={noteContent}
        onChange={onNoteChange}
        sx={{ marginTop: "15px" }}
        className={classes['custom-patient-note-textfield']}
      />
      <div className={classes.addNoteBtn}>
        <Typography display="flex" justifyContent="flex-end">
          <Grid className={classes.alignmentRight} sx={{ mb: 1 }}>
            <Button
              variant={noteContent.trim() && "contained"}
              color="primary"
              data-testid='add-Note-Button'
              sx={{
                marginTop: "15px",
                textTransform: "none !important",
                color: "white",
                backgroundColor: "#25A8E0",
                opacity: !noteContent.trim() ? "0.5" : "1.0",
                cursor: noteContent.trim() ? "pointer" : "default",
                "&:hover": {
                  backgroundColor: "#25A8E0",
                },
              }}
              onClick={() => {
                if (noteContent.trim()) {
                  onAddNote();
                }
              }}
              startIcon={<AddIcon />}
              className={classes.customButton}
            >
              Add Note
            </Button>
          </Grid>
        </Typography>
      </div>
      {notesData?.length > 0 ? (
        notesData.map((item, index) => (
          <Card
            key={uniqueKey === 'ordersNotes' ? item.patient_preauth_form_id : item.order_number}
            variant="outlined"
            className={classes.customBox2}
            sx={{
              borderRadius: borderRadiusNotes(index, notesData.length),
              margin: "0px",
            }}
          >
            <CardContent className={classes.customCardContent}>
              {(uniqueKey === 'ordersNotes' ? item.note !== "" : item.note_content !== "") && (
                <Grid container>
                  <Grid item md={1} xs={3}>
                    {uniqueKey === 'ordersNotes' ?
                      (item.profile_image ?
                        (<div>
                          {
                            imageArray.length > 0 ?
                              <img src={`data:data:image/jpeg;base64,${imageArray.find(value => value.imagejpg == item.profile_image)?.image}`} alt="User Profile" className={classes.profileImage} />
                              :
                              <Avatar style={{ margin: '7px 0px', height: '50px', width: '50px' }} />

                          }
                        </div>) :
                        <Avatar style={{ margin: '7px 0px', height: '50px', width: '50px' }} imageUrl={`data:data:image/jpeg;base64,${imageArray.find(value => value.imagejpg == item.profile_image)?.image}`} />
                      ) :
                      (item.profile_image_url ?
                        (<div>
                          <img src={`data:image/png;base64,${item.profile_image_url}`} alt="User Profile" className={classes.profileImage} />
                        </div>) :
                        <Avatar style={{ margin: '7px 0px', height: '50px', width: '50px' }} />
                      )
                    }
                  </Grid>
                  <Grid item md={11} xs={9}>
                 { uniqueKey === 'ordersNotes' ?
                    <>
                      <span className={classes.name}>
                        {item.user_last_name},{' '}
                        {item.user_first_name}{' '}
                        {item.user_middle_name}{' '}
                      </span>
                      <span className={classes.date}>{formatDateTime(item.created_on)}{' '}</span>
                    </>:
                    <>
                     <span className={classes.name}> {item.last_name}{","}{" "}
                          {item.first_name}{" "}
                          {item.middle_initial}{" "}
                         </span>
                        <span className={classes.date}>{item.added_on}{" "}</span>
                        {item.order_number !== 0 && <Typography
                          sx={{
                            fontSize: "var(--common-heading)",
                          }}
                          className={classes.scopedClassNameLine}
                        >
                          <span> {t("PatientNote.order")} </span>
                          {item.order_number}{" "}
                        </Typography>}
                    </>
                    }
                     <div style={{ wordWrap: 'break-word', width: '100%' }} className={classes.fontSizeNote}>
                        <div style={{ whiteSpace: "pre-line" }}>
                          {uniqueKey === 'ordersNotes' ?
                            (item.note.split("\n").map((line, index) => (<div key={item.patient_preauth_form_id}>{line} </div>))) :
                            (item.note_content?.split("\n").map((line, index) => (<div key={item.order_number}>{line}</div>)))}
                        </div>
                      </div>
                    <p style={{ wordWrap: 'break-word', width: '100%' }} className={classes.fontSizeNote}>
                      {item.description}
                    </p>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        ))
      ) : (
        <NoData message={isLoading ? <img alt='Loading...' src={loader} /> : noNoteExistMessage} />
      )}
    </>
  );
};

export default Notes;
