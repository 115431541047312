import { ToastContainerComponent } from "../src/components/ToastMessage/ToastMessage";
import Routing from "./routing/routing";

function App() {
  return (
    <>
      <ToastContainerComponent />
      <Routing />
    </>
  );
}
export default App;




