import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastOptions = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  closeButton: false,
  draggable: true,
  className: 'toast-custom'
};
export function successToast(message) {
  toast.success(message, toastOptions);
}
export function errorToast(message) {
   toast.error(message, toastOptions); 
}
function ToastMessage({ message, toastType = "success" }) {
  if (toastType === "success") { toast.success(message, toastOptions); }
  else {
    toast.error(message, toastOptions);
  }
};
export function ToastContainerComponent() {
  return <ToastContainer limit={1} />;
}

export default ToastMessage;