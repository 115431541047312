import React from 'react';
import { useDispatch } from 'react-redux';
import { TourProvider } from '@reactour/tour';
import { prevButton, nextButton } from './tourActions/tourActions';
import { getTourSteps, tourModalstyles } from './pageTour/pageTour';
import { setRelaunchTooltip, setShowTour } from '../../redux/RelaunchSlice/RelaunchSlice';
import { toggleSubMenus } from '../../redux/SubMenuToggleSlice/SubMenuToggleSlice';
import { walkThroughSavePage } from './tourActions/tourActions';

const WalkThrough = ({ children }) => {
    const dispatch = useDispatch();

    return (
        <TourProvider
            steps={getTourSteps}
            showDots={false}
            prevButton={prevButton}
            nextButton={nextButton}
            styles={tourModalstyles}
            scrollSmooth
            disableWhenSelectorFalsy={true}
            padding={{ popover: 15 }}
            onClickMask={() => {
            }}
            onClickClose={({ setIsOpen }) => {
                dispatch(setShowTour({showTour: false}))
                dispatch(toggleSubMenus({ title: 'support' }));
                dispatch(setRelaunchTooltip({openRelaunchTooltip: true}))
                setIsOpen(false)
                walkThroughSavePage()
              }}
        >
            {children}
        </TourProvider>
    );
};

export default WalkThrough;
