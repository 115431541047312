import React from 'react';
import styles from './ReasonCode.module.scss';
import { Badge, Box } from "@mui/material";
import AirIcon from "@mui/icons-material/Air";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import ErrorIcon from "@mui/icons-material/Error";
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import CallSplitRoundedIcon from '@mui/icons-material/CallSplitRounded';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded';
import MoveDownRoundedIcon from '@mui/icons-material/MoveDownRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import ShuffleRoundedIcon from '@mui/icons-material/ShuffleRounded';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import SignpostSharpIcon from '@mui/icons-material/SignpostSharp';
import BedRoundedIcon from '@mui/icons-material/BedRounded';
import ScaleRoundedIcon from '@mui/icons-material/ScaleRounded';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HealthAndSafetyRoundedIcon from '@mui/icons-material/HealthAndSafetyRounded';
import MasksRoundedIcon from '@mui/icons-material/MasksRounded';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ForwardToInboxRoundedIcon from '@mui/icons-material/ForwardToInboxRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded';
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded';
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded';
import ReplayIcon from '@mui/icons-material/Replay';
import { OrderReasonEnum, OrderReasonPickUpEnum } from '../../enums';

const ReasonCode = (orderReasonCode = "", orderType = "Delivery") => {
  if(orderType == "Pickup" || orderType == "Pick Up"){
    switch ((OrderReasonPickUpEnum[orderReasonCode] ?? "").toLowerCase()){
      case 'equipment move':
        return (
          <div style={{ display: "flex", flexDirection: "column",  width: "83px", marginRight: "4px", marginTop:'7px' }}>
            <ArrowCircleRightRoundedIcon className={styles.iconSize} style={{ color: "var(--color-grey)", lineHeight: '16px', margin: "4px 2px 3px 28px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px", display: "flex", flexDirection: "column" }}>
              <span>
               Equipment
              </span>
              <span>
                Move
              </span>
            </span>
          </div>
        );
      case 'd&d':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "2px", width: "40px", marginRight: "4px" }}>
            <FavoriteBorderRoundedIcon className={styles.iconSize} style={{ color: "var(--color-green)", lineHeight: '16px', margin: "0px 0px 4px 1px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px", marginBottom: "4px" }}>
              D&D
            </span>
          </div>
        );
      case 'expired':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "7px", width: "58px", marginRight: "4px" }}>
            <FavoriteRoundedIcon className={styles.iconSize} style={{ color: "var(--color-red)", lineHeight: '16px', margin: "0px 2px 7px 9px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px", marginBottom: "15px" }}>
              Expired
            </span>
          </div>
        );
      case 'live discharge':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "24px", width: "58px", marginRight: "14px" }}>
            <FavoriteRoundedIcon className={styles.iconSize} style={{ color: "var(--color-yellow)", lineHeight: '16px', margin: "0px 2px 6px 20px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px", marginBottom: "15px" }}>
              Live Discharge
            </span>
          </div>
        );
      case 'not needed':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "12px", width: "58px", marginRight: "4px" }}>
            <ArchiveRoundedIcon className={styles.iconSize} style={{ color: "var(--color-yellow)", lineHeight: '16px', margin: "0px 2px 0px 9px" }} />
            <span
              className={styles.deliveryStatusText}
              style={{
                marginTop: "5.5px",
                lineHeight: "17px",
              }}
            >
              {" "}
              Not Needed
            </span>
          </div>
        );
      case 'equipment exchange':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "9px", width: "75px", marginRight: "4px" }}>
            <ForwardToInboxRoundedIcon className={styles.iconSize} style={{ color: "var(--color-yellow)", lineHeight: '16px', margin: "0px 2px 2px 17px" }} />
            <span
              className={styles.deliveryStatusText}
              style={{
                marginTop: "5.5px",
                lineHeight: "17px",
              }}
            >
              {" "}
              Equipment Exchange
            </span>
          </div>
        );
      case 'lost attempt 1':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "14px", width: "76px", marginRight: "4px" }}>
            <Badge sx={{ marginLeft: "10px" }} badgeContent={<LooksOneRoundedIcon style={{ color: "var(--color-orange)", fontSize: "18px", margin: "17px 19px 0px -13px" }} />}>
              <HelpRoundedIcon className={styles.iconSize} style={{ color: "var(--color-green)", lineHeight: '16px', margin: "3px 2px -3px 8px" }} />
            </Badge>
            <span
              className={styles.deliveryStatusText}
              style={{
                marginTop: "2.5px",
                lineHeight: "17px",
              }}
            >
              {" "}
              Lost Attempt 1
            </span>
          </div>
        );
      case 'lost attempt 2':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "13px", width: "76px", marginRight: "4px" }}>
            <Badge sx={{ marginLeft: "10px" }} badgeContent={<LooksTwoRoundedIcon style={{ color: "var(--color-orange)", fontSize: "18px", margin: "17px 19px 0px -13px" }} />}>
              <HelpRoundedIcon className={styles.iconSize} style={{ color: "var(--color-green)", lineHeight: '16px', margin: "3px 2px -3px 8px" }} />
            </Badge>
            <span
              className={styles.deliveryStatusText}
              style={{
                marginTop: "5.5px",
                lineHeight: "17px",
              }}
            >
              {" "}
              Lost Attempt 2
            </span>
          </div>
        );
    }
  }else{
    switch ((OrderReasonEnum[orderReasonCode] ?? "").toLowerCase()){
      case 'respiratory distress':
        return (
          <div
            className={`${styles.deliveryStatusImg} ${styles.deliveryStatusWidth}`}
            style={{ width: "92px", marginTop: "10px" }}
          >
            <Badge sx={{marginLeft:"10px"}} badgeContent={<ErrorIcon style={{color:"var(--color-red)",fontSize:"18px",margin:"16px 0px 0px 11px"}}/>}>
            <AirIcon
              className={`${styles.iconSize} ${styles.backgroundColorBlue}`}
            />
            </Badge>
            <span
              className={styles.deliveryStatusText}
              style={{
                marginTop: "5.5px",
                lineHeight: "17px",
              }}
            >
              {" "}
              Respiratory Distress
            </span>
          </div>
        );
      case 'add on respiratory/oxygen':
        return (
          <div
            className={styles.deliveryStatusImg}
            style={{ width: "87px",marginTop:"29px" }}
          >
            <AddCircleRoundedIcon
              className={`${styles.iconSize} ${styles.backgroundColorBlue}`}
            />
            <span
              className={styles.deliveryStatusText}
              style={{
                display:"flex",
                flexDirection:"column",
                marginTop: "3px",
                lineHeight: "16px",
              }}
            >
              Add On
              <span>
                Respiratory/
              </span>
              <span>
                Oxygen
              </span>
            </span>
          </div>
        );
      case 'hi-tech respiratory':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "2px", width: "77px", marginRight: "4px" }}>
            <BoltRoundedIcon className={styles.iconSize} style={{ color: "var(--color-base)", lineHeight: '16px', margin: "6px 2px 9px 20px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px", display: "flex", flexDirection: "column" }}>
              <span>
                Hi-Tech
              </span>
              <span>
                Respiratory
              </span>
            </span>
          </div>
        );
      case 'o2 equipment repair/replace':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "1px", width: "104px", marginRight: "4px", alignItems: "center" }}>
            <SwapVertIcon className={styles.iconSize} style={{ color: "var(--color-base)", lineHeight: '16px', margin: "7px 0px 0px 4px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px", marginTop: "6px" }}>
              O2 Equipment Repair/Replace
            </span>
          </div>
        );
      case 'service: respiratory equipment':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "18px", width: "67px", marginRight: "4px" }}>
            <ConstructionRoundedIcon className={styles.iconSize} style={{ color: "var(--color-base)", lineHeight: '16px', margin: "4px 2px 9px 12px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px", display: "flex", flexDirection: "column" }}>
              <span>
                Service:
              </span>
              <span>
                Respiratory Equipment
              </span>
            </span>
          </div>
        );
      case 'service: weighing service':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "20px", width: "58px", marginRight: "4px" }}>
            <ScaleRoundedIcon className={styles.iconSize} style={{ color: "var(--color-grey)", lineHeight: '16px', margin: "4px 2px 5px 12px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px", display: "flex", flexDirection: "column" }}>
              <span>
                Service:
              </span>
              <span>
                Weighing Service
              </span>
            </span>
          </div>
        );
      case 'service: room change':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "13px", width: "107px", marginRight: "4px" }}>
            <MoveDownRoundedIcon className={styles.iconSize} style={{ color: "var(--color-grey)", lineHeight: '16px', margin: "0px 2px 3px 32px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px", display: "flex", flexDirection: "column" }}>
              <span>
                Service:
              </span>
              <span>
                Room Change
              </span>
            </span>
          </div>
        );
      case 'change of address':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "2px", width: "58px", marginRight: "4px" }}>
            <EditLocationAltIcon className={styles.iconSize} style={{ color: "var(--color-grey)", lineHeight: '16px', margin: "4px 2px 6px 12px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px" }}>
              Change Address
            </span>
          </div>
        );
      case 'respite stay':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "3px", width: "58px", marginRight: "4px" }}>
            <RestoreRoundedIcon className={styles.iconSize} style={{ color: "var(--color-grey)", lineHeight: '16px', margin: "4px 2px 6px 12px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px" }}>
              Respite Stay
            </span>
          </div>
        );
      case 'urgent admission':
        return (
          <Box
            className={styles.deliveryStatusImg}
            sx={{ width:"65px",marginTop:"14px"}}
          >
            <PersonAddRoundedIcon
              className={styles.iconSize}
            style={{ color: "var(--color-red)" }}
            />
            <span
              className={styles.deliveryStatusText}
              style={{
                marginTop: "5px",
                lineHeight: "17px",
                display:"flex",
                flexDirection:"column"
              }}
            >
              {" "}
              Urgent
              <span>
               Admission
              </span>
            </span>
          </Box>
        );
      case 'first delivery':
        return (
          <Box
            className={styles.deliveryStatusImg}
            sx={{ width: "76px",marginTop:"7px"}}
          >
            <PersonRoundedIcon
              className={styles.iconSize}
              style={{ color: "var(--color-red)"}}
            />
            <span
              className={styles.deliveryStatusText}
              style={{
                marginTop: "5.5px",
                lineHeight: "17px",
                display:"flex",
                flexDirection:"column"
              }}
            >
              {" "}
              First Delivery
            </span>
          </Box>
        );
      case 'patient safety':
        return (
          <Box
            className={styles.deliveryStatusImg}
            sx={{ width: "76px" ,marginTop:"10px"}}
          >
            <HealthAndSafetyRoundedIcon
              className={styles.iconSize}
              style={{ color: "var(--color-red)" }}
            />
            <span
              className={styles.deliveryStatusText}
              style={{
                marginTop: "5.5px",
                lineHeight: "17px",
  
              }}
            >
              {" "}
              Patient Safety
            </span>
          </Box>
        );
      case 'split order':
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "104px", alignItems: "center" }}>
            <CallSplitRoundedIcon className={styles.iconSize} style={{ color: "var(--color-red)", lineHeight: '16px'}} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "14px"}}>
              Split Order
            </span>
          </div>
        );
      case 'add on general':
        return (
          <div
            className={styles.deliveryStatusImg}
            style={{ width: "80px",marginTop:"14px" }}
          >
            <AddCircleRoundedIcon
              className={styles.iconSize}
              style={{ color:"var(--color-yellow)"}}
            />
            <span
              className={styles.deliveryStatusText}
              style={{
                marginTop: "6px",
                lineHeight: "17px",
              }}
            >
              {" "}
              Add On General
            </span>
          </div>
        );
      case 'disposable items':
        return (
          <div
            className={styles.deliveryStatusImg}
            style={{ width: "80px",marginTop:"15px" }}
          >
            <MasksRoundedIcon
              className={styles.iconSize}
              style={{ color:"var(--color-yellow)" }}
            />
            <span
              className={styles.deliveryStatusText}
              style={{
                lineHeight: "17px",
              }}
            >
              {" "}
              Disposable Items
            </span>
          </div>
        );
      case 'service: bed equipment':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "4px", width: "91px", marginRight: "4px" }}>
            <BedRoundedIcon className={styles.iconSize} style={{ color: "var(--color-yellow)", lineHeight: '16px', margin: "4px 2px 6px 33px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px", display: "flex", flexDirection: "column" }}>
              <span>
                Service: Bed
              </span>
              <span>
                Equipment
              </span>
            </span>
          </div>
        );
      case 'service: general equipment':
        return (
          <div className={styles.serviceContainer}>
            <ConstructionRoundedIcon className={`${styles.iconSize} ${styles.constructionRoundedIcon}`} />
            <span className={`${styles.deliveryStatusText} ${styles.serviceItemsBox}`}>
              <span>Service: </span>
              <span>
                General Equipment
              </span>
            </span>
          </div>
        );
      case 'billing purposes':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "1px", width: "58px", marginRight: "4px", alignItems: "center" }}>
            <PriceCheckIcon className={styles.iconSize} style={{ color: "var(--color-green)", lineHeight: '16px', margin: "8px 0px 0px 3px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px", marginTop: "5px" }}>
              Billing Purposes
            </span>
          </div>
        );
      case 'internal transfer':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "17px", width: "73px", marginRight: "4px" }}>
            <SwapHorizontalCircleIcon className={styles.iconSize} style={{ color: "var(--color-green)", lineHeight: '16px', margin: "0px 2px 2px 21px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px", marginBottom: "4px" }}>
              Internal Transfer
            </span>
          </div>
        );
      case 'conversion':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "1px", width: "76px", marginRight: "11px" }}>
            <ShuffleRoundedIcon className={styles.iconSize} style={{ color: "var(--color-green)", lineHeight: '16px', margin: "0px 2px 2px 20px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px", marginBottom: "4px" }}>
              Conversion
            </span>
          </div>
        );
      case 'switchout':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "1px", width: "73px", marginRight: "4px" }}>
            <SignpostSharpIcon className={styles.iconSize} style={{ color: "var(--color-green)", lineHeight: '16px', margin: "0px 2px 2px 17px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px", marginBottom: "4px" }}>
              Switchout
            </span>
          </div>
        );
      case 'quote':
        return (
          <div style={{ display: "flex", flexDirection: "column", marginTop: "1px", width: "92px", marginRight: "4px" }}>
            <RequestQuoteRoundedIcon className={styles.iconSize} style={{ color: "var(--color-green)", lineHeight: '16px', margin: "0px 2px 5px 24px" }} />
            <span className={styles.deliveryStatusText} style={{ lineHeight: "16px", marginBottom: "4px" }}>
              Quote Order
            </span>
          </div>
        );
      case 'recurring order':
        return (
        <div
        className={styles.deliveryStatusImg}
        style={{marginLeft: "5px"}}
      >
        <RepeatRoundedIcon
          className={`${styles.iconSize} ${styles.backgroundColorYellow}`}
        />
        <span
          className={styles.deliveryStatusText}
          style={{ lineHeight: "1.7rem" }}
        >
          {" "}
          Recurring
        </span>
      </div>
      ); 
      case 're-admit':
        return (
          <div
            className={`${styles.deliveryStatusImg} ${styles.deliveryStatusWidth}`}
            style={{ width: "72px", marginTop: "0px" }}
          >
            <Badge badgeContent={<ReplayIcon style={{color:"var(--color-red)",fontSize:"20px",margin:"18px 0px 0px 0px"}}/>}>
            <PersonRoundedIcon
              className={styles.iconSize}
              style={{ color: "var(--color-red)"}}
            />
            </Badge>
            <span
              className={styles.deliveryStatusText}
              style={{
                lineHeight: "17px",
              }}
            >
              {" "}
              Re-admit
            </span>
          </div>
        );
    }
  }
 
}
export default ReasonCode;
