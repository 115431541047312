import React from "react";
import { Typography, FormControl, FormLabel, FormControlLabel,Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle } from "@mui/material";
import styles from './FormAuth.module.css';
import CustomButtons from "../../../common/CustomButtons/CustomButtons";


const AirvoAuthorizationForm = ({handleClose, preAuthFormData}) => {
 

    return (
      <> 
      {preAuthFormData  && (
           
           <Dialog 
           fullWidth
           maxWidth="md"
           open={true} 
           onClose={handleClose} 
           >
           <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}> Airvo Pre-Authorization Documentation </DialogTitle>
           
           
           <DialogContent>
           <Typography sx={{margin: '10px 0px'}}><span className={styles.asterisk}>*</span> denotes required field</Typography>
              {preAuthFormData[0]?.BranchCode !== undefined && preAuthFormData[0].BranchCode !== '' && <Grid container>
               <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important'}}>
               <FormControl className={styles.spacingRemoveTop}>  
                 <div>
                   <Typography className={styles.labelText}> Branch/State (3 letter code) <span className={styles.asterisk}>*</span> </Typography>
                 </div>
                 <div >
                 <TextField
                     id="name"
                     margin="normal"
                     fullWidth
                     type="text"
                     autoFocus
                     size="small"
                     maxLength="3"
                     variant="outlined"
                     value={preAuthFormData[0].BranchCode}
                     sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                     disabled={true}
                     inputProps={{ style: { cursor: 'not-allowed' } }}
                 />
                 </div>
                 </FormControl>
               </Grid>
             </Grid>}
   
   {preAuthFormData[0]?.IsPatientOnAirvo !== undefined && preAuthFormData[0]?.IsPatientOnAirvo !== '' && <Grid container>
     <Grid xs={12} className={styles.spacingTop}>
     <FormControl className={styles.spacingRemoveTop}>  
   <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 1. Is the patient currently on the Airvo system?<span className={styles.asterisk}>*</span></FormLabel>
         <RadioGroup
           value={preAuthFormData[0].IsPatientOnAirvo?'Yes':'No'}
           row
           aria-labelledby="demo-row-radio-buttons-group-label"
           name="row-radio-buttons-group"
         >
           <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }}/>
           <FormControlLabel value="No" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }}/>
           
         </RadioGroup>
       </FormControl>
     </Grid>
   </Grid>}
   
   {preAuthFormData[0]?.ClinicalRationale !== undefined && preAuthFormData[0].ClinicalRationale !== '' && <Grid container sx={{paddingLeft:'10px'}}>
     <Grid xs={12} className={styles.spacingTop}>
       <div>
       <Typography className={styles.labelText}>
       a. If no, what is the clinical rationale for using the Airvo system? <span className={styles.asterisk}>*</span>
       </Typography>
     </div>
     <TextField
               fullWidth
               maxLength={4000}
               multiline
               rows={3}
               size="small"
               aria-label="minimum height"
               value={preAuthFormData[0].ClinicalRationale}
               sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
               disabled={true}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
     </Grid>
   </Grid>}
   
   
  {preAuthFormData[0].NeedsGreaterThan15Lm !== undefined && <Grid container>
     <Grid xs={12} className={styles.spacingTop}>
     <FormControl className={styles.spacingRemoveTop}>  
   <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">2. Does patient have high flow needs greater than 15L/M? <span className={styles.asterisk}>*</span></FormLabel>
         <RadioGroup
           value={preAuthFormData[0].NeedsGreaterThan15Lm?'Yes':'No'}
           row
           aria-labelledby="demo-row-radio-buttons-group-label"
           name="row-radio-buttons-group"
         >
           <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={true}/>
           <FormControlLabel value="No" control={<Radio />} label="No" disabled={true}/>
           
         </RadioGroup>
       </FormControl>
     </Grid>
   </Grid>}
   
   {preAuthFormData[0].ExtenuatingCircumstances !== undefined && preAuthFormData[0].ExtenuatingCircumstances !== '' && <Grid container>
     <Grid xs={12} className={styles.spacingTop}> 
     <div>
       <Typography className={styles.labelText}>
       3. Please describe any extenuating circumstances that require the patient to use the Airvo system: <span className={styles.asterisk}>*</span>
       </Typography>
     </div>
     <TextField
               fullWidth
               maxLength={4000}
               multiline
               rows={3}
               size="small"
               value={preAuthFormData[0].ExtenuatingCircumstances}
               sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
               aria-label="minimum height"
               disabled={true}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
     </Grid>
   </Grid>}

   {preAuthFormData[0].Diagnosis !== undefined && preAuthFormData[0].Diagnosis !== '' && <Grid container>
     <Grid xs={12} className={styles.spacingTop}> 
     <div>
       <Typography className={styles.labelText}>
       4. Diagnosis that requires this equipment: <span className={styles.asterisk}>*</span>
       </Typography>
     </div>
     <TextField
               fullWidth
               multiline
               rows={3}
               maxLength={4000}
               size="small"
               aria-label="minimum height"
               value={preAuthFormData[0].Diagnosis}
               sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
               disabled={true}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
     </Grid>
   </Grid>}
   
   
   {preAuthFormData[0].ClinicianName !== undefined && preAuthFormData[0].ClinicianName !== '' && <Grid container>
     <Grid xs={12} className={styles.spacingTop}> 
     <div>
       <Typography className={styles.labelText}>
               Clinician Name <span className={styles.asterisk}>*</span>
       </Typography>
     </div>
     <TextField
               fullWidth
               aria-label="minimum height"
               minRows={2}
               maxLength={4000}
               size="small"
               value={preAuthFormData[0].ClinicianName}
               sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
               disabled={true}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
     </Grid>
   </Grid>}
   
   
   {preAuthFormData[0].ApprovingManager !== undefined && preAuthFormData[0].ApprovingManager !== '' && <Grid container>
     <Grid xs={12} className={styles.spacingTop}> 
     <div>
       <Typography className={styles.labelText}>
               ED/Admin <span className={styles.asterisk}>*</span>
       </Typography>
     </div>
     <TextField
               fullWidth
               aria-label="minimum height"
               minRows={2}
               maxLength={4000}
               size="small"
               value={preAuthFormData[0].ApprovingManager}
               sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
               disabled={true}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
      </Grid>
   </Grid>}
   
   {preAuthFormData[0].AVPO !== undefined && preAuthFormData[0].AVPO !== '' && <Grid container>
     <Grid xs={12} className={styles.spacingTop}> 
     <div>
       <Typography className={styles.labelText}>
               AVPO <span className={styles.asterisk}>*</span>
       </Typography>
     </div>
     <TextField
     fullWidth
               aria-label="minimum height"
               minRows={2}
               maxLength={4000}
               size="small"
               value={preAuthFormData[0].AVPO}
               sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
               disabled={true}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
     </Grid>
   </Grid>}
   
   
   </DialogContent>
   
   <Grid container sx={{justifyContent:'end'}}>
     <Grid>
     <DialogActions
                 style={{ justifyContent: "end", padding: "14px 16px" }}
               >
                 <CustomButtons type="button" text="Close" onClick={handleClose} />
               </DialogActions>
     </Grid>
   </Grid>
           
         </Dialog>
      )}
     
    </>
        
    )
}

AirvoAuthorizationForm.formName = "AirvoAuthorizationForm";
export default AirvoAuthorizationForm;