export const handleTimeFormat = (timestamp) => {
    const currentDate = new Date();
    const notificationDate = new Date(timestamp);

    const timeDifference = currentDate.getTime() - notificationDate.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    if (secondsDifference < 60) {
        return "Just now";
    } else if (minutesDifference < 60) {
        return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
    } else if (hoursDifference < 24) {
        return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
    } else {
        return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
    }
}
