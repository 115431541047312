import React from 'react';
import { Dialog, DialogTitle, Card, DialogContent, DialogActions, Button,IconButton, TextField, CardContent } from '@mui/material';
import styles from './CancelOrder.module.scss';
import CloseIcon from "@mui/icons-material/Close";

const CancelOrder = ({ isOpen, handleCancel, handleSubmit, headerText, contentText, contentText2, secondaryContentText,cancelText,okText, cancelReason, setCancelReason}) => 
{
  const handleReasonChange = (event) => {
      setCancelReason(event.target.value);
    };
    const handleCancelAndClear = () => {
      setCancelReason(''); 
      handleCancel();
    };
     return (
  <div className={styles.CommonAlert} data-testid="CommonAlert">
    <Dialog open={isOpen} onClose={handleCancelAndClear}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
          },
        },
      }}  
    >
      <DialogTitle className={styles.commonDialogTitleCancelOrder}>
        <span className={styles.commonContainer}>{headerText}
            <IconButton
              className={styles.handleCancel}
              onClick={handleCancelAndClear}
              aria-label="close"
              data-testid="closeAddSupportPin"
            >
              <CloseIcon />
            </IconButton>
          </span>
      </DialogTitle>
    <Card>
      <CardContent  sx={{padding: '0px !important', background: 'rgba(242, 242, 242)',   overflowY: "auto"}}>
      <div>
      <DialogContent style={{ padding: '20px',  background: 'white'}} >
        <p className={styles.dialogDescription}>
          <span>{contentText}</span>
          {secondaryContentText && <span> {secondaryContentText}</span>}
        </p>
        <TextField
             label="Reason"
             fullWidth
             multiline
             rows={4}
            value={cancelReason}
            onChange={handleReasonChange}
           />
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between", padding: '14px 20px' , background: 'white' }}>
        <Button
         onClick={handleCancelAndClear}
          className={styles.customCancelButton}
          variant="contained" >
          {cancelText}
        </Button>
        <Button
           variant={cancelReason?.trim() && "contained"}
           color="primary"
           sx={{
             textTransform: "none !important",
             color: "white",
             backgroundColor: "#25A8E0",
             opacity: !cancelReason?.trim() ?  "0.5" : "1.0" ,
             cursor: cancelReason?.trim() ? "pointer" : "default",
             "&:hover": {
               backgroundColor: "#25A8E0",
             },
           }}               
             onClick={() => {
               if(cancelReason.trim()){
                handleSubmit();
               }
             }}
          className={styles.customYesButton} >
          {okText}
        </Button>
      </DialogActions>
      </div>
      </CardContent>
    </Card>
    </Dialog>
  </div>
  );
    }

export default CancelOrder;
