import React, { useCallback } from "react";
import OrderConfirmationDelivery from "./order-confirmation-delivery";
import OrderConfirmationPickup from "./order-confirmation-pickup";
import OrderConfirmationService from "./order-confirmation-service";
import { useLocation } from "react-router-dom";
import { deliveryOrderType } from "../../../common/enums";


const OrderConfirmation = () => { 
  const location = useLocation();
  const orderType = location.state?.orderType;
  const handleOrderConfirmation = useCallback(() => {
    switch (orderType) {
      case deliveryOrderType.Delivery: case deliveryOrderType.DeliveryService:  // because on QA instance OrderType return DS but on Local D
        return <OrderConfirmationDelivery state={location.state}/>;
        // case "DS": 
        //   return <OrderConfirmationDeliveryPlusService />;
      case deliveryOrderType.Service:
        return <OrderConfirmationService state={location.state} />;
      case deliveryOrderType.PickUp:
        case deliveryOrderType.PickUpAll:
        return <OrderConfirmationPickup state={location.state} />;
     
      
      default:
        break;
    }
  }, [orderType]);

  return <>{handleOrderConfirmation()}</>;
};

export default OrderConfirmation;
