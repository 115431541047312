export const LineOfBusinessEnum = {
      patientsToMembers: 'Patients',
      patientToMember: 'Patient',
      hospiceToPace: "Hospice",
}

export const PatientName = {
      editPatientName: ''
}
export const LineOfBusiness = {
      businessName: "Hospice"
}