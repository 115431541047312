import React, {  useState, useEffect } from 'react';
import {  Button, Card, Box, CardContent, Table, TableHead, TableBody, TableContainer, TableRow, Paper } from '@mui/material';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import styles from './OrderConfirmationpageThird.module.scss'
import WarningIcon from '@mui/icons-material/Warning';
import Container from "@mui/material/Container";
import { styled } from '@mui/material/styles';
import { headerStyleForEquipment, headerStyleForQuantity, cellStyle, cellStyleQuantity, StyledTableCellPickup } from '../../../components/MuiCustomComponent/MuiCustomComponent';

const StyledTableRow = styled(TableRow)(({ theme }) => ({

  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const OrderConfirmationPickup = ({state}) => {
  const navigate = useNavigate();

  const parsedPickupConfirmationOrder = state?.pickupOrder;
  const patientDischarged = localStorage.getItem('patientDischarged') || false;
  const parsedConfirmationAllData = state?.confirmationData ?state?.confirmationData : null;
  const [equipmentData, setEquipmentData] = useState(null);

  const mergeProductDataAndGetCount = () => {
    let newDataArray = [];
    if (parsedConfirmationAllData) {
     newDataArray = parsedConfirmationAllData.order_equipments.reduce((acc,val)=>{
        const productList = [];
       if (acc.findIndex(v => v.orderId == val.order_id) == -1){
          parsedConfirmationAllData.order_equipments.forEach(item=>{
            const index = productList.findIndex(equipment=>equipment.equipment_id == item.equipment_id);
            if(index == -1){
              if (item.order_id == val.order_id) {
                productList.push({ equipment_name: item.equipment_name, equipment_id: item.equipment_id, productQuantity :1 });
              }
            }else{
              productList[index].productQuantity += 1;
            }
          })
          acc.push({ orderId: val.order_id,productList:productList })
        }
        return acc;
      },[])
     setEquipmentData(newDataArray);
    }
  };
  useEffect(() => {
    mergeProductDataAndGetCount();
  }, [])
  const handleNewOrder = (event) => {
    event.preventDefault();
    localStorage.setItem('orderTypeConfirmation', parsedPickupConfirmationOrder[0]?.address?.source)
    localStorage.setItem('selectedOrderType', parsedPickupConfirmationOrder[0]?.address?.source);
    navigate("/orders/new-order");
  };

  return (
    <Container className="mainContainer">
      <section className={styles.OrderSubmittedSuccessfullySection}>
        <div className={styles.OrderSubmittedSuccessfully}>
          Order submitted successfully!
        </div>
        <div>
          <Button onClick={handleNewOrder} variant="contained"
            color="primary" size="large"
            className={styles.placeAnotherOrder}
            startIcon={<FontAwesomeIcon icon={faClipboardList} />}>Place another order</Button>
        </div>
      </section>

      {equipmentData?.map((equipment, index) => (
        <TableContainer component={Paper} sx={{ mt: 2 }} key={equipment?.orderId}>
          <Box className={styles.scopedClassName}>
            <span className={styles.scopedClassHeading}>
              Order #<RouterLink to={"/orders/order-details/" + equipment.orderId} className={styles.orderIdLink} >
                <span>
                  <b>{equipment.orderId}  </b>
                </span>
              </RouterLink>
            </span>

          </Box>


          <Table aria-label="customized table">
            <TableHead>
              <TableRow >
                <StyledTableCellPickup style={headerStyleForEquipment}>Order Equipment</StyledTableCellPickup>
                <StyledTableCellPickup style={headerStyleForQuantity}>Quantity</StyledTableCellPickup>
              </TableRow>
            </TableHead>
            <TableBody>
              {equipment?.productList?.map((orderItem, index) => (
                orderItem && <StyledTableRow key={orderItem?.equipment_id}>
                  <StyledTableCellPickup className={styles.scopedClassNameLine} style={cellStyle} component="th" scope="row">
                    {orderItem.equipment_name}
                  </StyledTableCellPickup>
                  <StyledTableCellPickup className={styles.scopedClassNameLine} style={cellStyleQuantity}>{orderItem.productQuantity}</StyledTableCellPickup>

                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      ))}


       {patientDischarged && <Card variant="outlined" className={styles.customBox2} sx={{ mt: 5 }}>
        <Box className={styles.scopedClassNameForData}>
          <WarningIcon />
          <span className={`${styles.scopedClassHeading} marginLeft8`}>
            ATTENTION DISCHARGE PLANNERS
          </span>
        </Box>
        <CardContent>
          <container direction="column">
            <p className={`${styles.scopedClassNameLine} marginTop0`}>Contact your Local Provider about whether he or she can furnish medical equipment for live-discharged patients with Medicare, Medicaid, and private insurance. Transitioning a patient to the Prowler's Home-care service will typically include the following steps:</p>
            <p className={styles.scopedClassNameLine}>1. Fax the provider a patient face sheet containing patient demographics and insurance information.</p>
            <p className={styles.scopedClassNameLine}>2. Depending on the type of DME in-use, the Provider may fax or email you a “Certificate of Medical Necessity” form, which is required by most insurance companies.</p>
            <p className={styles.scopedClassNameLine}>3. Have your Medical Director complete and sign the “Certificate of Medical Necessity” form(s) and fax them back to the provider.</p>
          </container>
        </CardContent>
      </Card>}
    </Container>
  );
};

export default OrderConfirmationPickup;