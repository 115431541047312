import React, { memo } from 'react';
import styles from './order-feedback.module.scss';
import { Grid } from "@mui/material";
import { Controller } from 'react-hook-form';


const Ratings = memo(({ title, initialText, lastText, id, control,value, naOption = false }) => {
  return (
    <div data-testid="ratings">
      <p className='fontWeight600'>{title}</p>
      <Grid container>
        <Grid lg={naOption?1:2} md={naOption?1:2} xs={6} sm={3} className={styles.lowRating} sx={{
          '@media (min-width:601px)': {
          maxWidth: '15%',
        }}}>
          <p className='marginRight10'>{initialText}</p>
        </Grid>
        <Grid lg={naOption?4:3} md={naOption?7:5} xs={12} sm={6} className={styles.ratingCount} sx={{
          '@media (min-width:601px)': {
          maxWidth: 'fit-content',
        }}}>
          <div className='displayFlex justifyContentCenter'>
            <div className={`${styles.ratingButton} ${styles.ratingButton1}`}>
              <Controller
                name={id}
                control={control}
                defaultValue={value}
                render={({ field }) => (
                  <label>
                    <input
                      {...field}
                      type="radio"
                      name={title || id}
                      checked={field.value === "1"}
                      value="1"
                    />
                    <span className='fontWeight600'>1</span>
                  </label>
                )}
              />
            </div>
            <div className={`${styles.ratingButton} ${styles.ratingButton2}`}>
              <Controller
                name={id}
                control={control}
                defaultValue={value}
                render={({ field }) => (
                  <label>
                    <input
                      {...field}
                      type="radio"
                      name={title || id}
                      checked={field.value === "2"}
                      value="2"
                    />
                    <span className='fontWeight600'>2</span>
                  </label>
                )}
              />
            </div>
            <div className={`${styles.ratingButton} ${styles.ratingButton3}`}>
              <Controller
                name={id}
                control={control}
                defaultValue={value}
                render={({ field }) => (
                  <label>
                    <input
                      {...field}
                      type="radio"
                      name={title || id}
                      checked={field.value === "3"}
                      value="3"
                    />
                    <span className='fontWeight600'>3</span>
                  </label>
                )}
              />
            </div>
            <div className={`${styles.ratingButton} ${styles.ratingButton4}`}>
              <Controller
                name={id}
                control={control}
                defaultValue={value}
                render={({ field }) => (
                  <label>
                    <input
                      {...field}
                      type="radio"
                      name={title || id}
                      checked={field.value === "4"}
                      value="4"
                    />
                    <span className='fontWeight600'>4</span>
                  </label>
                )}
              />
            </div>
            <div className={`${styles.ratingButton} ${styles.ratingButton5}`}>
              <Controller
                name={id}
                control={control}
                defaultValue={value}
                render={({ field }) => (
                  <label>
                    <input
                      {...field}
                      type="radio"
                      name={title || id}
                      checked={field.value === "5"}
                      value="5"
                    />
                    <span className='fontWeight600'>5</span>
                  </label>
                )}
              />
            </div>
            {naOption && <div className={`${styles.ratingButton} ${styles.ratingButtonNA}`}>
              <Controller
                name={id}
                control={control}
                defaultValue={value}
                render={({ field }) => (
                  <label>
                    <input
                      {...field}
                      type="radio"
                      name={title || id}
                      checked={field.value === "0"}
                      value="0"
                    />
                    <span className='fontWeight600'>NA</span>
                  </label>
                )}
              />
            </div>}
          </div>
        </Grid>
        <Grid lg={naOption?1:2} md={naOption?1:2} xs={6} sm={3} className={styles.highRating} sx={{
          '@media (min-width:601px)': {
          maxWidth: '15%',
        }}}>
          <p className='marginLeft10'>{lastText}</p>
        </Grid>
      </Grid>
    </div>
  )
});

export default Ratings